import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';
// The dependency cycle is caused by <RichEditor> importing assistant components and those components also importing <RichEditor>.
// The issue is mitigated by disabling the assistant on <RichEditor> inside the assistant components.
// eslint-disable-next-line import/no-cycle
import CommandForm from './CommandForm';
import useAssistant from '@/assistant/hooks/useAssistant';
import CommandResult from './CommandResult';

const propTypes = {
  insidePopover: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'lg']),
  autoFocus: PropTypes.bool,
};
const defaultProps = {
  insidePopover: false,
  size: undefined,
  autoFocus: undefined,
};

function Command({ insidePopover, size, autoFocus }) {
  const { loading, result, command, discardCommand } = useAssistant();
  const loadingRef = useRef(loading);

  useEffect(() => {
    document.addEventListener('keydown', onKeyPress);
    return () => {
      document.removeEventListener('keydown', onKeyPress);
    };
  }, []);

  useEffect(() => {
    loadingRef.current = loading;
  }, [loading]);

  function onKeyPress(event) {
    if (event.keyCode === 27) {
      if (!loadingRef.current && insidePopover) {
        event.preventDefault();
        discardCommand();
      }
    }
  }

  if (result || loading) {
    return <CommandResult key={command.identifier} insidePopover={insidePopover} size={size} />;
  }

  return (
    <CommandForm
      key={command.identifier}
      insidePopover={insidePopover}
      size={size}
      autoFocus={autoFocus}
    />
  );
}

Command.propTypes = propTypes;
Command.defaultProps = defaultProps;

export default Command;
