import { COMMANDS } from '../constants/Constants';

export default function getCommandFromLocation(location) {
  if (location) {
    const pathMatch = location.pathname.match(/\/assistant\/(.*)$/i);
    if (pathMatch !== null) {
      return Object.values(COMMANDS).find((command) => command.slug === pathMatch[1]) || null;
    }
  }
  return null;
}
