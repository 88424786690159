import { EditorState, Modifier } from 'draft-js';

export default function insertTextAfterSelection(editorState, text) {
  const selectionState = editorState.getSelection();
  const contentState = editorState.getCurrentContent();
  let offset = selectionState.getIsBackward()
    ? selectionState.anchorOffset
    : selectionState.focusOffset;

  let key = selectionState.getIsBackward() ? selectionState.anchorKey : selectionState.focusKey;
  // Fix to add content to same block when user selects entire block by tripple clicking
  if (selectionState.getFocusKey() !== selectionState.getAnchorKey() && offset === 0) {
    const blockMap = contentState.getBlockMap();
    const block = blockMap.get(
      selectionState.getIsBackward() ? selectionState.anchorKey : selectionState.focusKey,
    );
    const blocksBefore = blockMap.toSeq().takeUntil((v) => v === block);
    const blockBefore = blocksBefore.toArray().pop();
    offset = blockBefore.getLength();
    key = blockBefore.getKey();
  }

  const newSelectionState = selectionState.merge({
    anchorOffset: offset,
    anchorKey: key,
    focusOffset: offset,
    focusKey: key,
  });
  return EditorState.push(
    editorState,
    Modifier.insertText(contentState, newSelectionState, text),
    'insert-characters',
  );
}
