import sanitizeUrl from '@/storychief/utils/sanitizeUrl';

function convertToDomElement(iframeString) {
  const wrap = document.createElement('div');
  wrap.innerHTML = iframeString;
  const iframes = wrap.getElementsByTagName('iframe');
  if (iframes.length > 0) {
    return iframes[0];
  }
  return null;
}

export default function getIframeSrc(iframeString) {
  const iframe = convertToDomElement(iframeString);
  const rawSrc = iframe?.src;

  return sanitizeUrl(rawSrc);
}

export function getIframeWidth(iframeString) {
  const iframe = convertToDomElement(iframeString);
  return sanitizeIframeDimension(iframe?.width);
}

export function getIframeHeight(iframeString) {
  const iframe = convertToDomElement(iframeString);
  return sanitizeIframeDimension(iframe?.height);
}

function sanitizeIframeDimension(dimension) {
  const pixelRegex = /^\d+(px)?$/;
  const percentageRegex = /^\d+%$/;

  if (pixelRegex.test(dimension) || percentageRegex.test(dimension)) {
    return dimension;
  }

  return 0;
}
