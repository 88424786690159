import StoryChief from '../../storychief/index';
import getUrlParams from '../utils/getUrlParams';
import convertIframeToResponsive from '../utils/convertIframeToResponsive';

export default function (url) {
  let data = {};
  const modes = [
    { type: 'place', url: '/place/' },
    { type: 'directions', url: '/dir/' },
    { type: 'search', url: '/search/' },
    { type: 'viewer', url: '/viewer' },
  ];
  const currentMode = modes.find((mode) => url.indexOf(mode.url) !== -1);
  if (currentMode) {
    let urlParams = url.replace(/^.*\/\/[^\/]+/, '').split('/'); // eslint-disable-line no-useless-escape
    urlParams.shift();
    const query = {};
    switch (currentMode.type) {
      case 'place':
        query.q = urlParams[2];
        break;
      case 'directions': {
        query.origin = urlParams[2];
        const waypointsParams = urlParams.slice(3, urlParams.length - 2);
        if (waypointsParams.length > 1) {
          query.waypoints = waypointsParams.slice(0, waypointsParams.length).join('|');
          query.destination = waypointsParams[waypointsParams.length - 1];
        } else {
          query.destination = waypointsParams[0];
        }
        break;
      }
      case 'search':
        query.q = urlParams[2];
        break;
      default:
        break;
    }
    let src = '';
    if (currentMode.type === 'viewer') {
      urlParams = getUrlParams(url);
      if (urlParams.mid) {
        src = `https://www.google.com/maps/d/embed?mid=${urlParams.mid}`;
      }
    } else {
      query.key = StoryChief.googlemapsembed.key;
      const queryKeys = [];
      Object.keys(query).forEach((key) => {
        queryKeys.push(`${key}=${query[key]}`);
      });
      const queryString = `?${queryKeys.join('&')}`;
      src = `https://www.google.com/maps/embed/v1/${currentMode.type}${queryString}`;
    }
    if (src !== '') {
      data = {
        type: 'rich',
        version: '1.0',
        iframe_src: src,
        provider_name: 'Gmaps',
        povider_url: 'https://www.google.com/maps',
        width: 598,
        height: 450,
        html: `<iframe scrolling="no" frameborder="0" width="598" height="450" style="border:0" src="${src}" allowfullscreen="allowfullscreen"></iframe>`,
      };
      return new Promise((resolve) => {
        data.html_responsive = convertIframeToResponsive(data.html);
        resolve(data);
      });
    }
  }
  return new Promise((resolve, reject) => {
    reject(data);
  });
}
