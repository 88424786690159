import request from 'superagent';
import convertIframeToResponsive from '../utils/convertIframeToResponsive';

export default function (url) {
  return new Promise((resolve, reject) => {
    const urlParts = url.replace('http://', '').replace('https://', '').split(/[/?#]/);
    const customerSlug = urlParts[0].split('.')[0];
    const videoIdBase64 = urlParts[2];
    const videoId = videoIdBase64.replace(/=/g, ''); // eslint-disable-line no-useless-escape

    request
      .get(`https://api.spotlightr.com/getOEmbed?videoID=${videoId}&format=json`)
      .end((err, res) => {
        if (err) {
          reject(err);
        } else {
          const data = res.body;
          if (typeof data.error !== 'undefined') {
            reject(data);
          }
          data.customer_slug = customerSlug;
          data.video_id_base64 = videoIdBase64;
          data.video_id = videoId;
          data.iframe_src = `https://${data.customer_slug}.cdn.spotlightr.com/watch/${data.video_id_base64}?fallback=true`;
          data.html = `<iframe allowtransparency="true" width="${data.width}" height="${data.height}" allowfullscreen="true" src="${data.iframe_src}" frameborder="0" scrolling="no"></iframe>`;
          data.html_responsive = convertIframeToResponsive(data.html);
          resolve(data);
        }
      });
  });
}
