import { EditorState, genKey, Modifier, ContentBlock } from 'draft-js';
import { List } from 'immutable';
import { Block } from '../constants/Constants';
import getDefaultBlockData from './getDefaultBlockData';

/*
 Changes the block type of the current block.
 */
export default function resetBlockWithType(editorState, newType = Block.UNSTYLED, data = null) {
  let editorChangeType = 'change-block-type';
  let contentState = editorState.getCurrentContent();
  let selectionState = editorState.getSelection();
  const key = selectionState.getStartKey();
  const blockAfter = contentState.getBlockAfter(key);
  let blockMap = contentState.getBlockMap();
  const block = blockMap.get(key);

  const defaultData = data || getDefaultBlockData(newType);
  let emptyBlockKey;
  let newBlockKey;
  if (block.getLength()) {
    editorChangeType = 'split-block';
    selectionState = selectionState.merge({
      anchorOffset: block.getLength(),
    });

    contentState = Modifier.splitBlock(contentState, selectionState);
    let newBlock = contentState.getBlockAfter(key);
    blockMap = contentState.getBlockMap();
    newBlock = newBlock.merge({
      text: '',
      type: newType,
      data: defaultData,
    });
    newBlockKey = newBlock.getKey();
    blockMap = blockMap.set(newBlockKey, newBlock);
  } else {
    const newBlock = block.merge({
      text: '',
      type: newType,
      data: defaultData,
    });
    newBlockKey = newBlock.getKey();
    blockMap = blockMap.set(newBlockKey, newBlock);
  }

  // add empty block if current block is last
  if (!blockAfter) {
    emptyBlockKey = genKey();
    const emptyBlock = new ContentBlock({
      key: emptyBlockKey,
      type: Block.UNSTYLED,
      text: '',
      characterList: List(),
      depth: 0,
    });
    blockMap = blockMap.set(emptyBlockKey, emptyBlock);
  }

  contentState = contentState.merge({
    blockMap,
    selectionAfter: selectionState.merge({
      anchorKey: newBlockKey,
      anchorOffset: 0,
      focusKey: newBlockKey,
      focusOffset: 0,
    }),
  });

  // when divider created, set selection on the next block if any otherwise create empty block
  // and set selection on it
  if (defaultData.type === 'divider') {
    const blockKey = blockAfter ? blockAfter.getKey() : emptyBlockKey;
    contentState = contentState.merge({
      blockMap,
      selectionAfter: selectionState.merge({
        anchorKey: blockKey,
        anchorOffset: 0,
        focusKey: blockKey,
        focusOffset: 0,
      }),
    });
  }

  return EditorState.push(editorState, contentState, editorChangeType);
}
