import { GlobalContentTypesModelsType } from '@/storychief/constants/contentTypes';
import getPusherChannelName from './getPusherChannelName';

function getPusherListener(
  modelType: keyof GlobalContentTypesModelsType,
  modelId: string | number,
) {
  return window.Echo.private(getPusherChannelName(modelType, modelId));
}

export default getPusherListener;
