import { EditorState, SelectionState, Modifier } from 'draft-js';

/*
 Update block-level metadata of the given `block` to the `newData`/
 */
export default function updateDataOfBlock(editorState, block, newData) {
  const content = editorState.getCurrentContent();
  const blockSelection = new SelectionState({
    anchorKey: block.key,
    anchorOffset: block.getLength(),
    focusKey: block.key,
    focusOffset: block.getLength(),
  });

  const newContentState = Modifier.mergeBlockData(content, blockSelection, newData);
  const newState = EditorState.push(editorState, newContentState, 'change-block-data');
  return EditorState.forceSelection(newState, editorState.getSelection());
}
