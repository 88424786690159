import PropTypes from 'prop-types';

import ToolbarButton from './ToolbarButton';

const propTypes = {
  buttons: PropTypes.arrayOf(PropTypes.shape({})),
  getEditorState: PropTypes.func.isRequired,
  onToggle: PropTypes.func,
  disabled: PropTypes.bool,
};
const defaultProps = {
  buttons: [],
  onToggle: () => {},
  disabled: false,
};

function ToolbarInline({ buttons, getEditorState, onToggle, disabled }) {
  if (buttons.length < 1) {
    return null;
  }
  const currentStyle = getEditorState().getCurrentInlineStyle();
  return (
    <div className="d-inline-block">
      {buttons.map((type) => {
        const iconLabel = {};
        iconLabel.label = type.label;

        return (
          <ToolbarButton
            {...iconLabel}
            key={type.style}
            active={currentStyle.has(type.style)}
            onToggle={onToggle}
            styleName={type.style}
            icon={type.icon}
            description={type.description}
            shortcut={type.shortcut}
            disabled={disabled}
            showIconAndLabel={type.showIconAndLabel}
          />
        );
      })}
    </div>
  );
}

ToolbarInline.propTypes = propTypes;
ToolbarInline.defaultProps = defaultProps;

export default ToolbarInline;
