import { Component } from 'react';
import PropTypes from 'prop-types';
import { EditorBlock } from 'draft-js';
import classNames from 'classnames';

const propTypes = {
  block: PropTypes.shape({
    text: PropTypes.string,
  }).isRequired,
  blockProps: PropTypes.shape({
    editorEnabled: PropTypes.bool,
  }).isRequired,
};
const defaultProps = {};

class BlockCaption extends Component {
  constructor(props) {
    super(props);
    this.styleCaption = this.styleCaption.bind(this);
  }

  componentDidMount() {
    this.styleCaption();
  }

  componentDidUpdate() {
    this.styleCaption();
  }

  styleCaption() {
    const { block } = this.props;
    if (block.text === '') {
      const captionBoundary = this.captionPlaceholder.getBoundingClientRect();
      this.captionPlaceholder.style.visibility = 'visible';
      this.figcaption.style.width = `${captionBoundary.width}px`;
    } else {
      this.captionPlaceholder.style.visibility = 'hidden';
      this.figcaption.style.width = '100%';
    }
  }

  render() {
    const { block, blockProps } = this.props;
    const classNameCaption = classNames('', {
      'is-empty': block.text === '',
      hidden: !blockProps.editorEnabled && block.text === '',
    });
    return (
      <figcaption
        ref={(node) => {
          this.figcaption = node;
        }}
        className={classNameCaption}
      >
        <div
          ref={(node) => {
            this.captionPlaceholder = node;
          }}
          className="placeholder"
        >
          Type caption (optional)
        </div>
        <EditorBlock {...this.props} />
      </figcaption>
    );
  }
}

BlockCaption.propTypes = propTypes;
BlockCaption.defaultProps = defaultProps;

export default BlockCaption;
