import { Block } from '../constants/Constants';
/*
 Returns default block-level metadata for various block type. Empty object otherwise.
 */
export default function getDefaultBlockData(blockType, initialData = {}) {
  switch (blockType) {
    case Block.TODO:
      return { checked: false };
    default:
      return initialData;
  }
}
