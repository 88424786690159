import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import BlockCaption from '../../components/blocks/BlockCaption';
import updateDataOfBlock from '../../utils/updateDataOfBlock';
import deleteDataBlock from '../../utils/deleteDataBlock';
import Oembed from '../../../oembed/Oembed';
import getOembedHtml from '../../../oembed/utils/getOembedHtml';
import selectBlock from '../../utils/selectBlock';
import StoryChief from '../../../storychief';
import EmbedGist from './EmbedGist';
import EmbedsWithScript from './EmbedsWithScript';

const propTypes = {
  block: PropTypes.shape({
    getData: PropTypes.func,
  }).isRequired,
  blockProps: PropTypes.shape({
    setReadOnly: PropTypes.func,
    getEditorState: PropTypes.func,
    setEditorState: PropTypes.func,
    isSelected: PropTypes.bool,
    editorEnabled: PropTypes.bool,
  }).isRequired,
};

function EmbedBlock(props) {
  // props
  const { block, blockProps } = props;

  // state
  const [inputHasValue, setInputHasValue] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState(false);

  // hooks
  const searchFieldNode = useRef();
  const embedWrapper = useRef();

  useEffect(() => {
    if (searchFieldNode.current && blockProps.isSelected) {
      searchFieldNode.current.focus();
    }

    // src_temp is set by our Import API because it cannot fetch oembed data yet
    const srcTemp = block?.getData()?.get('src_temp');
    if (blockProps.editorEnabled && srcTemp) {
      initEmbed(srcTemp);
    }
  }, []);

  // functions
  const onInputFocus = () => {
    blockProps.setReadOnly(true);
  };

  const onInputBlur = () => {
    blockProps.setReadOnly(false);
  };

  const onInputChange = (e) => {
    const value = e.target.value;
    if (value !== '') {
      if (!inputHasValue) {
        setInputHasValue(true);
      }
    } else if (inputHasValue) {
      setInputHasValue(false);
    }
  };

  const updateData = (data) => {
    const { getEditorState, setEditorState } = blockProps;
    setEditorState(updateDataOfBlock(getEditorState(), block, data));
  };

  function initEmbed(url) {
    const oembed = new Oembed();
    setError(false);
    setProcessing(true);
    oembed.getData(url).then(
      () => {
        blockProps.setReadOnly(false);
        updateData({ ...oembed, src_temp: '' });
      },
      () => {
        blockProps.setReadOnly(false);
        setError(true);
        setProcessing(false);
      },
    );
  }

  const onInputKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const url = e.target.value;
      initEmbed(url);
    }
  };

  const onWrapperClick = () => {
    const { getEditorState, setEditorState } = blockProps;
    setEditorState(selectBlock(block, getEditorState()));
  };

  const onRemove = () => {
    blockProps.setReadOnly(true);
    const newEditorState = deleteDataBlock(blockProps.getEditorState(), block);
    blockProps.setEditorState(newEditorState);
    blockProps.setReadOnly(false);
  };

  const renderEmbedBlockContent = (provider, src, embedHtml) => {
    switch (provider) {
      case 'githubgist':
        return <EmbedGist gist={src} />;
      case 'instagram':
      case 'typeform':
      case 'twitter':
      case 'imgur':
      case 'pinterest':
      case 'tiktok':
      case 'facebookPost':
      case 'hubspotForm':
      case 'activeCampaignForm':
        return <EmbedsWithScript embedHtml={embedHtml} provider={provider} />;
      default:
        return <div dangerouslySetInnerHTML={{ __html: embedHtml }} />;
    }
  };

  // variables
  const isSelected = blockProps.isSelected;
  const data = block.getData();
  const provider = data.get('provider');
  const size = data.get('size');
  const src = data.get('src');

  // fix: data firstly returns a Map after that it returns an object
  let oembed = data.get('data');
  let embedHtml = null;
  if (typeof oembed !== 'undefined') {
    if (Immutable.Map.isMap(oembed)) {
      oembed = oembed.toObject();
    }
    embedHtml = getOembedHtml(oembed, true);
  }

  const classNameWrapper = `block-container block-embed-container${src === '' ? ' is-empty' : ''} ${
    isSelected ? 'selected' : ''
  } ${size}`;

  return (
    <div className={classNameWrapper}>
      {src === '' ? (
        <div contentEditable={false}>
          <button
            type="button"
            onClick={onRemove}
            className="btn-chromeless btn-delete icon-cancel"
          >
            <span className="sr-only">Remove</span>
          </button>
          <h2>
            <span className="highlight-marker">Embed</span> media
          </h2>
          <div className="text-muted no-line-height space-top-1 space-6">
            <small>
              Add rich media like Social posts, Videos, Forms, Tables, and audio.
              <br />
            </small>
            <a
              href={`${StoryChief.dashboardBasePath}/integrations#editor-embeds`}
              className="btn btn-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              View supported providers
            </a>
          </div>
          <div className={error ? 'form-group has-error' : 'form-group'}>
            <input
              ref={searchFieldNode}
              className="form-control"
              type="text"
              onFocus={onInputFocus}
              onBlur={onInputBlur}
              onKeyPress={onInputKeyPress}
              onChange={onInputChange}
              placeholder="Paste a link and press Enter"
              disabled={processing ? 'disabled' : false}
            />
            {!!error && (
              <div className="text-center">
                <span className="small text-danger">
                  Media not found for this link.&nbsp;
                  <a
                    href={`${StoryChief.dashboardBasePath}/integrations#editor-embeds`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View supported providers
                  </a>
                </span>
              </div>
            )}
          </div>
          <BlockCaption {...props} />
        </div>
      ) : (
        <div>
          <div
            className="media-wrapper embed-wrapper"
            ref={embedWrapper}
            contentEditable={false}
            onClick={blockProps.editorEnabled && !isSelected ? onWrapperClick : null}
            onKeyDown={blockProps.editorEnabled && !isSelected ? onWrapperClick : null}
          >
            <button
              type="button"
              onClick={onRemove}
              className="btn-chromeless btn-delete icon-cancel"
            >
              <span className="sr-only">Remove</span>
            </button>
            <div className={blockProps.editorEnabled ? 'embed-container' : ''}>
              {renderEmbedBlockContent(provider, src, embedHtml)}
            </div>
          </div>
          <BlockCaption {...props} />
        </div>
      )}
    </div>
  );
}

EmbedBlock.propTypes = propTypes;

export default EmbedBlock;
