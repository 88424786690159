import request from 'superagent';
import jsonp from 'superagent-jsonp';
import convertIframeToResponsive from '../utils/convertIframeToResponsive';
import getIframeSrc from '../../storychief/utils/getIframeSrc';
import getParameterByName from '../../storychief/utils/getParameterByName';

export default function (url) {
  return new Promise((resolve, reject) => {
    request
      .get(`https://soundcloud.com/oembed?url=${url}`)
      .query({ maxwidth: 740, format: 'js' })
      .set('Accept', 'application/json')
      .use(
        jsonp({
          timeout: 3000,
          callbackName: 'callback',
        }),
      )
      .end((err, res) => {
        if (err) {
          reject(err);
        } else {
          const data = res.body;
          data.html_responsive = convertIframeToResponsive(data.html);
          data.iframe_src = getIframeSrc(data.html);
          const playerUrl = getParameterByName('url', data.iframe_src);
          const trackRegEx =
            /api.soundcloud.com\/tracks\/(((\w|-)[0-9A-F]+)|([A-Za-z0-9]+(?:[-_][A-Za-z0-9]+)))/; // eslint-disable-line no-useless-escape, max-len
          const trackMatch = playerUrl.match(trackRegEx);
          data.track_id = trackMatch ? trackMatch[1] : null;
          const playlistRegEx =
            /api.soundcloud.com\/playlists\/(((\w|-)[0-9A-F]+)|([A-Za-z0-9]+(?:[-_][A-Za-z0-9]+)))/; // eslint-disable-line no-useless-escape, max-len
          const playlistMatch = playerUrl.match(playlistRegEx);
          data.playlist_id = playlistMatch ? playlistMatch[1] : null;

          resolve(data);
        }
      });
  });
}
