import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Icon } from '@iconify-icon/react';
import resetBlockWithType from '../../utils/resetBlockWithType';

const propTypes = {
  setEditorState: PropTypes.func.isRequired,
  getEditorState: PropTypes.func.isRequired,
  isPrivate: PropTypes.bool,
};
const defaultProps = {
  isPrivate: false,
};

function GalleryButton({ setEditorState, getEditorState, isPrivate }) {
  function onClick() {
    const data = { type: 'gallery', size: 'regular', images: [], isPrivate };
    setEditorState(resetBlockWithType(getEditorState(), 'atomic', data));
  }

  const tooltip = <Tooltip id="slideshow-tooltip">Add image gallery</Tooltip>;
  return (
    <div className="sb-button-wrapper">
      <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={tooltip}>
        <button className="sb-button sb-gallery-button" type="button" onClick={onClick}>
          <Icon icon="ph:slideshow" height="18" className="mx-[3px] align-[-0.25em]" />
        </button>
      </OverlayTrigger>
    </div>
  );
}

GalleryButton.propTypes = propTypes;
GalleryButton.defaultProps = defaultProps;

export default GalleryButton;
