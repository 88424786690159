import { Component } from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Icon } from '@iconify-icon/react';
import resetBlockWithType from '../../utils/resetBlockWithType';

const propTypes = {
  setEditorState: PropTypes.func.isRequired,
  getEditorState: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};
const defaultProps = {
  disabled: false,
};

class CaptureButton extends Component {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    const data = { type: 'capture', storyIntegrationId: null, integrationId: null };
    this.props.setEditorState(resetBlockWithType(this.props.getEditorState(), 'atomic', data));
  }

  render() {
    const tooltip = <Tooltip id="capture-tooltip">Add lead capture form</Tooltip>;
    return (
      <div className="sb-button-wrapper">
        <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={tooltip}>
          <button
            className="sb-button sb-capture-button"
            type="button"
            onClick={this.onClick}
            disabled={this.props.disabled}
          >
            <Icon icon="fa:users" inline height="13" className="mx-[3px]" />
          </button>
        </OverlayTrigger>
      </div>
    );
  }
}

CaptureButton.propTypes = propTypes;
CaptureButton.defaultProps = defaultProps;

export default CaptureButton;
