import {
  useState,
  useCallback,
  useMemo,
  useEffect,
  ReactNode,
  JSX,
  Dispatch,
  SetStateAction,
} from 'react';
import { gql, useQuery } from '@apollo/client';
import { useLocation, useHistory } from 'react-router-dom';
import { EditorState, Modifier } from 'draft-js';
import { draftToMarkdown } from 'markdown-draft-js';
import AssistantContext from '@/assistant/context/Assistant';
import setCommandLocation from '../utils/setCommandLocation';
import getCommandFromLocation from '../utils/getCommandFromLocation';
import insertTextAfterSelection from '@/editor/utils/insertTextAfterSelection';
import StoryChief from '@/storychief';
import useEntitlements from '@/entitlements/hooks/useEntitlements';
import usePreview from '@/storychief/hooks/usePreview';
import useAssistantCommand from '@/assistant/hooks/useAssistantCommand';
import replaceSelectedText from '@/editor/utils/replaceSelectedText';
import deleteEditorInlineSelection from '@/editor/utils/deleteEditorInlineSelection';
import addEditorInlineSelection from '@/editor/utils/addEditorInlineSelection';
import markDownToDraftWithDynamicContent from '../utils/markDownToDraftWithDynamicContent';
import useAssistantConfig from '../hooks/useAssistantConfig';
import {
  ASSISTANT_URL_SEARCH_PARAMS,
  COMMAND_IDENTIFIERS,
  COMMAND_SECTIONS,
} from '../constants/Constants';
import draftToMarkDownWithDynamicContent from '../utils/draftToMarkDownWithDynamicContent';
import selectAll from '@/editor/utils/selectAll';
import selectAllBeforeSelection from '@/editor/utils/selectAllBeforeSelection';
import getDefaultDecorator from '@/editor/decorators/getDefaultDecorator';
import splitEditorState from '@/editor/utils/splitEditorState';
import shouldIncludeContextAssets from '../utils/shouldIncludeContextAssets';
import subtractTitleFromEditorState from '@/editor/utils/subtractTitleFromEditorState';
import analyticsTrack from '@/storychief/utils/analyticsTrack';
import { useDidUpdateEffect, usePrevious } from '@/storychief/hooks';
import getEditorTextAsMarkdownFromJson from '@/editor/utils/getEditorTextAsMarkdownFromJson';
import useBrandVoices from '@/assistant/hooks/useBrandVoices';
import getTitlePlainText from '@/editor/utils/getTitlePlainText';
import tryParseJson from '@/storychief/utils/tryParseJson';

const ASSISTANT_USAGE_QUERY = gql`
  query AssistantUsage {
    usage {
      __typename
      assistant_words
    }
  }
`;

export type SourceType = 'text' | 'url' | 'contentPieces';

export type ContentPiece = {
  __typename: 'Story';
  id: string;
  title: string;
  content: string;
  edit_url: string;
};

type AssistantContextMessage = {
  role: 'user';
  content: string;
};

type Subject = {
  type?: string;
  /**
   * Pass the whole modal to give context to the assistant!
   */
  model?: {
    __typename:
      | 'Story'
      | 'Postset'
      | 'WebsiteContent'
      | 'Newsletter'
      | 'VideoProject'
      | 'Ebook'
      | 'Webinar'
      | 'Podcast';
    id?: string;
    title?: string;
    content?: string;
    brief?: {
      title: string;
      content: string;
      info: {
        word_count: string;
      };
      assets: {
        mime?: string;
        url?: string;
      }[];
    };
    keywords?: { name: string }[];
    updateTitleEditorState: (...values: any) => void;
  };
  template?: {
    contentRaw: object;
    editLink: string;
  };
  component?: 'TextEditor' | 'RichEditor' | 'EditorSc' | 'EditorScTitle';
  hasSelectedText?: boolean;
  isEmpty?: boolean;
  language?: string;
};

type AssistantProviderType = {
  children: ReactNode;
  navigate?: boolean;
  subject: Subject;
  getEditorState?: (...values: any) => any;
  setEditorState?: (...values: any) => void;
  setReadOnly?: (value: boolean) => void;
  initialCommand: {
    identifier: string;
  };
  initialContext?: object;
  initialResult?: object;
  initialInput?: string;
  initialSource?: SourceType;
  initialContentPieces?: ContentPiece[];
  onUnmount: (...values: any) => void;
};

export type AssistantProviderReturnType = {
  command?: {
    identifier: string;
  };
  initCommand: (...values: any) => void;
  runCommand: (...values: any) => void;
  acceptCommand: (...values: any) => void;
  retryCommand: (...values: any) => void;
  discardCommand: (...values: any) => void;
  cancelCommand: (...values: any) => void;
  resetCommand: (...values: any) => void;
  input: string;
  setInput: Function;
  language?: string;
  setLanguage: Dispatch<SetStateAction<string>>;
  assetSource: SourceType;
  setAssetSource: Dispatch<SetStateAction<SourceType>>;
  assetTextValue: string;
  setAssetTextValue: Dispatch<SetStateAction<string>>;
  assetUrl: string;
  setAssetUrl: (value: string) => void;
  assetUrlValue: string;
  setAssetUrlValue: (value: string) => void;
  assetContentPieces: ContentPiece[];
  setAssetContentPieces: Dispatch<SetStateAction<ContentPiece[]>>;
  context: object;
  setContext: Dispatch<SetStateAction<object>>;
  result: object;
  setResult: (...values: any) => void;
  usage: number;
  isOverMonthlyLimit: boolean;
  isNearOrOverMonthlyLimit: boolean;
  maxMonthlyUsage: number;
  forceAutorun: boolean;
  loading: boolean;
  available: boolean;
  hasAccess: boolean;
  getEditorState: (...values: any) => void;
  setEditorState: (...values: any) => void;
  setReadOnly: (...values: any) => void;
  subject: Subject;
};

function AssistantProvider({
  children,
  subject = {},
  navigate = false,
  getEditorState = undefined,
  setEditorState = undefined,
  setReadOnly = undefined,
  initialCommand = undefined,
  initialContext = undefined,
  initialResult = undefined,
  initialInput = undefined,
  initialSource = 'text',
  initialContentPieces = [],
  onUnmount = undefined,
}: AssistantProviderType): JSX.Element {
  // Hooks
  const { data: { brandVoices } = { brandVoices: [] } } = useBrandVoices();
  const config = useAssistantConfig();
  const location = useLocation();
  const history = useHistory();
  const [command, setCommand] = useState(
    navigate ? getCommandFromLocation(location) : initialCommand,
  );
  const [language, setLanguage] = useState(config.getAssistantLanguage());
  const [input, setInput] = useState(initialInput); // Working title

  // Fields for Context with the tabs "text" | "URL | "article"
  const [assetSource, setAssetSource] = useState<SourceType>(initialSource);
  const [assetTextValue, setAssetTextValue] = useState<string>(initialContext?.assets?.value || '');
  const [assetUrl, setAssetUrl] = useState<string>('');
  const [assetUrlValue, setAssetUrlValue] = useState<string>('');
  const [assetContentPieces, setAssetContentPieces] =
    useState<ContentPiece[]>(initialContentPieces);
  const [context, setContext] = useState(getCommandContext(command, initialContext));

  // Previous states
  const previousCommandIdentifier = usePrevious(command?.identifier);

  // Store language in local storage
  useDidUpdateEffect(() => config.setAssistantLanguage(language), [language]);

  // Reset the context assets tab, when switching to another command
  useDidUpdateEffect(() => {
    if (command?.identifier !== previousCommandIdentifier) {
      setAssetSource('text');
      setAssetTextValue('');
      setAssetUrl('');
      setAssetUrlValue('');
      setAssetContentPieces([]);
    }
  }, [command?.identifier]);

  // Memorize to avoid doing to many functions calls (see usage draftToMarkdown)
  const messages = useMemo<AssistantContextMessage[]>(() => {
    switch (assetSource) {
      case 'text':
        return assetTextValue
          ? [
              {
                role: 'user',
                content: assetTextValue,
              },
            ]
          : [];
      case 'url':
        return assetUrlValue
          ? [
              {
                role: 'user',
                content: assetUrlValue,
              },
            ]
          : [];
      case 'contentPieces':
        return assetContentPieces.map((contentPiece: ContentPiece): AssistantContextMessage => {
          let value = '';
          const title = getTitlePlainText(contentPiece.title);
          const content = tryParseJson(contentPiece.content).parsed;

          if (title && title.length) {
            value += `**${title}**\n\n`;
          }
          if (content) {
            value += draftToMarkdown(content);
          }

          value = value.trim();

          return {
            role: 'user',
            content: value.length > 10476 ? `${value.slice(0, 10476)}... (truncated)` : value,
          };
        });
      default:
        return [];
    }
  }, [assetSource, assetTextValue, assetUrlValue, JSON.stringify(assetContentPieces)]);

  const {
    result,
    streamCommand,
    setResult,
    cancelCommand,
    loading: isCommandLoading,
  } = useAssistantCommand(initialResult);

  const getEntitlement = useEntitlements();
  const { preview } = usePreview();
  const maxMonthlyUsage = getEntitlement('ai-assistant-monthly-word-count') as number;

  const hasAccess = StoryChief.accessRight('canUseAssistant');
  const available = getEntitlement('ai-assistant');

  // Queries
  const { data: usageData, loading: isUsageLoading } = useQuery(ASSISTANT_USAGE_QUERY, {
    skip: preview || !available,
  });
  const usage = usageData?.usage?.assistant_words || null;

  // Functions
  function getCommandContext(_command, _context) {
    if (_command?.initialContext) {
      return Object.assign({}, _command.initialContext, _context || {});
    }

    return _context;
  }

  const resetCommand = useCallback(
    (_command = undefined, _input = undefined, _context = undefined, _result = undefined) => {
      setResult(_result);
      setInput(_input);
      setContext(getCommandContext(_command, _context));
      setCommand(_command);
    },
    [],
  );

  const runCommand = useCallback(
    (_command, _input: string, _context = undefined, _result = undefined) => {
      function getBrandVoice() {
        if (config.local?.data.brandVoice) {
          return brandVoices.find(({ id: _id }) => _id === config.local.data.brandVoice);
        }

        return null;
      }

      streamCommand(_command.identifier, {
        prompt: {
          command: _command.identifier,
          language: _context?.language || language,
          input: _input,
          context: { ...(_context || {}), messages },
          subject_type: subject?.type || null,
          transcript: _result?.transcript
            ? // we remove the id from the transcript item to prevent the server from using it
              _result.transcript.map((item) => ({
                content: item.content,
                role: item.role,
              }))
            : [],
          brand_voice: getBrandVoice(),
        },
      });
    },
    [config, language, messages],
  );

  function prepareEditorState(_command) {
    let editorState = getEditorState ? getEditorState() : undefined;
    if (editorState) {
      // Fix to prevent editor from loosing selection
      setEditorState(EditorState.forceSelection(editorState, editorState.getSelection()));
      // this logic selects above or all based on the subject, command section or identifier
      if (
        editorState.getSelection().isCollapsed() &&
        ((subject.component === 'TextEditor' && _command?.input?.type === 'text') ||
          _command.section === COMMAND_SECTIONS.EDIT)
      ) {
        // select above or select all
        const currentContent = editorState.getCurrentContent();
        const selectionState = editorState.getSelection();
        if (
          currentContent.getFirstBlock().getKey() === selectionState.focusKey &&
          selectionState.focusOffset === 0
        ) {
          editorState = selectAll(editorState);
        } else {
          editorState = selectAllBeforeSelection(editorState);
        }
      }
      editorState = addEditorInlineSelection(editorState, setEditorState);
      setEditorState(editorState);
    }
    return editorState;
  }

  function prepareInput(_command, _input) {
    if (!_input && subject.model) {
      if (subject.model) {
        let _title = '';
        let _content = '';

        const workingTitle = new URLSearchParams(location.search).get(
          ASSISTANT_URL_SEARCH_PARAMS.workingTitle,
        );

        if (workingTitle) {
          return decodeURIComponent(workingTitle);
        }

        if (
          _command.section === COMMAND_SECTIONS.DRAFT ||
          _command.section === COMMAND_SECTIONS.WRITE
        ) {
          _title = subject.model.title || '';
        }

        if (
          _command.identifier === COMMAND_IDENTIFIERS.STORY_EXCERPT ||
          _command.identifier === COMMAND_IDENTIFIERS.STORY_META_TITLE ||
          _command.identifier === COMMAND_IDENTIFIERS.STORY_META_DESCRIPTION
        ) {
          // Pass the whole content, for generating summaries
          _content = getEditorTextAsMarkdownFromJson(subject.model.content);
        }

        return `${_title}\n\n${_content}`.trim();
      }
    }

    return '';
  }

  function prepareContext(_command, _context = undefined, _editorState = undefined) {
    const _newContext = _context || {};
    _newContext.subject = {};

    if (_editorState) {
      _newContext.subject.text = draftToMarkDownWithDynamicContent(
        _editorState.getCurrentContent(),
      );

      const { selectionEditorState, aboveSelectionEditorState, belowSelectionEditorState } =
        splitEditorState(_editorState);

      _newContext.subject.textSelection = draftToMarkDownWithDynamicContent(
        selectionEditorState.getCurrentContent(),
      );
      _newContext.subject.textSelectionPlain = selectionEditorState
        .getCurrentContent()
        .getPlainText();

      _newContext.subject.textAboveSelection = draftToMarkDownWithDynamicContent(
        aboveSelectionEditorState.getCurrentContent(),
      );

      _newContext.subject.textBelowSelection = draftToMarkDownWithDynamicContent(
        belowSelectionEditorState.getCurrentContent(),
      );

      if (subject.hasSelectedText && shouldIncludeContextAssets(_command, getEditorState)) {
        _newContext.assets = {
          source: 'text',
          value: _newContext.subject.textSelectionPlain,
        };
      }
    }

    if (subject.model && subject.model.brief) {
      // Convert DraftJS JSON
      _newContext.editorial_brief = getEditorTextAsMarkdownFromJson(subject.model.brief.content);
      _newContext.word_count = subject.model.brief.info?.word_count || 0;
    }

    if (subject.model && subject.model.keywords && subject.model.keywords.length) {
      _newContext.keywords = subject.model.keywords;
    }

    return _newContext;
  }

  const initCommand = useCallback(
    (_command, options) => {
      const {
        input: _input,
        context: _context,
        result: _result,
        forceAutorun = false,
      } = options || {};
      const newEditorState = prepareEditorState(_command);
      const newContext = prepareContext(_command, _context, newEditorState);
      const newInput = prepareInput(_command, _input);

      if (navigate) {
        setCommandLocation(location, history, _command, newInput, newContext, _result);
      } else {
        resetCommand(_command, newInput, newContext, _result);
      }
      if (setReadOnly) {
        setReadOnly(true);
      }
      if (!isOverMonthlyLimit() && (forceAutorun || (_command?.autoRun && newEditorState))) {
        runCommand(_command, newInput, newContext, _result);
      }
    },
    [location, history, subject, setReadOnly, navigate],
  );

  const acceptCommand = useCallback(
    (content, insertBelow = false) => {
      analyticsTrack('Assistant Command Accepted', {
        command: command.identifier,
        subject_type: subject?.type || null,
      });
      let editorState = getEditorState();
      let selectionState = editorState.getSelection();
      editorState = deleteEditorInlineSelection(editorState, setEditorState);
      let commandContentState = markDownToDraftWithDynamicContent(
        content,
        editorState.getCurrentContent(),
      );
      if (insertBelow) {
        // move selection to end and collapse
        selectionState = selectionState.merge({
          anchorOffset: selectionState.getEndOffset(),
          anchorKey: selectionState.getEndKey(),
          focusOffset: selectionState.getEndOffset(),
          focusKey: selectionState.getEndKey(),
        });
      }

      let newEditorState = null;
      if (
        subject.component === 'EditorSc' &&
        subject.isEmpty &&
        subject?.model?.updateTitleEditorState
      ) {
        // here we check if the command contains a header, if so we will save the header as the title
        const commandEditorState = EditorState.createWithContent(
          commandContentState,
          getDefaultDecorator(),
        );
        // we need to subtract the title from the content and update the title editor state
        const { titleEditorState, contentEditorState } = subtractTitleFromEditorState(
          commandEditorState,
          true,
        );
        if (titleEditorState) {
          subject.model.updateTitleEditorState(titleEditorState);
          commandContentState = contentEditorState.getCurrentContent();
        }
      }
      if (subject.component === 'TextEditor' || subject.component === 'EditorScTitle') {
        if (selectionState.isCollapsed()) {
          newEditorState = insertTextAfterSelection(
            editorState,
            `${commandContentState.getPlainText('\u0001')}`,
          );
        } else {
          newEditorState = replaceSelectedText(
            editorState,
            `${commandContentState.getPlainText('\u0001')}`,
          );
        }
        setEditorState(EditorState.forceSelection(newEditorState, newEditorState.getSelection()));
      } else {
        const blockMap = commandContentState.blockMap;
        const newContentState = Modifier.replaceWithFragment(
          editorState.getCurrentContent(),
          selectionState,
          blockMap,
        );
        newEditorState = EditorState.push(editorState, newContentState, 'insert-fragment');
        setEditorState(EditorState.forceSelection(newEditorState, newEditorState.getSelection()));
      }
      if (setReadOnly) {
        setReadOnly(false);
      }
      setCommand(undefined);
    },
    [command, subject],
  );

  const retryCommand = useCallback(() => {
    analyticsTrack('Assistant Command Retried', {
      command: command.identifier,
      subject_type: subject?.type || null,
    });
    if (isCommandLoading) {
      cancelCommand();
    }
    const _result = { ...result };
    if (_result?.transcript) {
      _result.transcript.length -= 1;
      setResult(_result);
    }
    runCommand(command, input, context, _result);
  }, [command, input, context, result, config]);

  const discardCommand = useCallback(() => {
    analyticsTrack('Assistant Command Discarded', {
      command: command.identifier,
      subject_type: subject?.type || null,
    });
    // remove all editor selection inline styles
    if (getEditorState) {
      deleteEditorInlineSelection(getEditorState(), setEditorState);
    }

    if (setReadOnly) {
      setReadOnly(false);
    }
    if (isCommandLoading) {
      cancelCommand();
    }
    resetCommand();
  }, [command]);

  function isOverMonthlyLimit() {
    return usage >= maxMonthlyUsage;
  }

  function isNearOrOverMonthlyLimit() {
    return usage && usage >= maxMonthlyUsage * 0.9;
  }

  const providerValue = useMemo(
    () => ({
      command,
      result,
      setResult,
      loading: isUsageLoading || isCommandLoading,
      input,
      setInput,
      language,
      setLanguage,
      assetSource,
      setAssetSource,
      assetTextValue,
      setAssetTextValue,
      assetUrl,
      setAssetUrl,
      assetUrlValue,
      setAssetUrlValue,
      assetContentPieces,
      setAssetContentPieces,
      context,
      setContext,
      config,
      usage,
      maxMonthlyUsage,
      isOverMonthlyLimit: isOverMonthlyLimit(),
      isNearOrOverMonthlyLimit: isNearOrOverMonthlyLimit(),
      hasAccess,
      available,
      initCommand,
      runCommand,
      acceptCommand,
      retryCommand,
      resetCommand,
      discardCommand,
      cancelCommand,
      getEditorState,
      setEditorState,
      subject,
    }),
    [
      command,
      result,
      setResult,
      usage,
      isUsageLoading,
      isCommandLoading,
      setInput,
      context,
      setContext,
      assetSource,
      config,
      hasAccess,
      available,
      initCommand,
      runCommand,
      acceptCommand,
      retryCommand,
      resetCommand,
      discardCommand,
      cancelCommand,
      subject,
    ],
  );

  // Effects
  useEffect(() => {
    if (navigate) {
      const _command = getCommandFromLocation(location);
      if (command && _command && command.identifier === _command.identifier) {
        return;
      }
      cancelCommand();
      resetCommand(
        _command,
        location.state?.input || undefined,
        location.state?.context || undefined,
        location.state?.result || undefined,
      );
    }
  }, [navigate, location, history]);

  useEffect(
    () => () => {
      if (onUnmount) {
        onUnmount({
          command,
          input,
          context,
          result,
        });
      }
    },
    [command, input, context, result],
  );

  return <AssistantContext.Provider value={providerValue}>{children}</AssistantContext.Provider>;
}

export default AssistantProvider;
