/**
 * @param {string} urlString
 * @param {string[]} allowedProtocols
 * @returns {string}
 */
export default function sanitizeUrl(urlString, allowedProtocols = ['http:', 'https:']) {
  if (!urlString) {
    return '';
  }
  let url;
  try {
    url = new URL(urlString);
  } catch (e) {
    return '';
  }

  if (!allowedProtocols.includes(url.protocol)) {
    return '';
  }

  return url.toString();
}
