import image from './image/plugin';
import video from './video/plugin';
import embed from './embed/plugin';
import capture from './capture/plugin';
import gallery from './gallery/plugin';
import divider from './divider';
import columnDivider from './columnDivider';
import button from './button';

export default [image, gallery, video, embed, capture, divider, columnDivider, button];
