import { useState, useEffect, useRef } from 'react';
import request from 'superagent';
import PropTypes from 'prop-types';
import UnsplashGallery from './UnsplashGallery';
import { useDidUpdateEffect } from '@/storychief/hooks';

const propTypes = {
  focusInput: PropTypes.bool,
  style: PropTypes.shape({}),
  onFileLoaded: PropTypes.func.isRequired,
  onFileLoadError: PropTypes.func,
  onFileLoading: PropTypes.func,
  onInputFocus: PropTypes.func.isRequired,
  onInputBlur: PropTypes.func.isRequired,
};
const defaultProps = {
  onFileLoading: () => {},
  onFileLoadError: () => {},
  focusInput: false,
  style: undefined,
};

const client_id = window.StoryChief.services.unsplash.clientId;
const endpoint = 'https://api.unsplash.com/search/photos';
const endpointDownload = 'https://api.unsplash.com/photos';

function UnsplashContainer({
  focusInput,
  style,
  onFileLoaded,
  onFileLoadError,
  onFileLoading,
  onInputFocus,
  onInputBlur,
}) {
  // State
  const [page, setPage] = useState(1);
  const [errors, setErrors] = useState(null);
  const [images, setImages] = useState([]);
  const [searchQuery, setSearchQuery] = useState([]);

  // Refs
  const searchFieldNode = useRef(null);

  function fetchImages(_page) {
    [_page, _page + 1].forEach((p) => {
      if (!images[p]) {
        request
          .get(`${endpoint}?query=${searchQuery}&client_id=${client_id}&page=${p}&per_page=9`)
          .end((err, res) => {
            if (res && res.ok) {
              setErrors(null);
              setImages((prevImages) => {
                const nextImages = prevImages.slice(0);
                const data = res.body;
                const results = data.results.map((result) => ({
                  ...result,
                  __typename: 'UnsplashImage',
                  title: result.user.name,
                  urls: {
                    ...result.urls,
                    thumb: `${result.urls.thumb}&w=240`,
                    full: `${result.urls.full}&w=2000`,
                  },
                }));
                nextImages[p] = {
                  ...data,
                  results,
                };
                return nextImages;
              });
            } else {
              setErrors(err?.response?.body?.errors || [err]);
            }
          });
      }
    });
  }

  function handlePagination(direction) {
    let nextPage = page;
    if (direction === 'Previous') {
      nextPage -= 1;
    } else if (images[page].total_pages > 1) {
      nextPage += 1;
    }
    setPage(nextPage);
    fetchImages(nextPage);
  }

  function handleSelection(e, image) {
    setImages((prevImagesByPage) =>
      prevImagesByPage.map((prevImages) => {
        const newResults = prevImages.results.map((prevImage) => {
          let downloading = false;
          if (prevImage.id === image.id) {
            downloading = true;
          }
          return { ...prevImage, downloading };
        });
        return { ...prevImages, results: newResults };
      }),
    );
    e.preventDefault();
    const { slug } = image;
    const imageResized = Object.assign({}, image);
    imageResized.height = Math.floor(imageResized.height * (2000 / imageResized.width));
    imageResized.width = 2000;
    if (onFileLoading) onFileLoading([imageResized]);
    // Let unsplash know we triggered a download
    request.get(`${endpointDownload}/${image.id}/download?client_id=${client_id}`);
    // Download Unsplash image and set max width to 2000 px;
    const xhr = new XMLHttpRequest();
    xhr.open('GET', image.urls.full, true);
    xhr.responseType = 'blob';
    xhr.onload = () => {
      if (xhr.status === 200) {
        const blob = xhr.response;
        blob.lastModifiedDate = new Date();
        // name is required for S3Upload component
        blob.name = `${slug || 'unsplash-image'}.jpg`;
        blob.source = 'unsplash';
        blob.user = image.user;
        onFileLoaded([blob]);
      } else {
        onFileLoadError([imageResized]);
      }
    };
    xhr.send();
  }

  function handleChange(e) {
    e.preventDefault();
    setSearchQuery(e.target.value);
  }

  function onKeyDown(e) {
    if (e.which === 8) {
      e.preventDefault();
      const _searchQuery = e.target.value;
      e.target.value = _searchQuery.substring(0, _searchQuery.length - 1);
      setSearchQuery(e.target.value);
    } else if (e.which === 13) {
      e.preventDefault();
      setImages([]);
      setPage(1);
    }
  }

  // Hooks
  useEffect(() => {
    if (focusInput && searchFieldNode.current) {
      searchFieldNode.current.focus();
    }
  }, []);

  useDidUpdateEffect(() => {
    if (page === 1 && !images.length) {
      fetchImages(page);
    }
  }, [page, images]);

  return (
    <div style={style} className="unsplash-container form-group">
      <div className="row row-table">
        <div className="col-table col-xs-offset-2 col-xs-8">
          <input
            className="form-control"
            type="search"
            ref={searchFieldNode}
            onFocus={onInputFocus}
            onBlur={onInputBlur}
            placeholder="Type a keyword to search images, and press Enter"
            onChange={handleChange}
            onKeyDown={onKeyDown}
          />
        </div>
      </div>
      {errors && (
        <div className="text-muted small space-top-4">
          Unfortunately, an error occurred while loading images: {errors.join(', ')}
          <div>Please try again.</div>
        </div>
      )}
      {images[page] && (
        <UnsplashGallery
          data={images[page]}
          page={page}
          handlePagination={handlePagination}
          handleSelection={handleSelection}
        />
      )}
    </div>
  );
}

UnsplashContainer.propTypes = propTypes;
UnsplashContainer.defaultProps = defaultProps;

export default UnsplashContainer;
