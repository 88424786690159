export default function (url) {
  let data = {};
  const urlEmbed = url.replace('usp=sf_link', 'embedded=true');

  data = {
    type: 'rich',
    version: '1.0',
    iframe_src: urlEmbed,
    provider_name: 'Gforms',
    povider_url: 'https://docs.google.com/forms',
    width: 640,
    height: 700,
    html: `<iframe
      class="gforms"
      src="${urlEmbed}"
      width="640"
      height="600"
      frameborder="0"
      marginheight="0"
      marginwidth="0"
      ></iframe
    >`,
  };
  return new Promise((resolve) => {
    data.html_responsive = data.html;
    resolve(data);
  });
}
