export default function getAssistantConfigCommandContextValues(identifier, config) {
  let values = [];
  switch (identifier) {
    case 'tone':
      values = config.account.data.brandVoices.map((voice) => ({
        value: voice.characteristics,
        label: voice.name,
        label_description: voice.characteristics,
      }));
      break;
    default:
      break;
  }

  return values;
}
