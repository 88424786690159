/* eslint-disable consistent-return */
import { useEffect } from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  embedHtml: PropTypes.string.isRequired,
  provider: PropTypes.string.isRequired,
};

const defaultProps = {};

function createHubspotForms() {
  if (typeof window.hbspt !== 'undefined') {
    document.getElementsByClassName('js-hubspot-form').forEach((hubspotForm) => {
      window.hbspt.forms.create({
        region: hubspotForm.dataset.region,
        portalId: hubspotForm.dataset.portalId,
        formId: hubspotForm.dataset.formId,
      });
    });
  }
}

function EmbedsWithScript(props) {
  // props
  const { embedHtml, provider } = props;

  const embedsScriptData = {
    imgur: {
      async: 1,
      src: '//s.imgur.com/min/embed.js',
      charset: 'utf-8',
      id: `${provider}-sdk`,
      onload: () => {
        // We need to overwrite the tasks to the amount of imgur embeds
        // that are included in this story
        if (window.imgurEmbed) {
          const tasks = document.getElementsByClassName('imgur-embed-pub').length;
          window.imgurEmbed.tasks = tasks;
        }
      },
    },
    instagram: {
      src: '//platform.instagram.com/en_US/embeds.js',
      id: `${provider}-sdk`,
    },
    twitter: {
      src: '//platform.twitter.com/widgets.js',
      id: `${provider}-sdk`,
    },
    typeform: {
      src: 'https://embed.typeform.com/embed.js',
      id: `${provider}-sdk`,
    },
    pinterest: {
      defer: 1,
      async: 1,
      id: `${provider}-sdk`,
      src: 'https://assets.pinterest.com/js/pinit.js',
    },
    tiktok: {
      src: 'https://www.tiktok.com/embed.js',
      id: `${provider}-sdk`,
      async: 1,
    },
    facebookPost: {
      src: 'https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v10.0',
      id: `${provider}-sdk`,
      async: 1,
      defer: 1,
    },
    hubspotForm: {
      src: '//js.hsforms.net/forms/v2.js',
      id: `${provider}-sdk`,
      async: 1,
      onload: () => {
        // hubspot forms are not initialized automatically
        createHubspotForms();
      },
    },
  };

  const createMarkup = () => ({ __html: embedHtml });

  const appendScript = () => {
    const isScriptAdded = document.getElementById(embedsScriptData[provider].id);
    if (!isScriptAdded) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = embedsScriptData[provider].async;
      script.src = embedsScriptData[provider].src;
      script.charset = embedsScriptData[provider].charset;
      script.id = embedsScriptData[provider].id;
      script.crossOrigin = embedsScriptData[provider].crossOrigin;
      script.defer = embedsScriptData[provider].defer;
      script.onload = embedsScriptData[provider].onload;
      document.body.appendChild(script);
    }
  };

  useEffect(() => {
    // Load external scripts if needed
    if (embedsScriptData[provider]) {
      appendScript();
    }
    // When script is already loaded we trigger the constructor function to render the embed
    switch (provider) {
      case 'twitter':
        if (typeof window.twttr !== 'undefined') {
          window.twttr.widgets.load();
        }
        break;
      case 'instagram':
        if (typeof window.instgrm !== 'undefined') {
          window.instgrm.Embeds.process();
        }
        break;
      case 'typeform':
        if (typeof window.typeformEmbed !== 'undefined') {
          document.getElementsByClassName('typeform-widget').forEach((typeform) => {
            window.typeformEmbed.makeWidget(typeform, typeform.dataset.url);
          });
        }
        break;
      case 'infogram':
        if (typeof window.InfogramEmbeds !== 'undefined') {
          window.InfogramEmbeds.process();
        }
        break;
      case 'pinterest': {
        if (typeof window.PinUtils !== 'undefined') {
          window.PinUtils.build();
        }
        break;
      }
      case 'imgur': {
        if (typeof window.imgurEmbed !== 'undefined') {
          window.imgurEmbed.createIframe();
        }
        break;
      }
      case 'tiktok':
        if (typeof window.tiktokEmbed !== 'undefined') {
          document.getElementsByClassName('tiktok-embed').forEach((tiktokEl) => {
            window.tiktokEmbed.lib.render([tiktokEl]);
          });
        }
        break;
      case 'facebookPost':
        if (typeof window.FB !== 'undefined') {
          window.FB.XFBML.parse();
        }
        break;
      case 'hubspotForm':
        createHubspotForms();
        break;
      case 'activeCampaignForm':
        // Each form needs a separate script. There is no sdk available in window object to initialize forms
        document.getElementsByClassName('activecampaign-form').forEach((acfEl) => {
          const embedScriptUrl = acfEl.dataset.embedScriptUrl;
          const formId = acfEl.dataset.formId;
          const scriptId = `${provider}-${formId}-sdk`;
          const script = document.getElementById(scriptId);
          if (script) script.remove();
          const newScript = document.createElement('script');
          newScript.type = 'text/javascript';
          newScript.id = scriptId;
          newScript.src = embedScriptUrl;
          document.body.appendChild(newScript);
        });
        break;
      default:
        break;
    }
  }, []);

  return <div dangerouslySetInnerHTML={createMarkup()} />;
}

EmbedsWithScript.propTypes = propTypes;
EmbedsWithScript.defaultProps = defaultProps;

export default EmbedsWithScript;
