import { Block, BASE_BLOCK_CLASS, BASE_BLOCK_EMPTY_CLASS } from '../../constants/Constants';

/*
 Get custom classnames for each of the different block types supported.
 */

export default (block) => {
  const blockData = block.getData().toObject();
  const emptyClass = block.text === '' ? BASE_BLOCK_EMPTY_CLASS : '';
  const diffClass = blockData.diff ? `diff-${blockData.diff}` : '';
  let baseClass = `${BASE_BLOCK_CLASS} ${diffClass} ${emptyClass}`;

  switch (block.getType()) {
    case Block.BLOCKQUOTE:
      return `${baseClass} ${BASE_BLOCK_CLASS}-quote`;
    case Block.UNSTYLED:
      return `${baseClass} ${BASE_BLOCK_CLASS}-paragraph`;
    case Block.CODE:
      return `${baseClass} ${BASE_BLOCK_CLASS}-code`;
    case Block.ATOMIC: {
      baseClass += ` ${blockData.size}`;
      if (blockData.alignment) {
        baseClass += ` strchf-align-${blockData.alignment}`;
      }
      return `${baseClass} ${BASE_BLOCK_CLASS}-${blockData.type}`;
    }
    case Block.CAPTION:
      return `${baseClass} ${BASE_BLOCK_CLASS}-caption`;
    default:
      return `${baseClass}`;
  }
};
