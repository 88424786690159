import PropTypes from 'prop-types';
import { EditorState, RichUtils } from 'draft-js';
import { Form, FormGroup, Button } from 'react-bootstrap';
import { Icon } from '@iconify-icon/react';
import getEntitySelectionState from '../../utils/getEntitySelectionState';

const propTypes = {
  getEditorState: PropTypes.func.isRequired,
  setEditorState: PropTypes.func.isRequired,
  linkEntity: PropTypes.shape({
    entity: PropTypes.shape({
      getData: PropTypes.func.isRequired,
    }).isRequired,
    entityKey: PropTypes.string.isRequired,
  }).isRequired,
  showUrlInput: PropTypes.func.isRequired,
  handleRemoveLink: PropTypes.func.isRequired,
};
const defaultProps = {};

function ToolbarHyperlinkPreview(props) {
  const { getEditorState, showUrlInput, handleRemoveLink, linkEntity, setEditorState } = props;
  const url = linkEntity.entity.getData().url;

  const getHeaders = () => {
    const contentState = getEditorState().getCurrentContent();
    const headers = [];
    contentState.getBlocksAsArray().forEach((block) => {
      if (block.getType().includes('header')) {
        headers.push({
          value: block.getKey(),
          label: block.getText(),
          type: block.getType(),
        });
      }
    });
    return headers;
  };

  const getDisplayUrl = () => {
    if (url.startsWith('#')) {
      const header = getHeaders().find((h) => h.value === url.substring(1));
      if (header) {
        return header.label;
      }
    }
    return url;
  };

  const removeLink = (e) => {
    e.preventDefault();
    const contentState = getEditorState().getCurrentContent();
    const selectionState = getEditorState().getSelection();
    const newSelectionState = getEntitySelectionState(
      contentState,
      selectionState,
      linkEntity.entityKey,
    );
    const newEditorState = RichUtils.toggleLink(getEditorState(), newSelectionState, null);

    setEditorState(newEditorState);
    handleRemoveLink();
  };

  const editLink = (e) => {
    e.preventDefault();
    const contentState = getEditorState().getCurrentContent();
    const selectionState = getEditorState().getSelection();
    const newSelectionState = getEntitySelectionState(
      contentState,
      selectionState,
      linkEntity.entityKey,
    );
    const newEditorState = EditorState.forceSelection(getEditorState(), newSelectionState);

    setEditorState(newEditorState);
    showUrlInput(url);
  };

  return (
    <Form inline>
      <FormGroup bsSize="sm" controlId="formInlineName">
        <div className="form-control-static editor-toolbar__url-preview">
          {url.startsWith('#') ? (
            getDisplayUrl()
          ) : (
            <a
              rel="noreferrer noopener"
              target="_blank"
              href={url}
              title={`Preview link (${getDisplayUrl()})`}
            >
              {getDisplayUrl()}
            </a>
          )}
        </div>
      </FormGroup>
      <div className="RichEditor-controls-separator" />
      <Button
        onClick={editLink}
        type="submit"
        className="btn-link inverted RichEditor-styleButton mx-1"
        bsSize="sm"
        title="Edit link"
      >
        <span className="sr-only">Edit link</span>
        <Icon icon="fa:pencil" inline className="text-xs" />
      </Button>
      <Button
        onClick={removeLink}
        type="submit"
        className="btn-link inverted RichEditor-styleButton mx-1"
        bsSize="sm"
        title="Remove link"
      >
        <span className="sr-only">Remove link</span>
        <Icon icon="fa:trash-o" inline className="text-xs" />
      </Button>
    </Form>
  );
}

ToolbarHyperlinkPreview.propTypes = propTypes;
ToolbarHyperlinkPreview.defaultProps = defaultProps;

export default ToolbarHyperlinkPreview;
