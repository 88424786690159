import { EditorState, Modifier } from 'draft-js';

export default function replaceSelectedText(editorState, text) {
  const selectionState = editorState.getSelection();
  const contentState = editorState.getCurrentContent();
  return EditorState.push(
    editorState,
    Modifier.replaceText(contentState, selectionState, text),
    'insert-characters',
  );
}
