import request from 'superagent';
import convertIframeToResponsive from '../utils/convertIframeToResponsive';
import StoryChief from '../../storychief';

export default function (url) {
  function getSanitizedUrl() {
    const unsanitizedUrl = new URL(url);

    if (unsanitizedUrl.searchParams.has('t')) {
      unsanitizedUrl.searchParams.append(
        'start',
        unsanitizedUrl.searchParams.get('t').replace('s', ''),
      );
      unsanitizedUrl.searchParams.delete('t');
    }

    return unsanitizedUrl.toString();
  }

  function getVideoId(iframeString) {
    return iframeString.match(/(youtube-nocookie\.com\/embed\/)([a-zA-Z0-9-_]*)/)[2];
  }

  return new Promise((resolve, reject) => {
    request
      .put(`${StoryChief.dashboardBasePath}/integrations/embed/oembed`)
      .set('Accept', 'application/json')
      .set('X-Requested-With', 'XMLHttpRequest')
      .set('X-CSRF-TOKEN', StoryChief.csrfToken)
      .send({
        url: getSanitizedUrl(),
        provider: 'youtube',
      })
      .end((err, res) => {
        if (err) {
          reject(err);
        } else {
          const data = res.body;
          if (typeof data.error !== 'undefined') {
            reject(data);
          }
          data.html_responsive = convertIframeToResponsive(data.html);
          data.video_id = getVideoId(data.html);
          resolve(data);
        }
      });
  });
}
