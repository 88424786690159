import matchTwitterMention from '@/editor/utils/matchTwitterMention';
import { MENTIONS_PATTERN } from '@/editor/constants/Constants';
import { CHANNEL_TWITTER } from '@/destinations/constants/Constants';

function matchMention(text, channelType) {
  if (channelType === CHANNEL_TWITTER) {
    return matchTwitterMention(text);
  }

  return text.match(MENTIONS_PATTERN);
}

function getCurrentMultiWordMentionText(text, index) {
  // Select the mention's text relative to the caret's position. It should select full words.
  // Example input: The @storychief te|am is amazing! (the | symbol is the caret)
  // Example output: @storychief team
  const mentionStart = text.substring(0, index);
  const mentionEnd = text.substring(index, text.length);

  return mentionStart + mentionEnd.split(' ')[0];
}

function isMentionElement() {
  const selection = window.getSelection();
  return selection.rangeCount !== 0 && selection.anchorNode.parentElement.closest('.mention');
}

export function getCurrentMention({ index, text, channelType }) {
  const words = text.replace(/(\r\n|\r|\n)/gm, ' ').split(' ');
  let currentWord = '';
  let prev = 0;
  let wordIndex = 0;

  while (currentWord === '') {
    const word = words[wordIndex];
    const match = matchMention(word, channelType);

    // Mention found
    if (match) {
      const mention = match[0];
      const mentionStart = word.indexOf(mention);

      if (index > prev + mentionStart && index <= prev + mentionStart + mention.length) {
        prev += mentionStart;
        currentWord = mention;
        break;
      }
    }

    // Mention not found
    if (wordIndex + 1 === words.length) {
      break;
    }

    prev += word.length + 1;
    wordIndex += 1;
  }

  if (currentWord !== '') {
    return {
      text: currentWord.trim(),
      start: prev,
      end: prev + currentWord.length,
    };
  }

  return null;
}

export function getCurrentMentionWithSpaces({ index, text, channelType }) {
  if (isMentionElement()) {
    return null;
  }

  const words = text.replace(/(\r\n|\r|\n|\\u000A)/gm, ' ').split(' ');
  const allMentions = [];
  let currentMention = null;
  let prev = 0;
  let wordIndex = 0;

  while (wordIndex < words.length) {
    const word = words[wordIndex];

    if (currentMention === null && matchMention(word, channelType)) {
      // Fresh mention start
      currentMention = {
        text: word,
        start: prev,
      };
    } else if (currentMention !== null && matchMention(word, channelType)) {
      // One mention after another
      allMentions.push(currentMention);
      currentMention = {
        text: word,
        start: prev,
      };
    } else if (currentMention !== null && word !== '' && !matchMention(word, channelType)) {
      // Mention other words
      currentMention.text += ` ${word}`;
    } else if (currentMention !== null && word === '') {
      // End mention on new line
      allMentions.push(currentMention);
      currentMention = null;
    }

    if (currentMention !== null && wordIndex + 1 === words.length) {
      // Finish mention if end of text
      allMentions.push(currentMention);
      currentMention = null;
    }

    prev += word.length + 1;
    wordIndex += 1;
  }

  const mention = allMentions.find((m) => index > m.start && index <= m.start + m.text.length);

  if (mention) {
    const mentionText = getCurrentMultiWordMentionText(mention.text, index - mention.start);

    return {
      text: mentionText,
      start: mention.start,
      end: mention.start + mentionText.length,
    };
  }

  return null;
}
