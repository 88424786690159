import { TWITTER_MENTIONS_PATTERN } from '@/editor/constants/Constants';

export default function matchTwitterMention(input) {
  const matches = input.match(TWITTER_MENTIONS_PATTERN);

  if (matches && matches.length > 0) {
    return matches.map((m) => m.match(/@(.*)/g)[0]);
  }

  return null;
}
