export default function findWithRegex(regex, contentBlock, callback) {
  const text = contentBlock.getText();
  let matchArr;
  let start;
  let end;
  let matchedText;
  // eslint-disable-next-line no-cond-assign
  while ((matchArr = regex.exec(text)) !== null) {
    start = matchArr.index;
    end = start + matchArr[0].length;
    matchedText = matchArr[0];
    if (matchArr[0][0] === ' ') {
      start += 1;
    }
    if (matchArr[0][matchArr[0].length] === ' ') {
      end -= 1;
    }
    callback(start, end, matchedText);
  }
}
