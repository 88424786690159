import { COMMAND_IDENTIFIERS } from '../constants/Constants';

export default function cleanupAssistantCommandTextResult(commandIdentifier, text) {
  let _text = text;
  // convert sequence of TAB characters to new line
  _text = _text.replaceAll(/\t+/g, '\n');
  // remove empty space markdown bold syntax (**hello ** => **hello**)
  _text = _text
    .split('**')
    .map((_item, index) => {
      if ((index + 1) % 2 === 0) {
        return _item.trimEnd();
      }
      return _item;
    })
    .join('**');
  // remove empty space before dots
  _text = _text.replaceAll(' .', '. ');
  // remove empty space before commas
  _text = _text.replaceAll(' ,', ', ');
  // convert 3 spaces to newline
  _text = _text.replaceAll('   ', '\n');
  // add an empty space after a dot in the following sequence: number dot letter
  _text = _text.replace(/(\d)\.([a-zA-Z])/g, '$1. $2');
  // _text = _text.replaceAll(/(?<!\s\n)-\s/g, '\n- ');
  // _text = _text.replaceAll(' - ', '\n- ');

  switch (commandIdentifier) {
    case COMMAND_IDENTIFIERS.STORY_EXCERPT:
    case COMMAND_IDENTIFIERS.STORY_META_DESCRIPTION:
    case COMMAND_IDENTIFIERS.STORY_META_TITLE:
      // remove newline at start of text
      _text = _text.replace(/^\n/, '');
      // remove double and single quotes from start and end
      _text = _text.replace(/^(")(.*)\1$/, '$2');
      break;
    default:
      break;
  }

  return _text;
}
