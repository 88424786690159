import { Map, List } from 'immutable';

import { EditorState, genKey, ContentBlock } from 'draft-js';
import { Block } from '../constants/Constants';
import getDefaultBlockData from './getDefaultBlockData';
/*
 Used from [react-rte](https://github.com/sstur/react-rte/blob/master/src/lib/insertBlockAfter.js)
 by [sstur](https://github.com/sstur)
 */
export default function addNewBlockAt(
  editorState,
  pivotBlockKey,
  newBlockType = Block.UNSTYLED,
  initialData = {},
  isAppend = true,
) {
  const content = editorState.getCurrentContent();
  const blockMap = content.getBlockMap();
  const block = blockMap.get(pivotBlockKey);
  const blocksBefore = blockMap.toSeq().takeUntil((v) => v === block);
  const blocksAfter = blockMap
    .toSeq()
    .skipUntil((v) => v === block)
    .rest();
  const newBlockKey = genKey();

  const newBlock = new ContentBlock({
    key: newBlockKey,
    type: newBlockType,
    text: '',
    characterList: List(),
    depth: 0,
    data: Map(getDefaultBlockData(newBlockType, initialData)),
  });

  function getPivotAndNewBlocks() {
    if (isAppend) {
      return [
        [pivotBlockKey, block],
        [newBlockKey, newBlock],
      ];
    }

    return [
      [newBlockKey, newBlock],
      [pivotBlockKey, block],
    ];
  }

  const newBlockMap = blocksBefore.concat(getPivotAndNewBlocks(), blocksAfter).toOrderedMap();

  const selection = editorState.getSelection();

  const newContent = content.merge({
    blockMap: newBlockMap,
    selectionBefore: selection,
    selectionAfter: selection.merge({
      anchorKey: newBlockKey,
      anchorOffset: 0,
      focusKey: newBlockKey,
      focusOffset: 0,
      isBackward: false,
    }),
  });
  return EditorState.push(editorState, newContent, 'split-block');
}
