import PropTypes from 'prop-types';
import { Editor } from 'draft-js';
import classNames from 'classnames';
import DEFAULT_PLUGINS from '@/editor/plugins/default';
import { CUSTOM_BLOCK_STYLES } from '@/editor/constants/Constants';
import blockRendererFn from '@/editor/components/blocks/blockRendererFn';
import blockStyleFn from '@/editor/components/blocks/blockStyleFn';

const propTypes = {
  id: PropTypes.string.isRequired,
  editorState: PropTypes.shape([]),
  selectable: PropTypes.bool,
  onSelect: PropTypes.func,
  selected: PropTypes.bool,
};

const defaultProps = {
  editorState: undefined,
  selectable: false,
  onSelect: undefined,
  selected: false,
};

function CommandResultTranscriptItemContent({ id, editorState, selectable, selected, onSelect }) {
  function handleOnClick() {
    if (!selectable) return;
    if (onSelect) onSelect(id);
  }
  if (!editorState) {
    return null;
  }

  const componentClassName = classNames('command-result__transcript-item__content', {
    'command-result__transcript-item__content--selectable d-flex flex-gap-lg flex-align-items-center':
      selectable,
    'command-result__transcript-item__content--selected': selected,
  });

  return (
    <div className={componentClassName} onClick={handleOnClick} onKeyPress={handleOnClick}>
      {selectable && (
        <div className="selected-indicator">
          <div />
        </div>
      )}
      <Editor
        readOnly
        editorState={editorState}
        blockStyleFn={blockStyleFn}
        blockRendererFn={blockRendererFn(
          () => {},
          () => editorState,
          () => {},
          () => {},
          () => {},
          () => {},
          DEFAULT_PLUGINS,
        )}
        customStyleMap={CUSTOM_BLOCK_STYLES}
      />
    </div>
  );
}

CommandResultTranscriptItemContent.propTypes = propTypes;
CommandResultTranscriptItemContent.defaultProps = defaultProps;

export default CommandResultTranscriptItemContent;
