export default function getEditorInlineSelection(contentState) {
  let styles = [];

  contentState.getBlocksAsArray().forEach((block) => {
    const currentBlockKey = block.getKey();

    let currentInlineStyles = null;
    block.findStyleRanges(
      (character) => {
        currentInlineStyles = null;
        if (character.getStyle() !== null) {
          let inlineStyles = character.getStyle();

          inlineStyles = inlineStyles.filter((value) => value.startsWith('SELECTION'));

          if (inlineStyles.size) {
            currentInlineStyles = inlineStyles;
            inlineStyles.forEach((inlineStyle) => {
              if (styles.find((style) => style.key === inlineStyle)) {
                styles = styles.map((style) => {
                  if (style.key === inlineStyle) {
                    const ranges = style.ranges.slice();
                    ranges.push({ blockKey: currentBlockKey });
                    return { ...style, ranges };
                  }
                  return style;
                });
              } else {
                styles.push({
                  key: inlineStyle,
                  ranges: [{ blockKey: currentBlockKey }],
                });
              }
            });
            return true;
          }
        }
        return false;
      },
      (start, end) => {
        styles = styles.map((style) => {
          if (currentInlineStyles.find((inlineStyle) => inlineStyle === style.key)) {
            let ranges = style.ranges.slice();
            ranges = ranges.map((range) => {
              if (range.blockKey === currentBlockKey && range.start === undefined) {
                return { ...range, start, end };
              }
              return range;
            });
            return { ...style, ranges };
          }
          return style;
        });
      },
    );
  });

  return styles;
}
