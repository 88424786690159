import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  gist: PropTypes.string.isRequired,
};

const defaultProps = {};

// GitHub embed script uses “document.write” to insert the code into the page.
// By the time react inserts the <script> element into the page,
// it’s too late to write to the document. As such, nothing is displayed.
// This component is one of the solutions for this issue.

function EmbedGist(props) {
  const { gist } = props;
  const [src, setSrc] = useState('');
  let stylesheetAdded = false;

  // The Gist JSON data includes a stylesheet to add to the page
  // to make it look correct. `addStylesheet` ensures we only add
  // the stylesheet one time.
  const addStylesheet = (href) => {
    if (!stylesheetAdded) {
      stylesheetAdded = true;
      const link = document.createElement('link');
      link.type = 'text/css';
      link.rel = 'stylesheet';
      link.href = href;

      document.head.appendChild(link);
    }
  };

  useEffect(() => {
    // Create a JSONP callback that will set our state
    // with the data that comes back from the Gist site
    const gistCallback = EmbedGist.nextGistCallback();
    window[gistCallback] = (_gist) => {
      setSrc(_gist.div);
      addStylesheet(_gist.stylesheet);
    };

    const url = `${gist}.json?callback=${gistCallback}`;

    // Add the JSONP script tag to the document.
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;
    document.head.appendChild(script);
  }, []);

  return <div dangerouslySetInnerHTML={{ __html: src }} />;
}

// Each time we request a Gist, we’ll need to generate a new
// global function name to serve as the JSONP callback.
let gistCallbackId = 0;
EmbedGist.nextGistCallback = () => `embed_gist_callback_${(gistCallbackId += 1)}`; // eslint-disable-line no-return-assign

EmbedGist.propTypes = propTypes;
EmbedGist.defaultProps = defaultProps;

export default EmbedGist;
