import { EditorState, SelectionState } from 'draft-js';

export default function selectAll(editorState) {
  const currentContent = editorState.getCurrentContent();

  const selection = new SelectionState({
    anchorKey: currentContent.getFirstBlock().getKey(),
    anchorOffset: 0,
    focusOffset: currentContent.getLastBlock().getText().length,
    focusKey: currentContent.getLastBlock().getKey(),
  });
  return EditorState.forceSelection(editorState, selection);
}
