import { Popover } from 'react-bootstrap';
import PropTypes from 'prop-types';
// The dependency cycle is caused by <TextEditor> importing assistant components and those components also importing <TextEditor>.
// The issue is mitigated by disabling the assistant on <TextEditor> inside the assistant components.
// eslint-disable-next-line import/no-cycle
import Command from './Command';
import useAssistant from '@/assistant/hooks/useAssistant';
import CommandAssistantConfig from './CommandAssistantConfig';
import AssistantDropdownMenuItems from '../AssistantDropdownMenuItems';
import { COMMAND_IDENTIFIERS } from '@/assistant/constants/Constants';

const propTypes = {
  positionTop: PropTypes.number.isRequired,
  width: PropTypes.number,
  size: PropTypes.oneOf(['sm', 'lg']),
};
const defaultProps = {
  width: 700,
  size: undefined,
};

function CommandPopover({ positionTop, width, size }) {
  const { command, discardCommand, loading, result, setResult, getEditorState, setEditorState } =
    useAssistant();

  if (!command) {
    return null;
  }

  return (
    <Popover
      id="commandPopover"
      className="command--popover"
      positionTop={positionTop}
      placement="bottom"
      width={width}
      style={{ width }}
      onClick={(e) => {
        // fix that prevents PostMessage and PostsetPostConfigurator taking over focus
        e.stopPropagation();
      }}
    >
      <div className="popover-content-header">
        <div className="d-flex flex-column flex-gap-md w-100">
          <div className="d-flex flex-space-between">
            <span>
              <em className="icon-rocket" />{' '}
              <span data-testid="popover-content-header-title">{command.title}</span>
            </span>

            <div className="d-flex flex-gap-lg">
              {!command.autoRun && !!result && (
                <div className="filter-transparent">
                  <button type="button" className="btn-chromeless" onClick={() => setResult()}>
                    Restart
                  </button>
                </div>
              )}
              <button type="button" className="btn-chromeless" onClick={discardCommand}>
                <em className="icon-cancel" /> <span className="sr-only">cancel</span>
              </button>
            </div>
          </div>
          <CommandAssistantConfig insidePopover />
        </div>
      </div>
      <div className="space-top-1">
        {!(result || loading) && (
          <div className="space-top-1 no-line-height">
            {size && size === 'sm' ? (
              <small>{command.description}</small>
            ) : (
              <div>{command.description}</div>
            )}
          </div>
        )}
        <div className="space-top-1">
          <Command insidePopover size={size} />
        </div>
        {command.identifier === COMMAND_IDENTIFIERS.CHAT && !result && (
          <ul className="dropdown-menu d-block">
            <AssistantDropdownMenuItems
              getEditorState={getEditorState}
              setEditorState={setEditorState}
              pullRight={false}
            />
          </ul>
        )}
      </div>
    </Popover>
  );
}

CommandPopover.propTypes = propTypes;
CommandPopover.defaultProps = defaultProps;

export default CommandPopover;
