import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import classNames from 'classnames';
import { useEffect, useState, forwardRef, useRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import getSelection from '@/editor/utils/getSelection';
import getSelectionRect from '@/editor/utils/getSelectionRect';
import StoryChief from '@/storychief';
import { usePrevious } from '@/storychief/hooks';
import {
  CHANNEL_TWITTER,
  CHANNEL_LINKEDIN,
  CHANNEL_LINKEDIN_PROFILE,
  CHANNEL_FACEBOOK,
  CHANNEL_INSTAGRAM,
} from '@/destinations/constants/Constants';
import PointerEventsWrapper from '@/storychief/components/PointerEventsWrapper';
import ConditionalWrapper from '@/storychief/components/ConditionalWrapper';

const propTypes = {
  onMentionSuggestionClick: PropTypes.func.isRequired,
  mentionSearchQuery: PropTypes.string,
  mentions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      display_name: PropTypes.string,
      picture: PropTypes.string,
    }),
  ),
  mentionSearchPending: PropTypes.bool,
  mentionMinLengthSearch: PropTypes.number,
  mentionDisabledTooltip: PropTypes.string,
  destination: PropTypes.shape({
    channel_type: PropTypes.number.isRequired,
    info: PropTypes.string,
    auth_url: PropTypes.string,
  }),
  enabled: PropTypes.bool,
  show: PropTypes.bool,
};

const defaultProps = {
  mentions: null,
  mentionSearchPending: false,
  mentionSearchQuery: '',
  mentionMinLengthSearch: 0,
  mentionDisabledTooltip: null,
  destination: null,
  enabled: true,
  show: false,
};

const MentionSuggestions = forwardRef(
  (
    {
      onMentionSuggestionClick,
      mentionSearchPending,
      mentions,
      mentionSearchQuery,
      mentionMinLengthSearch,
      mentionDisabledTooltip,
      destination,
      enabled,
      show,
    },
    ref,
  ) => {
    // States
    const [currentItem, setCurrentItem] = useState(0);
    const [isKeyPress, setIsKeyPress] = useState(false);
    const _ref = useRef();

    // Hooks
    const prevMentionSearchQuery = usePrevious(mentionSearchQuery);

    useImperativeHandle(ref, () => ({
      handleEnter() {
        if (mentions && mentions[currentItem]) {
          onMentionSuggestionClick(mentions[currentItem]);
          return 'handled';
        }
        return 'not-handled';
      },
      handleOnArrowKeyClick(direction) {
        switch (direction) {
          case 'up':
            setCurrentItem(currentItem === 0 ? mentions.length - 1 : currentItem - 1);
            break;
          case 'down':
            setCurrentItem(mentions.length - 1 === currentItem ? 0 : currentItem + 1);
            break;
          default:
            break;
        }
      },
    }));

    // Effects
    useEffect(() => {
      const scroller = document.querySelector('#overlay') || document;

      scroller.addEventListener('scroll', handleScroll);

      return () => scroller.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
      positionElement({ mentionSearchQuery: prevMentionSearchQuery });
    });

    useEffect(() => {
      if (isKeyPress) {
        scrollFocusedItemIntoView();
      }
    }, [prevMentionSearchQuery, currentItem, isKeyPress]);

    // Functions
    function scrollFocusedItemIntoView() {
      const current = _ref.current;

      if (current) {
        const focusedEl = current.querySelector('.select-menu__item--focused');

        if (focusedEl) {
          focusedEl.scrollIntoView({ block: 'nearest' });
        }
      }
    }

    function handleScroll() {
      if (show) {
        window.requestAnimationFrame(() => {
          positionElement();
        });
      }
    }

    function positionElement(prevProps) {
      const current = _ref.current;
      const nativeSelection = getSelection(window);

      if (!nativeSelection.rangeCount) {
        return;
      }

      const selectionBoundary = getSelectionRect(nativeSelection);

      if (!selectionBoundary || checkIsHidden()) return;

      let left = selectionBoundary.left;
      let top = selectionBoundary.top;

      // Correcting suggestions element when going pass the window's width
      const windowWidth = window.innerWidth;
      let correctionWidth;

      if (current) {
        const isLocatedInsideSidebar = !!current.closest('.sidebar-container');
        const isLocatedInsideEditorComments = !!current.closest('.sc-editor-comments');
        const mentionSuggestionsBoundary = current.getBoundingClientRect();
        const totalWidth = selectionBoundary.left + mentionSuggestionsBoundary.width;

        if (isLocatedInsideSidebar) {
          const sidebarNode = document.querySelector('.sidebar-container');
          const sidebarBoundary = sidebarNode.getBoundingClientRect();

          left -= sidebarBoundary.left;
          top -= sidebarBoundary.top;
        }

        if (isLocatedInsideEditorComments) {
          const sidebarNode = document.querySelector('.sc-editor-comments');
          const sidebarBoundary = sidebarNode.getBoundingClientRect();

          left -= sidebarBoundary.left;
          top -= sidebarBoundary.top;
        }

        if (totalWidth > windowWidth) {
          correctionWidth = windowWidth - totalWidth;
          left += correctionWidth - 15; // always 15px from window
        }

        current.style.left = `${left}px`;
        current.style.top = `${top}px`;

        if (prevProps.mentionSearchQuery !== mentionSearchQuery) {
          current.scrollTop = 0;
        }
      }
    }

    function onSuggestionHover(e, idx) {
      e.preventDefault();
      setCurrentItem(idx);
      setIsKeyPress(false);
    }

    function onSuggestionClick(e, mention) {
      e.preventDefault();
      onMentionSuggestionClick(mention);
    }

    function onHelpArticleClick(e) {
      e.preventDefault();
    }

    function checkHelpArticleUrlShowOnResults() {
      switch (destination?.channel_type) {
        case CHANNEL_FACEBOOK:
        case CHANNEL_LINKEDIN:
        case CHANNEL_LINKEDIN_PROFILE:
          return mentions?.length === 0;
        default:
          return false;
      }
    }

    function getHelpArticleAnchor() {
      switch (destination?.channel_type) {
        case CHANNEL_FACEBOOK:
          return '#h_9e4e543e75';
        case CHANNEL_TWITTER:
          return '#h_1ccaa817b2';
        case CHANNEL_LINKEDIN:
        case CHANNEL_LINKEDIN_PROFILE:
          return '#h_de873e134a';
        case CHANNEL_INSTAGRAM:
          return '#h_2329681a46';
        default:
          return '';
      }
    }

    function getHelpArticleUrl() {
      return `https://help.storychief.io/en/articles/6525292-mention-guidelines-in-social-posts${getHelpArticleAnchor()}`;
    }

    function getMentionGuidelineUrl() {
      return `http://help.storychief.io/en/articles/6525292-mention-guidelines`;
    }

    function renderMentions() {
      if (destination?.channel_type === CHANNEL_INSTAGRAM) {
        return (
          <div className="select-menu__item select-menu__item--create">
            To mention on Instagram, use &apos;@&apos; followed by the exact handle. <br />
            The account will be automatically mentioned on the live post.{' '}
            <a
              href={getHelpArticleUrl()}
              target="_blank"
              rel="noopener noreferrer"
              onMouseDown={(e) => onHelpArticleClick(e)}
            >
              More info
            </a>
            .
          </div>
        );
      }

      if (!enabled) {
        return (
          <div className="select-menu__item select-menu__item--create">
            Mentions are not supported on this channel.
          </div>
        );
      }

      if (mentionSearchPending) {
        return (
          <div className="select-menu__item select-menu__item--create">
            Loading{' '}
            <span className="animated-spin d-inline-block">
              <span className="icon-spin1" />
            </span>
          </div>
        );
      }

      if (mentions.length === 0) {
        return (
          <div className="select-menu__item select-menu__item--create">
            No results found.{' '}
            {destination && (
              <a
                href={getHelpArticleUrl()}
                target="_blank"
                rel="noopener noreferrer"
                onMouseDown={(e) => onHelpArticleClick(e)}
              >
                Not seeing your result?
              </a>
            )}
          </div>
        );
      }

      const mentionsRender = mentions.map((mention, idx) => {
        const src = mention.picture
          ? mention.picture
          : StoryChief.asset('images/placeholders/profile.png');

        return (
          <ConditionalWrapper
            key={mention.id}
            condition={mention.disabled && mentionDisabledTooltip}
            wrapper={(children) => (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="mention-suggestions__tooltip">{mentionDisabledTooltip}</Tooltip>
                }
              >
                <PointerEventsWrapper>{children}</PointerEventsWrapper>
              </OverlayTrigger>
            )}
          >
            <div
              data-key={mention.id}
              className={classNames('select-menu__item', {
                'select-menu__item--focused': currentItem === idx,
                'select-menu__item--disabled': mention.disabled,
              })}
              onFocus={(e) => onSuggestionHover(e, idx)}
              onMouseOver={(e) => onSuggestionHover(e, idx)}
              onMouseDown={(e) => onSuggestionClick(e, mention)}
              data-testid={`mention-user-${mention.id}`}
            >
              <div className="mention-suggestions__item">
                <img className="mention-suggestion-picture" src={src} alt={mention.name} />
                {renderMentionSuggestionName(mention)}
              </div>
            </div>
          </ConditionalWrapper>
        );
      });

      const helpArticleUrlRender = (
        <div className="select-menu__item select-menu__item--create">
          <a
            href={getHelpArticleUrl()}
            target="_blank"
            rel="noopener noreferrer"
            onMouseDown={(e) => onHelpArticleClick(e)}
          >
            Not seeing your result?
          </a>
        </div>
      );

      return (
        <>
          {checkHelpArticleUrlShowOnResults() && helpArticleUrlRender}
          {mentions.length > 0 && !!destination && (
            <div className="select-menu__item select-menu__item--create">
              Please read our mention{' '}
              <a
                href={getMentionGuidelineUrl()}
                target="_blank"
                rel="noopener noreferrer"
                onMouseDown={(e) => onHelpArticleClick(e)}
              >
                guidelines
              </a>
            </div>
          )}
          {mentionsRender}
        </>
      );
    }

    function renderMentionSuggestionName(mention) {
      let mentionSuggestionName = <span className="mention-suggestion-name">{mention.name}</span>;

      if (mention.display_name) {
        mentionSuggestionName = (
          <span className="mention-suggestion-name">
            {mention.display_name}
            <br />
            <span className="text-muted">{mention.name}</span>
          </span>
        );
      }

      return mentionSuggestionName;
    }

    function checkIsHidden() {
      return mentions === null && !mentionSearchPending && enabled;
    }

    const nativeSelection = getSelection(window);

    // Rendering
    if (
      !show ||
      !nativeSelection.rangeCount ||
      checkIsHidden() ||
      (enabled && mentionSearchQuery.length < mentionMinLengthSearch)
    ) {
      return null;
    }

    return (
      <div className="relative">
        <div className="mention-suggestions" ref={_ref} data-testid="mention-suggestions">
          {renderMentions()}
        </div>
      </div>
    );
  },
);

MentionSuggestions.propTypes = propTypes;
MentionSuggestions.defaultProps = defaultProps;

export default MentionSuggestions;
