import PropTypes from 'prop-types';
import selectBlock from '../../utils/selectBlock';

const propTypes = {
  block: PropTypes.shape({
    key: PropTypes.string,
    getData: PropTypes.func,
  }),
  blockProps: PropTypes.shape({
    isSelected: PropTypes.bool,
    editorEnabled: PropTypes.bool,
    getEditorState: PropTypes.func,
    setEditorState: PropTypes.func,
    setReadOnly: PropTypes.func,
  }),
};
const defaultProps = {
  block: null,
  blockProps: null,
};

function ButtonBlock({ block, blockProps }) {
  const blockData = block.getData();
  const { isSelected, editorEnabled } = blockProps;
  const isClickable = editorEnabled && !isSelected;

  // Functions
  function handleOnButtonBlick() {
    blockProps.setEditorState(selectBlock(block, blockProps.getEditorState()));
  }

  return (
    <div
      contentEditable={false}
      onClick={isClickable ? handleOnButtonBlick : null}
      onKeyPress={isClickable ? handleOnButtonBlick : null}
    >
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, react/jsx-no-target-blank */}
      <a
        style={{
          borderRadius: '4px',
          display: 'inline-block',
          lineHeight: '1em',
          padding: '0.7em 1.2em',
          textDecoration: 'none',
          background: '#001fe6',
          color: '#ffffff',
        }}
        href={!editorEnabled ? blockData.get('href') : undefined}
        target={blockData.get('targetBlank') ? '_blank' : '_self'}
        rel={blockData.get('noFollow') ? 'nofollow' : 'noreferrer'}
      >
        {blockData.get('textButton') || 'Button Text'}
      </a>
    </div>
  );
}

ButtonBlock.propTypes = propTypes;
ButtonBlock.defaultProps = defaultProps;

export default ButtonBlock;
