export default function setCommandLocation(
  location,
  history,
  command,
  input = undefined,
  context = undefined,
  result = undefined,
) {
  const basePath = location.pathname.replace(/\/assistant\/?(.*)$/i, '');
  history.push(`${basePath}/assistant/${command ? command.slug : ''}`, {
    input,
    context,
    result,
  });
}
