import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useRef, useState } from 'react';
import { EditorState, convertFromRaw } from 'draft-js';
import getDefaultDecorator from '@/editor/decorators/getDefaultDecorator';
import useAssistant from '@/assistant/hooks/useAssistant';
import { COMMANDS } from '@/assistant/constants/Constants';

const propTypes = {
  getEditorNode: PropTypes.func.isRequired,
  focusEditor: PropTypes.func.isRequired,
};

const defaultProps = {};

function AssistantPlaceholder({ getEditorNode, focusEditor }) {
  const { initCommand, command, subject, config, setEditorState, getEditorState } = useAssistant();
  const placeHolderID = useRef(config.local.data.placeholders.length);
  const [itemIndex, setItemIndex] = useState(-1);
  const [discarded, setDiscarded] = useState(!subject.isEmpty);
  const componentRef = useRef();

  const assistantRecommendations = useMemo(() => {
    const _command = Object.values(COMMANDS).find((_c) => _c.contentTypes?.includes(subject.type));

    if (_command) {
      if (_command.items) {
        // This command has predefined placeholder items
        return prepareActionsBasedOnPlaceholderConfig(_command);
      }

      return prepareCommandAction(_command);
    }

    return []; // Nothing to show
  }, [subject.type]);

  const assistantRecommendationsOffset = useMemo(
    () => (subject.template ? 2 : 1),
    [subject.template],
  );

  const itemsCount = useMemo(
    () => assistantRecommendations.length + assistantRecommendationsOffset,
    [assistantRecommendations],
  );

  function prepareCommandAction(_command) {
    return [
      {
        title: _command.title,
        identifier: _command.identifier,
        icon: _command.icon,
        handle: () => initCommand(_command),
      },
    ];
  }

  function prepareActionsBasedOnPlaceholderConfig(_command) {
    return [
      {
        title: _command.title.toLowerCase(),
        identifier: `${_command.identifier}-${_command.title}`,
        icon: _command.icon,
        handle: () => {
          initCommand(_command);
        },
      },
      ..._command.items
        .filter((_v) => _v.title.toLowerCase() !== 'autodetect')
        .map((_v) => ({
          title: _v.title.toLowerCase(),
          identifier: `${_command.identifier}-${_v.title}`,
          icon: _v.icon,
          handle: () => {
            initCommand(
              Object.values(COMMANDS).find(({ identifier }) => identifier === _v.command),
              _v.value,
            );
          },
        })),
    ];
  }

  function onKeyPress(event) {
    if (placeHolderID.current === config.local.data.placeholders.length - 1) {
      if (event.keyCode === 13) {
        event.preventDefault();
        if (itemIndex <= 0) {
          handleOnEmptyClick();
          return;
        }
        if (itemIndex === 1 && !!subject.template) {
          handleOnTemplateClick();
        } else {
          handleOnCommandClick(
            assistantRecommendations[itemIndex - assistantRecommendationsOffset],
          );
        }
      }
      if (event.keyCode === 38) {
        event.preventDefault();
        setItemIndex((prevIndex) => (prevIndex === 0 ? itemsCount - 1 : prevIndex - 1));
      }
      if (event.keyCode === 40) {
        event.preventDefault();
        setItemIndex((prevIndex) => (prevIndex === itemsCount - 1 ? 0 : prevIndex + 1));
      }
    }
  }

  useEffect(() => {
    const placeholders = config.local.data.placeholders;
    placeholders.push(placeHolderID.current);
    config.local.set({
      placeholders,
    });
    return () => {
      config.local.set({
        placeholders: config.local.data.placeholders.filter((_p) => _p !== placeHolderID.current),
      });
    };
  }, []);

  useEffect(() => {
    const editorNode = getEditorNode().editor;
    if (editorNode) {
      if (subject.isEmpty && !discarded) {
        editorNode.parentElement.parentElement.classList.add('hide');
      } else {
        editorNode.parentElement.parentElement.classList.remove('hide');
      }
    }
  }, [subject.isEmpty, discarded, command?.identifier]);

  useEffect(() => {
    const editorNode = getEditorNode().editor;
    if (!discarded && command?.identifier) {
      // We also need to remove the hide class from the editor because seDiscarded is async
      editorNode.parentElement.parentElement.classList.remove('hide');
      setDiscarded(true);
    }
  }, [command?.identifier]);

  useEffect(() => {
    if (!discarded) {
      document.addEventListener('keydown', onKeyPress);
    } else {
      document.removeEventListener('keydown', onKeyPress);
    }
    return () => {
      document.removeEventListener('keydown', onKeyPress);
    };
  }, [discarded, config.local.data.placeholders, itemIndex]);

  function handleOnCommandClick({ handle }) {
    setDiscarded(true);
    handle();
  }

  function handleOnTemplateClick() {
    setDiscarded(true);
    const editorState = EditorState.createWithContent(
      convertFromRaw(subject.template.contentRaw),
      getDefaultDecorator(),
    );
    setEditorState(editorState);
    setTimeout(() => {
      focusEditor();
    }, 1);
  }

  function handleOnEmptyClick() {
    setDiscarded(true);
    // force update so sidebar becomes visible
    setEditorState(EditorState.forceSelection(getEditorState(), getEditorState().getSelection()));
    setTimeout(() => {
      focusEditor();
    }, 1);
  }

  if (discarded) {
    return null;
  }

  return (
    <div ref={componentRef} className="assistant-placeholder DraftEditor-root text-muted">
      <p>Press Enter to continue with an empty page, or pick a template (↑↓ to select)</p>
      <ul className="list-unstyled">
        <li>
          <div
            role="button"
            onMouseOver={() => setItemIndex(0)}
            onFocus={() => setItemIndex(0)}
            onClick={handleOnEmptyClick}
            onKeyPress={handleOnEmptyClick}
            tabIndex="0"
            className={itemIndex === 0 ? 'active' : ''}
          >
            <em className="icon-doc" />
            <span>Start from scratch</span>
          </div>
        </li>
        {subject.template && (
          <li>
            <div
              role="button"
              onMouseOver={() => setItemIndex(1)}
              onFocus={() => setItemIndex(1)}
              onClick={handleOnTemplateClick}
              onKeyPress={handleOnTemplateClick}
              tabIndex="0"
              className={itemIndex === 1 ? 'active' : ''}
            >
              <em className="icon-doc" />
              <span>Use default template</span>
            </div>
          </li>
        )}
        {assistantRecommendations.map((_action, _index) => (
          <li key={_action.identifier}>
            <div
              className={classNames('d-flex flex-align-items-center', {
                active: itemIndex === _index + assistantRecommendationsOffset,
              })}
              role="button"
              onMouseOver={() => setItemIndex(_index + assistantRecommendationsOffset)}
              onFocus={() => setItemIndex(_index + assistantRecommendationsOffset)}
              onClick={() => handleOnCommandClick(_action)}
              onKeyPress={() => handleOnCommandClick(_action)}
              tabIndex="0"
            >
              <img style={{ width: '1.4em' }} src={_action.icon} alt={_action.title} />
              <span>
                Write a{_action.title.match('^[aieouAIEOU].*') ? 'n' : ''} {_action.title}{' '}
                {subject.storyTitle ? `"${subject.storyTitle}"` : ''}with AI
              </span>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

AssistantPlaceholder.propTypes = propTypes;
AssistantPlaceholder.defaultProps = defaultProps;

export default AssistantPlaceholder;
