import PropTypes from 'prop-types';

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};
const defaultProps = {};

export function OverflowSpan(props) {
  return <span className="overflow">{props.children}</span>;
}

OverflowSpan.propTypes = propTypes;
OverflowSpan.defaultProps = defaultProps;

export function LinkSpan(props) {
  return <span className="link">{props.children}</span>;
}

LinkSpan.propTypes = propTypes;
LinkSpan.defaultProps = defaultProps;

export function MentionSpan(props) {
  return <span className="mention">{props.children}</span>;
}

MentionSpan.propTypes = propTypes;
MentionSpan.defaultProps = defaultProps;

export function MentionDisabledSpan(props) {
  return <span className="mention-disabled">{props.children}</span>;
}

MentionDisabledSpan.propTypes = propTypes;
MentionDisabledSpan.defaultProps = defaultProps;

export function MentionInactiveSpan(props) {
  return <span className="mention-inactive">{props.children}</span>;
}

MentionInactiveSpan.propTypes = propTypes;
MentionInactiveSpan.defaultProps = defaultProps;
