import { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import BlockCaption from '../../components/blocks/BlockCaption';
import updateDataOfBlock from '../../utils/updateDataOfBlock';
import deleteDataBlock from '../../utils/deleteDataBlock';
import Oembed from '../../../oembed/Oembed';
import getOembedHtml from '../../../oembed/utils/getOembedHtml';
import selectBlock from '../../utils/selectBlock';
import StoryChief from '../../../storychief';

const propTypes = {
  block: PropTypes.shape({
    getData: PropTypes.func,
  }).isRequired,
  blockProps: PropTypes.shape({
    setReadOnly: PropTypes.func,
    isSelected: PropTypes.bool,
    getEditorState: PropTypes.func,
    setEditorState: PropTypes.func,
    editorEnabled: PropTypes.bool,
  }).isRequired,
};
const defaultProps = {};

export default class VideoBlock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputHasValue: false,
      processing: false,
      error: false,
    };
    this.onRemove = this.onRemove.bind(this);
    this.onWrapperClick = this.onWrapperClick.bind(this);
    this.updateData = this.updateData.bind(this);
    this.onInputKeyPress = this.onInputKeyPress.bind(this);
    this.onInputFocus = this.onInputFocus.bind(this);
    this.onInputBlur = this.onInputBlur.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
  }

  UNSAFE_componentWillMount() {
    const { block } = this.props;
    const data = block.getData();
    const src = data.get('src');
    let oembed = data.get('data');
    // legacy video block: only src was set in older editor, fetch oembed data
    if (src !== '' && typeof oembed === 'undefined') {
      oembed = new Oembed();
      oembed.getData(src).then(
        () => {
          this.updateData({ ...oembed });
        },
        () => {},
      );
    }
  }

  componentDidMount() {
    if (this.searchFieldNode && this.props.blockProps.isSelected) {
      this.searchFieldNode.focus();
    }
  }

  onInputFocus() {
    this.props.blockProps.setReadOnly(true);
  }

  onInputBlur() {
    this.props.blockProps.setReadOnly(false);
  }

  onInputChange(e) {
    const { inputHasValue } = this.state;
    const value = e.target.value;
    if (value !== '') {
      if (!inputHasValue) {
        this.setState({ inputHasValue: true });
      }
    } else if (inputHasValue) {
      this.setState({ inputHasValue: false });
    }
  }

  onInputKeyPress(e) {
    if (e.key === 'Enter') {
      e.preventDefault();
      const url = e.target.value;
      const oembed = new Oembed();
      this.setState({
        error: false,
        processing: true,
      });
      oembed.getData(url).then(
        () => {
          this.props.blockProps.setReadOnly(false);
          this.updateData({ ...oembed });
        },
        () => {
          this.props.blockProps.setReadOnly(false);
          this.setState({
            error: true,
            processing: false,
          });
        },
      );
    }
  }

  onWrapperClick() {
    const { getEditorState, setEditorState } = this.props.blockProps;
    setEditorState(selectBlock(this.props.block, getEditorState()));
  }

  onRemove() {
    this.props.blockProps.setReadOnly(true);
    const { block, blockProps } = this.props;
    const newEditorState = deleteDataBlock(blockProps.getEditorState(), block);
    blockProps.setEditorState(newEditorState);
    this.props.blockProps.setReadOnly(false);
  }

  updateData(data) {
    const { block, blockProps } = this.props;
    const { getEditorState, setEditorState } = blockProps;
    setEditorState(updateDataOfBlock(getEditorState(), block, data));
  }

  render() {
    const { block, blockProps } = this.props;
    const isSelected = blockProps.isSelected;
    const data = block.getData();
    const size = data.get('size');
    const src = data.get('src');
    let oembed = data.get('data');
    let embedHtml = null;
    // fix: data firstly returns a Map after that it returns an object
    if (typeof oembed !== 'undefined') {
      if (Immutable.Map.isMap(oembed)) {
        oembed = oembed.toObject();
      }
      embedHtml = getOembedHtml(oembed, true);
    }

    let isEmpty = true;
    if (src !== '') {
      isEmpty = false;
    }
    const classNameWrapper = `block-container block-video-container${isEmpty ? ' is-empty' : ''} ${
      isSelected ? 'selected' : ''
    } ${size}`;
    return (
      <div className={classNameWrapper}>
        {src === '' ? (
          <div contentEditable={false}>
            <button
              type="button"
              onClick={this.onRemove}
              className="btn-chromeless btn-delete icon-cancel"
            />
            <h2>
              Add a <span className="highlight-marker">video</span>
            </h2>
            <div className="text-muted no-line-height space-top-1 space-6">
              <small>
                Add videos from Youtube, Vimeo, Wistia, Loom,...
                <br />
                <a
                  href={`${StoryChief.dashboardBasePath}/integrations#editor-embeds`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View supported providers
                </a>
              </small>
            </div>
            <div className={this.state.error ? 'form-group has-error' : 'form-group'}>
              <input
                ref={(c) => {
                  this.searchFieldNode = c;
                }}
                className="form-control"
                type="text"
                onFocus={this.onInputFocus}
                onBlur={this.onInputBlur}
                onKeyPress={this.onInputKeyPress}
                onChange={this.onInputChange}
                placeholder="Paste a link and press Enter"
                disabled={this.state.processing ? 'disabled' : false}
              />
              {!!this.state.error && (
                <div className="text-center">
                  <span className="small text-danger">
                    Video not found for this link.&nbsp;
                    <a
                      href={`${StoryChief.dashboardBasePath}/integrations#editor-embeds`}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      View supported providers
                    </a>
                  </span>
                </div>
              )}
            </div>
            <BlockCaption {...this.props} />
          </div>
        ) : (
          <div>
            <div
              className="media-wrapper embed-wrapper"
              ref={(node) => {
                this.embedWrapper = node;
              }}
              contentEditable={false}
              onClick={blockProps.editorEnabled && !isSelected ? this.onWrapperClick : null}
              onKeyPress={blockProps.editorEnabled && !isSelected ? this.onWrapperClick : null}
            >
              <button
                type="button"
                onClick={this.onRemove}
                className="btn-chromeless btn-delete icon-cancel"
              />
              <div className={blockProps.editorEnabled ? 'embed-container' : ''}>
                <div dangerouslySetInnerHTML={{ __html: embedHtml }} />
              </div>
            </div>
            <BlockCaption {...this.props} />
          </div>
        )}
      </div>
    );
  }
}

VideoBlock.propTypes = propTypes;
VideoBlock.defaultProps = defaultProps;
