import getIframeSrc, { getIframeWidth, getIframeHeight } from '@/storychief/utils/getIframeSrc';

function getBaseUrl(urlString) {
  const url = new URL(urlString);
  return `${url.protocol}//${url.host}`;
}

export default function (_iframeHtml) {
  const iframeWidth = getIframeWidth(_iframeHtml);
  const iframeHeight = getIframeHeight(_iframeHtml);
  const iframeSrc = getIframeSrc(_iframeHtml);
  const providerUrl = getBaseUrl(iframeSrc);
  const iframeHtml = `<iframe src="${iframeSrc}" height="${iframeHeight}" width="100%" frameborder="0" sandbox="allow-scripts allow-popups allow-top-navigation-by-user-activation allow-forms allow-same-origin allow-storage-access-by-user-activation" style="max-width: ${iframeWidth}px" allowfullscreen=""></iframe>`;

  const data = {
    type: 'rich',
    version: '1.0',
    iframe_src: iframeSrc,
    width: iframeWidth,
    height: iframeHeight,
    provider_name: 'rawIframe',
    provider_url: providerUrl,
    html: iframeHtml,
  };

  return new Promise((resolve) => {
    data.html_responsive = data.html;
    resolve(data);
  });
}
