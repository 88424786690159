export default function (url) {
  const iframeSrc = url.replace('embed', 'embed_iframe');

  const data = {
    type: 'rich',
    version: '1.0',
    iframe_src: iframeSrc,
    provider_name: 'Pastebin',
    povider_url: 'https://pastebin.com/',
    width: 200,
    height: 150,
    html: `<iframe src="${iframeSrc}" style="border:none;width:100%;"></iframe>`,
  };

  return new Promise((resolve) => {
    data.html_responsive = data.html;
    resolve(data);
  });
}
