import { convertFromRaw, convertToRaw } from 'draft-js';
import { markdownToDraft } from 'markdown-draft-js';

export default function markDownToDraftWithDynamicContent(text, contentState) {
  const rawState = markdownToDraft(text, {
    remarkableOptions: {
      html: true,
    },
    blockTypes: {
      htmlblock: (item) => {
        // if we have an htmlblock the original block key is located inside ()
        const filteredContent = item.content.match(/\(([^)]+)\)/);
        if (filteredContent && filteredContent.length > 1) {
          return {
            text: filteredContent[1],
            type: 'atomic',
          };
        }
        return {
          text: '',
          type: 'inline',
        };
      },
    },
  });

  const ogRaw = convertToRaw(contentState);
  rawState.blocks = rawState.blocks.map((_block) => {
    if (_block.type === 'atomic') {
      const ogBlock = ogRaw.blocks.find((_ogBlock) => _ogBlock.key === _block.text);
      return ogBlock;
    }
    return _block;
  });

  return convertFromRaw(rawState);
}
