import type { JSX } from 'react';
import { convertToRaw } from 'draft-js';
import Label from '@/storychief/components/Label';
import { useEmitEvent } from '@/storychief/hooks';
import Button from '@/storychief/components/Button';
// eslint-disable-next-line import/no-cycle
import RichEditor from '@/storychief/components/editors/RichEditor';
import { EDITOR_COLLABORATION_EVENT } from '@/editor/constants/Constants';

function MergeConflictBlockComponent(props): JSX.Element {
  // Hooks
  const solveBlockConflictEvent = useEmitEvent(EDITOR_COLLABORATION_EVENT.emitSolvedBlockConflict);

  // Functions
  function handleSaveEditorVersion(): void {
    solveBlockConflictEvent(getBlockRaw(props.contentState, props.block.getKey()));
  }

  function handleSaveBrowserVersion(): void {
    solveBlockConflictEvent(props.blockProps.conflictedBlock);
  }

  function getBlockRaw(contentState, blockKey) {
    const contentStateWithSingleBlock = contentState.merge({
      blockMap: contentState.getBlockMap().filter((_, key) => key === blockKey),
    });

    return convertToRaw(contentStateWithSingleBlock);
  }

  // Render
  return (
    <div className="flex flex-col border border-solid border-gallery bg-alabaster">
      <Label variant="warning" className="font-bold">
        Please, solve this block conflict selecting a version
      </Label>

      <div className="grid grid-cols-2">
        <div className="flex flex-col justify-between border border-solid border-gallery p-4">
          <div className="my-2 max-h-[500px] overflow-y-scroll">
            <RichEditor
              isPreview
              disabled
              content={getBlockRaw(props.contentState, props.block.getKey())}
            />
          </div>

          <Button size="sm" variant="primary" onClick={handleSaveEditorVersion}>
            Select saved version
          </Button>
        </div>

        <div className="group flex flex-col justify-between border border-solid border-gallery p-4 transition-all">
          <div className="my-2 max-h-[500px] overflow-y-scroll">
            <RichEditor isPreview disabled content={props.blockProps.conflictedBlock} />
          </div>
          <Button size="sm" variant="secondary" onClick={handleSaveBrowserVersion}>
            Select local version
          </Button>
        </div>
      </div>
    </div>
  );
}

export default MergeConflictBlockComponent;
