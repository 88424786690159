import { useState, useEffect } from 'react';
import { animateScroll } from 'react-scroll';
import PropTypes from 'prop-types';
import useAssistant from '@/assistant/hooks/useAssistant';
// The dependency cycle is caused by <TextEditor> importing assistant components and those components also importing <TextEditor>.
// The issue is mitigated by disabling the assistant on <TextEditor> inside the assistant components.
// eslint-disable-next-line import/no-cycle
import CommandPopover from '@/assistant/components/Command/CommandPopover';
import getEditorInlineSelection from '@/editor/utils/getEditorInlineSelection';
import { CUSTOM_BLOCK_STYLES } from '@/editor/constants/Constants';

const propTypes = {
  currentBlock: PropTypes.shape({
    getData: PropTypes.func.isRequired,
    getKey: PropTypes.func.isRequired,
  }).isRequired,
  getEditorNode: PropTypes.func.isRequired,
  size: PropTypes.oneOf(['sm', 'lg']),
};

const defaultProps = {
  size: undefined,
};

function BlockToolbarAssistant({ getEditorNode, currentBlock, size }) {
  // State
  const [top, setTop] = useState(0);
  const [width, setWidth] = useState(700);

  // variables
  const blockKey = currentBlock.getKey();

  // Hooks
  const { command, getEditorState } = useAssistant();

  useEffect(() => {
    if (blockKey && command) {
      const parent = getEditorNode().editor;
      const editorState = getEditorState();
      const selections = getEditorInlineSelection(editorState.getCurrentContent());
      let lastSelectionElementBoundary;
      if (selections.length) {
        const lastSelection = selections.pop();
        const lastSelectionRange = lastSelection.ranges.pop();
        const lastBlockKey = lastSelectionRange.blockKey;
        const lastSelectionElements = parent.querySelectorAll(
          `span[data-offset-key*="${lastBlockKey}"][style*="background-color: ${CUSTOM_BLOCK_STYLES.SELECTION.backgroundColor}"]`,
        );
        const lastSelectionElement = lastSelectionElements[lastSelectionElements.length - 1];
        if (lastSelectionElementBoundary) {
          lastSelectionElementBoundary = lastSelectionElement.getBoundingClientRect();
        }
      }
      const blockElement = parent.querySelector(`div[data-offset-key="${blockKey}-0-0"]`);
      if (blockElement) {
        const editorElement = blockElement.closest('.DraftEditor-root');
        const editor = blockElement.closest('#sc-editor');
        const formControlElement = blockElement.closest('.form-control.text-editor');
        const editorRelativeParentElement = editorElement.offsetParent;
        const blockElementBoundary = blockElement.getBoundingClientRect();
        const editorRelativeParentElementBoundary = editorRelativeParentElement
          ? editorRelativeParentElement.getBoundingClientRect()
          : { top: 0, left: 0 };

        /*
         * Main logic for setting the toolbar position.
         */
        const _top =
          (lastSelectionElementBoundary
            ? lastSelectionElementBoundary.bottom
            : blockElementBoundary.bottom) - editorRelativeParentElementBoundary.top;

        setTop(_top);

        if (formControlElement) {
          const formControlElementBoundary = formControlElement.getBoundingClientRect();
          setWidth(formControlElementBoundary.width);
        } else {
          setWidth(blockElementBoundary.width);
        }
        if (editor) {
          animateScroll.scrollTo(_top);
        }
      }
    }
  }, [blockKey, command]);

  if (blockKey && command) {
    return <CommandPopover positionTop={top} width={width} size={size} />;
  }

  return null;
}

BlockToolbarAssistant.propTypes = propTypes;
BlockToolbarAssistant.defaultProps = defaultProps;

export default BlockToolbarAssistant;
