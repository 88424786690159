import request from 'superagent';
import convertIframeToResponsive from '../utils/convertIframeToResponsive';
import StoryChief from '@/storychief';
import getIframeSrc from '@/storychief/utils/getIframeSrc';

export default function cloudinary(url) {
  return new Promise((resolve, reject) => {
    request
      .put(`${StoryChief.dashboardBasePath}/integrations/embed/oembed`)
      .set('Accept', 'application/json')
      .set('X-Requested-With', 'XMLHttpRequest')
      .set('X-CSRF-TOKEN', StoryChief.csrfToken)
      .send({
        url,
        provider: 'cloudinary',
      })
      .end((err, res) => {
        if (err) {
          reject(err);
        } else {
          const data = res.body;

          if (typeof data.error !== 'undefined') {
            reject(data);
          }
          data.iframe_src = getIframeSrc(data.html);
          data.html_responsive = convertIframeToResponsive(data.html);
          resolve(data);
        }
      });
  });
}
