import { convertToRaw } from 'draft-js';
import { draftToMarkdown } from 'markdown-draft-js';

export default function draftToMarkDownWithDynamicContent(contentState) {
  return draftToMarkdown(convertToRaw(contentState), {
    styleItems: {
      atomic: {
        open: (block) => `<!--- (${block.key}) `,
        close: () => ' -->\n\n',
      },
    },
  });
}
