import { EditorState, Modifier } from 'draft-js';
import { ENTITY_TYPES } from '../constants/Constants';

function addLinkEntityToEditorState(linkData, editorState) {
  const contentState = editorState.getCurrentContent();
  const selection = editorState.getSelection();
  const contentStateWithEntity = contentState.createEntity(ENTITY_TYPES.LINK, 'MUTABLE', linkData);
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  const contentStateWithLink = Modifier.applyEntity(contentStateWithEntity, selection, entityKey);
  return EditorState.set(editorState, { currentContent: contentStateWithLink });
}

export default addLinkEntityToEditorState;
