import { EditorState, convertFromRaw } from 'draft-js';
import getDefaultDecorator from '../decorators/getDefaultDecorator';

export default function subtractTitleFromEditorState(editorState, fuzzySearch = false) {
  const contentState = editorState.getCurrentContent();
  const blockMap = contentState.getBlockMap();

  // Get the first block that starts with 'header-'
  let titleBlock;
  if (fuzzySearch) {
    titleBlock = blockMap.find((block) => block.getType().startsWith('header-'));
  } else {
    const firstBlock = blockMap.first();
    if (firstBlock.getType().startsWith('header-')) {
      titleBlock = firstBlock;
    }
  }

  // If there's no title, return the original editorState
  if (!titleBlock) {
    return { titleEditorState: null, contentEditorState: editorState };
  }

  const titleEditorState = EditorState.createWithContent(
    convertFromRaw({
      entityMap: {},
      blocks: [
        {
          key: 'title',
          text: titleBlock.getText(),
          type: 'header-one',
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        },
      ],
    }),
    getDefaultDecorator(),
  );

  // Now remove the titleBlock and all blocks before it from the original content
  let shouldKeepBlock = false;
  const contentBlockMap = blockMap.filter((block) => {
    if (block.getKey() === titleBlock.getKey()) {
      shouldKeepBlock = true;
      return false;
    }
    return shouldKeepBlock;
  });

  const contentContentState = contentState.merge({
    blockMap: contentBlockMap,
    selectionBefore: contentState.getSelectionBefore(),
    selectionAfter: contentState.getSelectionAfter(),
  });

  const contentEditorState = EditorState.push(editorState, contentContentState, 'remove-range');

  return { titleEditorState, contentEditorState };
}
