import { EditorState, Modifier } from 'draft-js';

export default function addEditorInlineSelection(editorState, setEditorState) {
  let newEditorState = EditorState.set(editorState, { allowUndo: false });
  setEditorState(newEditorState);
  const newContentState = Modifier.applyInlineStyle(
    newEditorState.getCurrentContent(),
    newEditorState.getSelection(),
    'SELECTION',
  );
  newEditorState = EditorState.set(newEditorState, { currentContent: newContentState });
  setEditorState(newEditorState);
  newEditorState = EditorState.set(newEditorState, { allowUndo: true });
  setEditorState(newEditorState);
  return newEditorState;
}
