import request from 'superagent';
import StoryChief from '../../storychief';
import convertIframeToResponsive from '@/oembed/utils/convertIframeToResponsive';

export default function (url) {
  return new Promise((resolve, reject) => {
    request
      .put(`${StoryChief.dashboardBasePath}/integrations/embed/oembed`)
      .set('Accept', 'application/json')
      .set('X-Requested-With', 'XMLHttpRequest')
      .set('X-CSRF-TOKEN', StoryChief.csrfToken)
      .send({
        url,
        provider: 'canva',
      })
      .end((err, res) => {
        if (err) {
          reject(err);
        } else {
          const data = res.body;
          data.html_responsive = convertIframeToResponsive(data.html);
          data.iframe_src = `${url}?embed&meta`;

          resolve(data);
        }
      });
  });
}
