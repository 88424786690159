import PropTypes from 'prop-types';

const propTypes = {
  direction: PropTypes.string.isRequired,
};

function DividerBlock(props) {
  const { direction } = props;

  return <hr direction={direction} />;
}

DividerBlock.propTypes = propTypes;

export default DividerBlock;
