import { Component } from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Icon } from '@iconify-icon/react';
import resetBlockWithType from '../../utils/resetBlockWithType';

const propTypes = {
  setEditorState: PropTypes.func.isRequired,
  getEditorState: PropTypes.func.isRequired,
};
const defaultProps = {};

class VideoButton extends Component {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    const data = { src: '', type: 'video', size: 'regular' };
    this.props.setEditorState(resetBlockWithType(this.props.getEditorState(), 'atomic', data));
  }

  render() {
    const tooltip = <Tooltip id="embed-tooltip">Add video</Tooltip>;
    return (
      <div className="sb-button-wrapper">
        <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={tooltip}>
          <button className="sb-button sb-video-button" type="button" onClick={this.onClick}>
            <Icon icon="bi:film" height="13" inline className="mx-[3px]" />
          </button>
        </OverlayTrigger>
      </div>
    );
  }
}

VideoButton.propTypes = propTypes;
VideoButton.defaultProps = defaultProps;

export default VideoButton;
