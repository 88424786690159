export default function getColumnDividerCount(contentState) {
  let columnCount = 0;
  const blocks = contentState ? contentState.getBlockMap() : [];
  if (blocks) {
    blocks.forEach((block) => {
      if (block.type === 'atomic') {
        const metadata = [...block.data];
        metadata.forEach((data) => {
          if (data[1] === 'columnDivider') columnCount += 1;
        });
      }
    });
  }
  return columnCount;
}
