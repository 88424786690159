import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Icon } from '@iconify-icon/react';
import resetBlockWithType from '../../utils/resetBlockWithType';

const propTypes = {
  setEditorState: PropTypes.func.isRequired,
  getEditorState: PropTypes.func.isRequired,
  focus: PropTypes.func.isRequired,
};

function ButtonButton(props) {
  const { setEditorState, getEditorState, focus } = props;

  function handleOnClick(e) {
    e.preventDefault();
    const data = { type: 'button' };
    setEditorState(resetBlockWithType(getEditorState(), 'atomic', data));
    setTimeout(() => focus(), 0);
  }

  const tooltip = <Tooltip id="button-tooltip">Add Button</Tooltip>;

  return (
    <div className="sb-button-wrapper">
      <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={tooltip}>
        <button className="sb-button" type="button" onClick={handleOnClick}>
          <Icon icon="radix-icons:button" inline height="13" />
        </button>
      </OverlayTrigger>
    </div>
  );
}

ButtonButton.propTypes = propTypes;

export default ButtonButton;
