import { Component } from 'react';
import PropTypes from 'prop-types';
import { EditorState } from 'draft-js';
import Select from '@/storychief/components/Select';
import getEntities from '../../utils/getEntities';
import getCurrentBlock from '../../utils/getCurrentBlock';

const propTypes = {
  onKeyDown: PropTypes.func.isRequired,
  onChangeUrl: PropTypes.func.isRequired,
  urlInputValue: PropTypes.string,
  urlTargetBlank: PropTypes.bool,
  handleTargetCheckboxChange: PropTypes.func.isRequired,
  urlRelNofollow: PropTypes.bool,
  handleRelCheckboxChange: PropTypes.func.isRequired,
  handleSaveLinkInput: PropTypes.func.isRequired,
  getEditorState: PropTypes.func.isRequired,
  setEditorState: PropTypes.func.isRequired,
  handleRemoveLink: PropTypes.func.isRequired,
  allowAnchorLinks: PropTypes.bool,
  initializeLinkAttributes: PropTypes.func.isRequired,
};
const defaultProps = {
  urlInputValue: '',
  urlTargetBlank: false,
  urlRelNofollow: false,
  allowAnchorLinks: false,
};

const linkTypes = [
  { value: 'anchor', label: 'Section' },
  { value: 'url', label: 'External' },
  { value: 'mail', label: 'E-mail' },
  { value: 'phone', label: 'Phone' },
];

class ToolbarHyperlink extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedLinkTypeValue: this.getLinkType(props.urlInputValue),
      selectedHeader: this.props.urlInputValue.startsWith('#')
        ? this.getSelectedAnchor(this.props.urlInputValue)
        : '',
    };
  }

  UNSAFE_componentWillMount() {
    // if selection is not entity empty inputs
    const { getEditorState } = this.props;
    const currentBlock = getCurrentBlock(getEditorState());
    const blockKey = currentBlock.getKey();
    const linkEntities = getEntities(getEditorState().getCurrentContent(), 'LINK');
    const selectedEntity = linkEntities.filter((entity) => entity.blockKey === blockKey);
    if (!selectedEntity.length) {
      this.props.handleRemoveLink();
      this.setState({ selectedHeader: '', selectedLinkTypeValue: 'url' });
    }
  }

  componentDidMount() {
    setTimeout(() => {
      if (this.nameInput && this.nameInput.value) {
        this.nameInput.select();
      } else if (this.nameInput && !this.nameInput.value) {
        this.nameInput.focus();
      }
    }, 0);

    this.props.initializeLinkAttributes();
  }

  getLinkType = (url) => {
    if (url.startsWith('#')) {
      return 'anchor';
    }

    if (url.startsWith('mailto:')) {
      return 'mail';
    }

    if (url.startsWith('tel:')) {
      return 'phone';
    }

    return 'url';
  };

  getCleanUrl = (url) =>
    url
      .replace(/^#/g, '')
      .replace(/mailto:/g, '')
      .replace(/tel:/g, '')
      .replace(/http:\/\//g, '')
      .replace(/https:\/\//g, '');

  getLinkPrefix = (type) => {
    if (type === 'mail') {
      return 'mailto:';
    }

    if (type === 'phone') {
      return 'tel:';
    }

    return 'http://';
  };

  getOptionsHeaders = () => {
    const content = this.props.getEditorState().getCurrentContent();
    const headers = [];
    content.getBlocksAsArray().forEach((block) => {
      if (block.getType().includes('header') && block.getText() !== '') {
        headers.push({
          value: block.getKey(),
          label: block.getText(),
          type: block.getType(),
        });
      }
    });
    return headers;
  };

  getSelectedAnchor(urlInputValue) {
    const anchorKey = urlInputValue.substring(1);
    const selectedAnchor = this.getOptionsHeaders().find((anchor) => anchor.value === anchorKey);
    return selectedAnchor;
  }

  handleAnchorChange = (objOption) => {
    const editorState = this.props.getEditorState();
    if (objOption) {
      this.setState({ selectedHeader: objOption });
    } else {
      this.setState({ selectedHeader: '' });
    }
    // Fix to prevent editor from loosing selection
    const newEditorState = EditorState.forceSelection(
      this.props.getEditorState(),
      editorState.getSelection(),
    );
    this.props.setEditorState(newEditorState);
  };

  handleOptionChange = (objOption) => {
    const prefix = this.getLinkPrefix(objOption.value);
    const url = this.getCleanUrl(this.props.urlInputValue);
    this.setState({ selectedLinkTypeValue: objOption.value });
    this.props.onChangeUrl({
      target: {
        value: `${prefix}${url}`,
      },
    });
    this.focusAnchorSelect();
    this.focusNameInput();
  };

  handleSaveLinkOrAnchor = (e) => {
    e.preventDefault();
    const { selectedLinkTypeValue, selectedHeader } = this.state;
    if (selectedLinkTypeValue === 'anchor') {
      const anchorKey = selectedHeader ? selectedHeader.value : '';
      this.props.handleSaveLinkInput(`#${anchorKey}`, null, null);
      return;
    }
    this.props.handleSaveLinkInput(
      this.props.urlInputValue,
      this.props.urlTargetBlank,
      this.props.urlRelNofollow,
    );
  };

  handleChangeUrl = (e) => {
    const type = this.getLinkType(e.target.value);
    if (e.target.value === '#') {
      this.focusAnchorSelect();
    }
    this.setState({ selectedLinkTypeValue: type });
    this.props.onChangeUrl(e);
  };

  focusNameInput = () => {
    setTimeout(() => {
      if (this.nameInput) {
        this.nameInput.focus();
      }
    }, 0);
  };

  focusAnchorSelect = () => {
    setTimeout(() => {
      if (this.anchorSelect) {
        this.anchorSelect.focus();
      }
    }, 0);
  };

  render() {
    const { selectedLinkTypeValue, selectedHeader } = this.state;

    const {
      onKeyDown,
      handleTargetCheckboxChange,
      handleRelCheckboxChange,
      urlInputValue,
      allowAnchorLinks,
      urlRelNofollow,
      urlTargetBlank,
    } = this.props;

    const optionsHeaders = this.getOptionsHeaders();

    return (
      <div className="RichEditor-controls RichEditor-show-input d-block">
        <form className="form-inline">
          <div className="form-group form-group-sm">
            {!!allowAnchorLinks && (
              <span>
                <div className="input-group">
                  <Select
                    isClearable={false}
                    value={linkTypes.find((type) => type.value === selectedLinkTypeValue)}
                    options={linkTypes.filter((type) => type.value !== selectedLinkTypeValue)}
                    onChange={this.handleOptionChange}
                    className="react-select--options react-select--inverted"
                    components={{ IndicatorSeparator: () => null }}
                    isSearchable={false}
                    openMenuOnFocus
                  />
                </div>
                <div className="RichEditor-controls-separator" />
              </span>
            )}
            <div className="input-group">
              {selectedLinkTypeValue === 'anchor' ? (
                <div>
                  <Select
                    ref={(select) => {
                      this.anchorSelect = select;
                    }}
                    options={optionsHeaders}
                    onChange={this.handleAnchorChange}
                    isClearable={false}
                    placeholder="Select an article heading..."
                    noOptionsMessage={() =>
                      optionsHeaders.length === 0
                        ? 'There are no headings in this article'
                        : 'Heading not found'
                    }
                    components={{ IndicatorSeparator: () => null }}
                    className="react-select--anchors react-select--inverted"
                    value={selectedHeader}
                    openMenuOnFocus
                  />
                </div>
              ) : (
                <input
                  ref={(input) => {
                    this.nameInput = input;
                  }}
                  type="text"
                  className="text form-control form-control--inverted form-control--chromeless"
                  onKeyDown={(e) => onKeyDown(e, urlInputValue, urlTargetBlank, urlRelNofollow)}
                  onChange={this.handleChangeUrl}
                  placeholder="e.g. https://google.com"
                  value={urlInputValue.startsWith('#') ? '' : urlInputValue}
                />
              )}
            </div>
          </div>
          <hr />
          <div className="form-group form-group-sm gutter-2">
            <label className="control-label" htmlFor="targetCheckbox">
              <input
                type="checkbox"
                id="targetCheckbox"
                name="target"
                checked={!!urlTargetBlank}
                onChange={handleTargetCheckboxChange}
                disabled={selectedLinkTypeValue === 'anchor'}
              />{' '}
              New window
            </label>
          </div>
          <div className="form-group form-group-sm gutter-1">
            <label className="control-label" htmlFor="relCheckbox">
              <input
                type="checkbox"
                id="relCheckbox"
                name="rel"
                checked={!!urlRelNofollow}
                onChange={handleRelCheckboxChange}
                disabled={selectedLinkTypeValue === 'anchor'}
              />{' '}
              No follow
            </label>
          </div>
          <button
            type="button"
            className="url-input-save btn btn-small btn--rectangle btn-primary pull-right"
            onMouseDown={this.handleSaveLinkOrAnchor}
          >
            Save
          </button>
        </form>
      </div>
    );
  }
}

ToolbarHyperlink.propTypes = propTypes;
ToolbarHyperlink.defaultProps = defaultProps;

export default ToolbarHyperlink;
