import { convertFromRaw, EditorState } from 'draft-js';
import draftToMarkDownWithDynamicContent from '@/assistant/utils/draftToMarkDownWithDynamicContent';
import getDefaultDecorator from '@/editor/decorators/getDefaultDecorator';
import tryParseJson from '@/storychief/utils/tryParseJson';

/**
 * Convert DraftJS JSON to markdown.
 *
 * @param {string|null} contentRaw JSON
 * @returns {string}
 */
export default function getEditorTextAsMarkdownFromJson(contentRaw) {
  const tryContentRaw = tryParseJson(contentRaw);

  if (tryContentRaw.valid) {
    return draftToMarkDownWithDynamicContent(
      EditorState.createWithContent(
        convertFromRaw(tryContentRaw.parsed),
        getDefaultDecorator(),
      ).getCurrentContent(),
    );
  }

  return '';
}
