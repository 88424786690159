import request from 'superagent';
import StoryChief from '../../storychief';
import convertIframeToResponsive from '../utils/convertIframeToResponsive';

export default function (url) {
  return new Promise((resolve, reject) => {
    const encodedUrl = encodeURIComponent(url);

    request
      .put(`${StoryChief.dashboardBasePath}/integrations/embed/oembed`)
      .set('Accept', 'application/json')
      .set('X-Requested-With', 'XMLHttpRequest')
      .set('X-CSRF-TOKEN', StoryChief.csrfToken)
      .send({
        url,
        provider: 'facebook-video',
      })
      .end((err, res) => {
        if (err) {
          reject(err);
        } else {
          const data = res.body;
          const iframeSrc = `https://www.facebook.com/plugins/video.php?href=${encodedUrl}&width=500`;
          const html = `<iframe src="${iframeSrc}" width="${data.width}" height="${data.height}" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media"></iframe>`;
          data.url = url;
          data.html = html;
          data.html_responsive = convertIframeToResponsive(html);
          resolve(data);
        }
      });
  });
}
