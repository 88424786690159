/*
Example
url = https://pandora8627.activehosted.com/f/1
embed code = <div class="_form_1"></div><script src="https://pandora8627.activehosted.com/f/embed.php?id=1" type="text/javascript" charset="utf-8"></script>
 */
export default function (url) {
  return new Promise((resolve, reject) => {
    const urlParams = url.split('/');
    try {
      const form_id = urlParams[4];
      const portal_url = `https://${urlParams[2]}/${urlParams[3]}`;
      const embed_script_url = `${portal_url}/embed.php?id=${form_id}`;
      const data = {
        type: 'rich',
        version: '1.0',
        iframe_src: url,
        provider_name: 'ActiveCampaignForm',
        provider_url: 'https://www.activecampaign.com/',
        width: 300,
        height: 100,
        html: `<div data-embed-script-url="${embed_script_url}" data-form-id="${form_id}" class="activecampaign-form _form_${form_id}"></div><script src="${embed_script_url}" type="text/javascript" charset="utf-8"></script>`,
      };
      data.html_responsive = data.html;
      resolve(data);
    } catch {
      reject(new Error('form not found'));
    }
  });
}
