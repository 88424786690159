import { EditorState, Modifier, SelectionState } from 'draft-js';
import getEditorInlineSelection from './getEditorInlineSelection';

export default function deleteEditorInlineSelection(editorState, setEditorState) {
  let newEditorState = EditorState.set(editorState, { allowUndo: false });
  setEditorState(newEditorState);

  let newContentState = newEditorState.getCurrentContent();
  const selections = getEditorInlineSelection(newContentState);

  selections.forEach((editorComment) => {
    const { ranges } = editorComment;
    // iterate through all ranges and remove selection style
    ranges.forEach((range) => {
      const commentSelection = new SelectionState({
        anchorOffset: range.start,
        anchorKey: range.blockKey,
        focusOffset: range.end,
        focusKey: range.blockKey,
        isBackward: false,
      });
      newContentState = Modifier.removeInlineStyle(
        newContentState,
        commentSelection,
        editorComment.key,
      );
    });
  });

  newEditorState = EditorState.set(newEditorState, {
    currentContent: newContentState,
  });
  setEditorState(newEditorState);
  newEditorState = EditorState.set(newEditorState, { allowUndo: true });
  setEditorState(newEditorState);
  return newEditorState;
}
