import request from 'superagent';
import jsonp from 'superagent-jsonp';
import convertIframeToResponsive from '../utils/convertIframeToResponsive';
import getIframeSrc from '../../storychief/utils/getIframeSrc';

export default function (url) {
  return new Promise((resolve, reject) => {
    request
      .get(`https://www.mixcloud.com/oembed/?url=${url}`)
      .query({ width: 740 })
      .set('Accept', 'application/json')
      .use(
        jsonp({
          timeout: 3000,
          callbackName: 'callback',
        }),
      )
      .end((err, res) => {
        if (err) {
          reject(err);
        } else {
          const data = res.body;
          data.html_responsive = convertIframeToResponsive(data.html);
          data.iframe_src = getIframeSrc(data.html);
          data.thumbnail_url = data.image;
          resolve(data);
        }
      });
  });
}
