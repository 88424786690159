import updateIntegration from '../../integrations/actions/updateIntegration';
import updateStatus from '../../storychief/actions/updateStatus';

export default function (response, error) {
  return (dispatch) => {
    if (error?.status === 400) {
      return dispatch(updateIntegration(response?.body));
    }

    return dispatch(
      updateStatus({
        status: 'error',
        status_response: response,
      }),
    );
  };
}
