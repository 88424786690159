import {
  GLOBAL_CONTENT_TYPES,
  GLOBAL_CONTENT_TYPES_MODELS,
} from '@/storychief/constants/contentTypes';

const GLOBAL_CONTENT_TYPE_PUSHER_CHANNELS_PREFIX = {
  [GLOBAL_CONTENT_TYPES_MODELS[GLOBAL_CONTENT_TYPES.stories]]: 'story',
  [GLOBAL_CONTENT_TYPES_MODELS[GLOBAL_CONTENT_TYPES.postsets]]: 'postset',
  [GLOBAL_CONTENT_TYPES_MODELS[GLOBAL_CONTENT_TYPES.websitecontents]]: 'websitecontents',
  [GLOBAL_CONTENT_TYPES_MODELS[GLOBAL_CONTENT_TYPES.newsletters]]: 'newsletters',
  [GLOBAL_CONTENT_TYPES_MODELS[GLOBAL_CONTENT_TYPES.videoprojects]]: 'videoprojects',
  [GLOBAL_CONTENT_TYPES_MODELS[GLOBAL_CONTENT_TYPES.ebooks]]: 'ebooks',
  [GLOBAL_CONTENT_TYPES_MODELS[GLOBAL_CONTENT_TYPES.webinars]]: 'webinars',
  [GLOBAL_CONTENT_TYPES_MODELS[GLOBAL_CONTENT_TYPES.podcasts]]: 'podcasts',
};

export default GLOBAL_CONTENT_TYPE_PUSHER_CHANNELS_PREFIX;
