import { COMMANDS } from '@/assistant/constants/Constants';

/*
 * Get recommended draft commands based on type (story, social post, newsletter).
 *
 * If the type is unknown, a list of default commands will be returned.
 */
export default function getRecommendedDraftCommandsByType(subject) {
  switch (subject.type) {
    case 'Story':
    case 'Ebook':
    case 'Podcast':
    case 'Newsletter':
    case 'Webinar':
    case 'WebsiteContent':
    case 'VideoProject':
      return [
        COMMANDS.BLOG_POST,
        COMMANDS.NEWSLETTER,
        COMMANDS.PODCAST,
        COMMANDS.WEBSITE_CONTENT,
        COMMANDS.WEBINAR,
        COMMANDS.VIDEO_PROJECT,
        COMMANDS.EBOOK,
        COMMANDS.EDITORIAL_BRIEF,
        COMMANDS.TOPIC_QUESTIONS,
        COMMANDS.TOPIC_PROSCONS,
        COMMANDS.TOPIC_BLOG_TITLES,
      ];
    case 'Brief':
      return [COMMANDS.EDITORIAL_BRIEF];
    case 'Postset':
    case 'Post':
      return [COMMANDS.SOCIAL_POST];
    case 'StoryExcerpt':
      return [COMMANDS.STORY_EXCERPT];
    case 'StorySeoDescription':
      return [COMMANDS.STORY_META_DESCRIPTION];
    case 'StorySeoTitle':
      return [COMMANDS.STORY_META_TITLE];
    default:
      return [COMMANDS.TOPIC_QUESTIONS, COMMANDS.TOPIC_PROSCONS, COMMANDS.TOPIC_BLOG_TITLES];
  }
}
