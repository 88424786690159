import { useState } from 'react';
import usePreview from '@/storychief/hooks/usePreview';
import clientStorage from '@/storychief/utils/clientStorage';
import useEntitlements from '@/entitlements/hooks/useEntitlements';
import StoryChief from '@/storychief';
import { COMMAND_LANGUAGES } from '../constants/Constants';
import useBrandVoices from '@/assistant/hooks/useBrandVoices';

export type UseAssistantConfigReturnType = {
  getAssistantLanguage: () => string | null;
  setAssistantLanguage: (language: string | null) => void;
  local: {
    data: {
      language?: string;
      placeholders?: any[];
      brandVoice?: string | number | null;
    };
    set: (value: object) => void;
  };
  account: { loading: boolean; data: { brandVoices: [] } };
};

export default function useAssistantConfig(): UseAssistantConfigReturnType {
  const [localConfig, setLocalConfig] = useState({
    placeholders: [],
  });
  const { preview } = usePreview();
  const getEntitlement = useEntitlements();
  const available = getEntitlement('ai-assistant');

  const { data: { brandVoices } = { brandVoices: [] }, loading: isLoadingBrandVoices } =
    useBrandVoices({
      skip: preview || !available,
      onCompleted: (data) => {
        const defaultBrandVoice = data.brandVoices.find((_b) => _b.is_default);

        if (defaultBrandVoice) {
          setAssistantLocalConfig({
            brandVoice: defaultBrandVoice.id,
          });
        }
      },
    });

  function getDefaultLanguage() {
    if (StoryChief?.account?.languages.length) {
      const accountDefaultLanguage = StoryChief.account.languages.find((l) => l.default);

      if (accountDefaultLanguage) {
        const commandLanguage = COMMAND_LANGUAGES.find((l) =>
          accountDefaultLanguage.code.toLowerCase().startsWith(l.code),
        );

        if (commandLanguage) {
          return commandLanguage.value;
        }
      }
    }

    return null;
  }

  function setAssistantLocalConfig(data) {
    setLocalConfig({
      ...localConfig,
      ...data,
    });
  }

  /*
   * Retrieve the last used language or account default.
   */
  function getAssistantLanguage(): string | null {
    return clientStorage.getItem('assistant.language') || getDefaultLanguage();
  }

  /*
   * Store the language in local storage.
   */
  function setAssistantLanguage(language: string): void {
    clientStorage.setItem('assistant.language', language);
  }

  return {
    getAssistantLanguage,
    setAssistantLanguage,
    local: {
      data: localConfig,
      set: setAssistantLocalConfig,
    },
    account: {
      loading: isLoadingBrandVoices,
      data: {
        brandVoices,
      },
    },
  };
}
