import { RichUtils } from 'draft-js';

/**
 * @param {import('draft-js').DraftModel.ImmutableData.EditorState} editorState
 * @returns {string}
 */
export default function getEditorStateCurrentBlockType(editorState) {
  // The block type might be undefined (eg. when undoing changes) which makes getCurrentBlockType error out.
  try {
    return RichUtils.getCurrentBlockType(editorState);
  } catch {
    return 'unstyled';
  }
}
