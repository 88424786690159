import { useEffect, useState, useRef } from 'react';
import { throttle } from 'lodash-es';

export default function usePopoverHeight(containerRef, options) {
  const defaultOptions = { minHeight: 250, maxHeight: null };
  const { maxHeight, minHeight } = Object.assign(defaultOptions, options);
  const [popoverHeight, setPopoverHeight] = useState(null);
  const popoverResizeObserver = useRef(null);
  const adjustPopoverHeightThrottled = useRef(null);
  const container = containerRef?.current || null;

  function adjustPopoverHeight() {
    window.requestAnimationFrame(() => {
      const popover = container?.querySelector('.popover');

      if (popover) {
        const viewportOffset = popover.getBoundingClientRect();
        const viewportTop = viewportOffset.top;
        const windowHeight = window.innerHeight;
        let height = Math.max(windowHeight - viewportTop - 18, minHeight);
        if (maxHeight) {
          height = Math.min(height, maxHeight);
        }
        setPopoverHeight(height);
      }
    });
  }

  function getResizeElement() {
    return document.querySelector('.page-main') || document.querySelector('html');
  }

  function removeResizeListener() {
    if (popoverResizeObserver.current) {
      popoverResizeObserver.current.unobserve(getResizeElement());
    }
  }

  function resizeListener() {
    removeResizeListener();

    if (container) {
      popoverResizeObserver.current = new ResizeObserver(adjustPopoverHeight);
      popoverResizeObserver.current.observe(getResizeElement());

      return () => {
        removeResizeListener();
      };
    }

    return undefined;
  }

  function getScrollElement() {
    return document.querySelector('.page-main') || window;
  }

  function removeScrollListener() {
    if (adjustPopoverHeightThrottled?.current) {
      getScrollElement().removeEventListener('scroll', adjustPopoverHeightThrottled.current);
    }
  }

  function scrollListener() {
    removeScrollListener();

    if (container) {
      adjustPopoverHeightThrottled.current = throttle(() => adjustPopoverHeight(), 100);
      getScrollElement().addEventListener('scroll', adjustPopoverHeightThrottled.current);

      return () => {
        removeScrollListener();
      };
    }

    return undefined;
  }

  useEffect(resizeListener, [container]);
  useEffect(scrollListener, [container]);

  return [popoverHeight, adjustPopoverHeight];
}
