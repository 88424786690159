import request from 'superagent';
import convertIframeToResponsive from '../utils/convertIframeToResponsive';
import getIframeSrc from '../../storychief/utils/getIframeSrc';
import StoryChief from '../../storychief';

export default function (url) {
  return new Promise((resolve, reject) => {
    const regExp = /play.adways.com\/[a-zA-Z0-9_-]+\/([a-zA-Z0-9]+)/gi; // eslint-disable-line no-useless-escape, max-len
    const match = regExp.exec(url);
    request
      .put(`${StoryChief.dashboardBasePath}/integrations/embed/oembed`)
      .set('Accept', 'application/json')
      .set('X-Requested-With', 'XMLHttpRequest')
      .set('X-CSRF-TOKEN', StoryChief.csrfToken)
      .send({ url: match[1], provider: 'adways' })
      .end((err, res) => {
        if (err) {
          reject(err);
        } else {
          const data = res.body;
          data.iframe_src = getIframeSrc(data.html);
          data.html_responsive = convertIframeToResponsive(data.html);
          resolve(data);
        }
      });
  });
}
