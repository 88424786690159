import { ControlLabel, FormGroup, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { components as selectComponent } from 'react-select';
// The dependency cycle is caused by <TextEditor> importing assistant components and those components also importing <TextEditor>.
// The issue is mitigated by disabling the assistant on <TextEditor> inside the assistant components.
// eslint-disable-next-line import/no-cycle
import TextEditor from '@/storychief/components/editors/TextEditor';
import useAssistant from '@/assistant/hooks/useAssistant';
import Select from '@/storychief/components/Select';
import getAssistantConfigCommandContextValues from '@/assistant/utils/getAssistantConfigCommandContextValues';

const propTypes = {
  size: PropTypes.oneOf(['sm', 'lg']),
};
const defaultProps = {
  size: undefined,
};
function CommandFormContext({ size }) {
  const { command, loading, context, config, setContext } = useAssistant();

  function handleOnContextChange(_contextIdentifier, _value) {
    setContext((_prevContext) => {
      if (!_prevContext) {
        return { [_contextIdentifier]: _value };
      }
      return { ..._prevContext, [_contextIdentifier]: _value };
    });
  }

  if (!command.context) {
    return null;
  }

  function renderContextFieldSelect(_contextItem) {
    const _values = [
      ..._contextItem.values,
      ...getAssistantConfigCommandContextValues(_contextItem.identifier, config),
    ];
    const _value =
      context && context[_contextItem.identifier]
        ? _values.find((_v) => _v.value === context[_contextItem.identifier])
        : _values[0];

    return (
      <Select
        value={_value}
        isDisabled={loading}
        placeholder={`Select ${_contextItem.label}`}
        options={_values}
        getOptionValue={(option) => option.value}
        onChange={({ value }) => handleOnContextChange(_contextItem.identifier, value)}
        isSelected
        components={{
          Option: (innerProps) => (
            <selectComponent.Option {...innerProps}>
              <div className="white-space-normal">
                <div>{innerProps.data.label}</div>
                <div className="no-line-height small text-muted">
                  {innerProps.data.label_description}
                </div>
              </div>
            </selectComponent.Option>
          ),
        }}
      />
    );
  }

  function renderContextFieldRadio(_contextItem) {
    const _values = [
      ..._contextItem.values,
      ...getAssistantConfigCommandContextValues(_contextItem.identifier, config),
    ];
    const _value =
      context && context[_contextItem.identifier]
        ? _values.find((_v) => _v.value === context[_contextItem.identifier])
        : _values[0];

    return (
      <div>
        <div className="btn-group">
          {_values.map((_v) => (
            <Button
              type="button"
              key={`${_contextItem.identifier}-${_v.value}`}
              className={`btn-small btn-default-light ${_v.value === _value.value ? 'active' : ''}`}
              onClick={() => handleOnContextChange(_contextItem.identifier, _v.value)}
            >
              {_v.label}
            </Button>
          ))}
        </div>
      </div>
    );
  }

  function renderContextField(_contextItem) {
    switch (_contextItem.type) {
      case 'select':
        return renderContextFieldSelect(_contextItem);
      case 'radio':
        return renderContextFieldRadio(_contextItem);
      default:
        return (
          <TextEditor
            data-testid={`command-form-context-${_contextItem.identifier}`}
            allowNewLines
            placeholder={_contextItem.placeholder || ''}
            readOnly={loading}
            content={
              context && context[_contextItem.identifier] ? context[_contextItem.identifier] : ''
            }
            onContentChange={(value) => handleOnContextChange(_contextItem.identifier, value)}
            formControl
            formControlAutoHeight
            formControlId={`command-form-context-${_contextItem.identifier}`}
            assistant={false}
            debounceContentChange={false}
          />
        );
    }
  }

  return (
    <div className="command-form-context">
      {command.context.map((_contextItem) => (
        <FormGroup
          key={_contextItem.identifier}
          controlId="context"
          bsSize={size}
          data-testid={`command-form-context-${_contextItem.identifier}`}
        >
          <ControlLabel bsClass="control-label">{_contextItem.label}</ControlLabel>
          {renderContextField(_contextItem)}
          {_contextItem.description && (
            <span className="help-block">{_contextItem.description}</span>
          )}
        </FormGroup>
      ))}
    </div>
  );
}

CommandFormContext.propTypes = propTypes;
CommandFormContext.defaultProps = defaultProps;

export default CommandFormContext;
