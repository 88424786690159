import request from 'superagent';
import getIframeSrc from '../../storychief/utils/getIframeSrc';
import StoryChief from '../../storychief';

export default function (url) {
  return new Promise((resolve, reject) => {
    request
      .put(`${StoryChief.dashboardBasePath}/integrations/embed/oembed`)
      .set('Accept', 'application/json')
      .set('X-Requested-With', 'XMLHttpRequest')
      .set('X-CSRF-TOKEN', StoryChief.csrfToken)
      .send({
        url,
        provider: 'transistor',
      })
      .end((err, res) => {
        if (err) {
          reject(err);
        } else {
          const data = res.body;
          data.html_responsive = data.html;
          data.iframe_src = getIframeSrc(data.html);
          resolve(data);
        }
      });
  });
}
