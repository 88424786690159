import PropTypes from 'prop-types';
import ToolbarButton from './ToolbarButton';
import getEditorStateCurrentBlockType from '@/editor/utils/getEditorStateCurrentBlockType';

function ToolbarBlock(props) {
  if (props.buttons.length < 1) {
    return null;
  }
  const { getEditorState, disabled, showTooltips } = props;
  const blockType = getEditorStateCurrentBlockType(getEditorState());

  return (
    <div className="RichEditor-controls">
      {props.buttons.map((type) => {
        const iconLabel = {};
        iconLabel.label = type.label;

        return (
          <ToolbarButton
            disabled={disabled}
            {...iconLabel}
            key={type.style}
            active={type.style === blockType}
            onToggle={props.onToggle}
            styleName={type.style}
            icon={type.icon}
            description={type.description}
            shortcut={type.shortcut}
            showTooltip={showTooltips}
          />
        );
      })}
    </div>
  );
}

ToolbarBlock.propTypes = {
  buttons: PropTypes.arrayOf(PropTypes.shape({})),
  getEditorState: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  onToggle: PropTypes.func,
  showTooltips: PropTypes.bool,
};

ToolbarBlock.defaultProps = {
  buttons: [],
  disabled: false,
  showTooltips: false,
  onToggle: () => {},
};

export default ToolbarBlock;
