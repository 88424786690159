import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Icon } from '@iconify-icon/react';
import resetBlockWithType from '../../utils/resetBlockWithType';

const propTypes = {
  setEditorState: PropTypes.func.isRequired,
  getEditorState: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isPrivate: PropTypes.bool,
};
const defaultProps = {
  disabled: false,
  isPrivate: false,
};

function ImageButton({ setEditorState, getEditorState, disabled, isPrivate }) {
  function onClick() {
    const data = { src: '', type: 'image', size: 'regular', alignment: '', isPrivate };
    setEditorState(resetBlockWithType(getEditorState(), 'atomic', data));
  }

  const tooltip = <Tooltip id="image-tooltip">Add image</Tooltip>;
  return (
    <div className="sb-button-wrapper">
      <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={tooltip}>
        <button
          className="sb-button sb-img-button"
          disabled={disabled}
          type="button"
          onClick={onClick}
        >
          <Icon icon="fa:picture-o" inline height="13" className="mx-[3px]" />
        </button>
      </OverlayTrigger>
    </div>
  );
}

ImageButton.propTypes = propTypes;
ImageButton.defaultProps = defaultProps;

export default ImageButton;
