export default function (url) {
  // https://cta-service-cms2.hubspot.com/web-interactives/public/v1/track/redirect?encryptedPayload=AVxigLIqX5A6XVqhYKlakqEowBH2sWtwjOhwm%2FuZyYEeANpAhIxySUKjlC1hpqSfCgONKbrv6p9B8n%2F7krWcuMWVpCDkzCJneXAYQWcWyNHkTmB84Bm%2BW0%2BNXVICiaPvOzxysWxMj87YFW4ALYlqBYrqAcv%2Ff4v6TeQgVA%3D%3D&webInteractiveContentId=131471279693&portalId=20240156
  const isCtaBeta = !!url.includes('cta-service-cms2');

  const urlObject = new URL(url);
  const params = new URLSearchParams(urlObject.search);

  let data = {};
  const urlParams = url.split('/');
  const portalId = isCtaBeta ? params.get('portalId') : urlParams[5];
  const ctaGuid = isCtaBeta ? params.get('webInteractiveContentId') : urlParams[6];

  const iframeSrc = isCtaBeta
    ? `https://${portalId}.hs-sites.com/hs-web-interactive-${portalId}-${ctaGuid}`
    : `https://amp.hubspot.net/_hcms/cta?portalId=${portalId}&ctaGuid=${ctaGuid}`;
  const html = isCtaBeta
    ? `
  <div class="hs-cta-embed hs-cta-simple-placeholder hs-cta-embed-${ctaGuid}"
style="max-width:100%; max-height:100%;">
<a href="${url}" target="_blank" rel="noopener" crossorigin="anonymous">
  <img loading="lazy" src="https://no-cache.hubspot.com/cta/default/${portalId}/interactive-${ctaGuid}.png" style="height: 100%; width: 100%; object-fit: fill"
    onerror="this.style.display='none'" />
</a>
</div>
<script defer src="https://js.hubspot.com/web-interactives-embed.js" type="text/javascript" id="hubspot-web-interactives-loader" data-loader="hs-previewer" data-hsjs-portal="${portalId}" data-hsjs-env="prod" data-hsjs-hublet="na1"></script>`
    : `<span class="hs-cta-wrapper" id="hs-cta-wrapper-${ctaGuid}">
<span class="hs-cta-node hs-cta-${ctaGuid}" id="hs-cta-${ctaGuid}">
  <!--[if lte IE 8]><div id="hs-cta-ie-element"></div><![endif]-->
  <a href="https://cta-redirect.hubspot.com/cta/redirect/${portalId}/${ctaGuid}"  target="_blank" >
      <img class="hs-cta-img" id="hs-cta-img-${ctaGuid}" style="border-width:0px;" src="https://no-cache.hubspot.com/cta/default/${portalId}/${ctaGuid}.png"  alt="cta"/>
  </a>
</span>
<script charset="utf-8" src="https://js.hscta.net/cta/current.js"></script>
<script type="text/javascript"> hbspt.cta.load(${portalId}, '${ctaGuid}', {}); </script>
</span>`;
  data = {
    type: 'rich',
    version: '1.0',
    iframe_src: iframeSrc,
    provider_name: 'HubspotCta',
    provider_url: 'https://www.hubspot.com/',
    portal_id: portalId,
    cta_guid: ctaGuid,
    width: 300,
    height: 100,
    html,
  };
  return new Promise((resolve) => {
    data.html_responsive = data.html;
    resolve(data);
  });
}
