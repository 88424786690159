import request from 'superagent';
import convertIframeToResponsive from '../utils/convertIframeToResponsive';
import getIframeSrc from '../../storychief/utils/getIframeSrc';
import StoryChief from '../../storychief';

export default function (url) {
  return new Promise((resolve, reject) => {
    const urlSplit = url.split('/');
    if (urlSplit.length === 7) {
      const episodeId = urlSplit[5].substring(urlSplit[5].lastIndexOf('-') + 1);
      const audio = urlSplit[6].substring(urlSplit[6].lastIndexOf('-') + 1);
      const title = urlSplit[5].substring(0, urlSplit[5].lastIndexOf('-')).replace(/[-]/g, ' ');
      request
        .put(`${StoryChief.dashboardBasePath}/integrations/embed/oembed`)
        .send({
          episodeId,
          audio,
          title,
          provider: 'anchorfm',
        })
        .set('X-Requested-With', 'XMLHttpRequest')
        .set('X-CSRF-TOKEN', StoryChief.csrfToken)
        .end((err, res) => {
          if (err) {
            reject(err);
          } else {
            const data = res.body;
            data.iframe_src = getIframeSrc(data.html);
            data.html_responsive = convertIframeToResponsive(data.html);
            resolve(data);
          }
        });
    } else {
      reject();
    }
  });
}
