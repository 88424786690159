import { ControlLabel, FormGroup, FormControl } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useRef, useEffect } from 'react';
// The dependency cycle is caused by <TextEditor> importing assistant components and those components also importing <TextEditor>.
// The issue is mitigated by disabling the assistant on <TextEditor> inside the assistant components.
// eslint-disable-next-line import/no-cycle
import TextEditor from '@/storychief/components/editors/TextEditor';
import useAssistant from '@/assistant/hooks/useAssistant';
import { COMMAND_IDENTIFIERS, COMMAND_SECTIONS } from '@/assistant/constants/Constants';
import CommandFormSubmit from './CommandFormSubmit';
import CommandFormContext from './CommandFormContext';
import CommandFormContextAssets from './CommandFormContextAssets';
import shouldIncludeContextAssets from '@/assistant/utils/shouldIncludeContextAssets';
import analyticsTrack from '@/storychief/utils/analyticsTrack';
import Button from '@/storychief/components/Button';

const propTypes = {
  insidePopover: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'lg']),
  autoFocus: PropTypes.bool,
};
const defaultProps = {
  insidePopover: false,
  size: undefined,
  autoFocus: true,
};
function CommandForm({ insidePopover, size, autoFocus }) {
  const {
    result,
    command,
    loading,
    error,
    usage,
    isNearOrOverMonthlyLimit,
    isOverMonthlyLimit,
    maxMonthlyUsage,
    runCommand,
    discardCommand,
    resetCommand,
    subject,
    input,
    setInput,
    context,
    setContext,
    getEditorState,
    available,
  } = useAssistant();

  const _input = input || null;
  const _textSelection = context?.subject?.textSelection || null;
  const _textAboveSelection = context?.subject?.textAboveSelection || null;
  const _textBelowSelection = context?.subject?.textBelowSelection || null;
  const _text = _input || _textSelection || _textAboveSelection || _textBelowSelection || '';

  // The user cannot submit without a working title or selecting some text.
  const disableSubmit = !_text || !_text.length;

  // Use key to reset TextEditor as it has internal state which we can't reset from the outside.
  const key = useRef(0);

  useEffect(() => {
    if (error && insidePopover && command.section !== COMMAND_SECTIONS.DRAFT) {
      discardCommand();
    }
  }, [error]);

  useEffect(() => {
    analyticsTrack('Assistant Command Viewed', {
      command: command.identifier,
      subject_type: subject?.type || null,
      has_access: available,
    });
  }, []);

  function handleOnSubmitClick(event) {
    if (event) {
      event.preventDefault();
    }

    if (available && !isOverMonthlyLimit) {
      resetCommand(command, input, context, null);
      runCommand(command, input, context, null, null);
    }
  }

  function handleOnInputChange(newInput) {
    setInput(newInput);
  }

  function handleOnInputKeyDown(event) {
    if (event.keyCode === 13 && event.ctrlKey) {
      handleOnSubmitClick(event);
    }
  }

  function handleOnClear() {
    key.current += 1;
    setInput('');
    setContext();
    resetCommand(command);
  }

  return (
    // We need to use a div instead of a form because a potential ancestor of this component can be a form element.
    // The div can be replaced with a form element if the popover will be rendered separately from its caller location.
    <div className="command-form" key={key.current}>
      {command?.input && (
        <FormGroup controlId="input" bsSize={size}>
          <div className="d-flex flex-align-items-center space-1">
            <div className="flex-grow">
              <ControlLabel bsClass="control-label" className="m-0">
                {subject.hasSelectedText && command.input.labelSelectedText
                  ? command.input.labelSelectedText
                  : command.input.label}
              </ControlLabel>
            </div>
          </div>
          <div className="d-flex flex-gap-lg flex-row">
            {command.input.type === 'text' ? (
              <TextEditor
                allowNewLines
                focusOnMount={autoFocus}
                placeholder={command.input.placeholder || ''}
                readOnly={loading}
                content={input}
                onContentChange={handleOnInputChange}
                onReturn={handleOnSubmitClick}
                formControl
                formControlAutoHeight
                formControlId="input"
                assistant={false}
                debounceContentChange={false}
              />
            ) : (
              <FormControl
                data-testid="powermode-assistant-working-title"
                type="text"
                autoFocus={autoFocus}
                value={input}
                placeholder={command.input.placeholder || ''}
                onChange={(e) => handleOnInputChange(e.target.value)}
                onKeyDown={handleOnInputKeyDown}
                disabled={loading}
              />
            )}
            {command.identifier === COMMAND_IDENTIFIERS.CHAT && insidePopover && (
              <CommandFormSubmit
                insidePopover={insidePopover}
                size={size}
                disabled={!input?.length}
                onSubmit={handleOnSubmitClick}
              />
            )}
          </div>
          {command.input.description && (
            <div className="help-block">{command.input.description}</div>
          )}
        </FormGroup>
      )}
      {shouldIncludeContextAssets(command, getEditorState) && (
        <CommandFormContextAssets size={size} />
      )}

      {command?.context && <CommandFormContext size={size} />}

      <div className="command-form-footer d-flex flex-align-center">
        {!!(
          !insidePopover ||
          (insidePopover && command.identifier !== COMMAND_IDENTIFIERS.CHAT)
        ) && (
          <div className="gutter-right-2">
            <CommandFormSubmit
              insidePopover={insidePopover}
              size={size}
              onSubmit={handleOnSubmitClick}
              disabled={disableSubmit}
            />
          </div>
        )}
        {!insidePopover && !!result && (
          <div className="gutter-right-2">
            <Button
              onClick={handleOnClear}
              disabled={loading}
              className="btn-outline"
              size={size || 'sm'}
              variant="secondary-alt"
            >
              Clear
            </Button>
          </div>
        )}
        {isNearOrOverMonthlyLimit && available && (
          <div className="gutter-right-2">
            <small className="text-muted gutter-left-1">
              {usage}/{maxMonthlyUsage}
            </small>
          </div>
        )}
      </div>
    </div>
  );
}

CommandForm.propTypes = propTypes;
CommandForm.defaultProps = defaultProps;

export default CommandForm;
