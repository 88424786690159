import { GlobalContentTypesModelsType } from '@/storychief/constants/contentTypes';
import GLOBAL_CONTENT_TYPE_PUSHER_CHANNELS_PREFIX from '../constants';

function getPusherChannelName(
  modelType: keyof GlobalContentTypesModelsType,
  modelId: string | number,
): string {
  return `${GLOBAL_CONTENT_TYPE_PUSHER_CHANNELS_PREFIX[modelType]}.${modelId}`;
}

export default getPusherChannelName;
