import getRangesForDraftEntity from 'draft-js/lib/getRangesForDraftEntity';
import { SelectionState } from 'draft-js';

export default function getEntitySelectionState(contentState, selectionState, entityKey) {
  const selectionKey = selectionState.getAnchorKey();
  const selectionOffset = selectionState.getAnchorOffset();
  const block = contentState.getBlockForKey(selectionKey);
  const blockKey = block.getKey();

  let entitySelection = null;
  getRangesForDraftEntity(block, entityKey).forEach((range) => {
    if (range.start <= selectionOffset && selectionOffset <= range.end) {
      entitySelection = new SelectionState({
        anchorOffset: range.start,
        anchorKey: blockKey,
        focusOffset: range.end,
        focusKey: blockKey,
        isBackward: false,
        hasFocus: selectionState.getHasFocus(),
      });
    }
  });
  if (entitySelection) {
    return selectionState.merge({
      anchorOffset: entitySelection.anchorOffset,
      focusOffset: entitySelection.focusOffset,
    });
  }
  return null;
}
