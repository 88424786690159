import convertIframeToResponsive from '../utils/convertIframeToResponsive';

export default function (url) {
  const data = {};
  const newUrl = url.replace('podcasts.', 'embed.podcasts.');
  const iframeData = `<iframe src="${newUrl}" height="175px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *;" style="width: 660px; max-width: 660px; overflow: hidden; border-radius: 10px; background: transparent;"></iframe>`;
  data.html_responsive = convertIframeToResponsive(iframeData);

  return new Promise((resolve) => {
    resolve(data);
  });
}
