function getFormDetails(url) {
  const windowLocation = document.createElement('a');
  windowLocation.href = url;
  // javascript from source code form page https://share.hsforms.com/aaaaaaa-bbbbb
  // 1 change was made: renamed window.location to windowLocation
  /* eslint-disable */
  var o,
    e,
    i,
    s,
    u,
    p = '-',
    d = [],
    c = [
      ['+', '-'],
      ['/', '_'],
    ];
  function l(t) {
    return (
      (t = (function (t) {
        for (var r = window.atob(t), n = [], o = 0; o < r.length; o++) n.push(r.charCodeAt(o));
        return new window.Uint8Array(n);
      })(t)),
      (r = r || 0),
      [
        d[t[r++]],
        d[t[r++]],
        d[t[r++]],
        d[t[r++]],
        '-',
        d[t[r++]],
        d[t[r++]],
        '-',
        d[t[r++]],
        d[t[r++]],
        '-',
        d[t[r++]],
        d[t[r++]],
        '-',
        d[t[r++]],
        d[t[r++]],
        d[t[r++]],
        d[t[r++]],
        d[t[r++]],
        d[t[r++]],
      ].join('')
    );
    var r;
  }
  function f(t) {
    var r,
      n = t.charAt(0) === p,
      o = n ? 2 : 1,
      r = l(
        ((r = t.substring(o, 22 + o)),
        c.reduce(function (t, r) {
          return t.replace(new RegExp('\\'.concat(r[1]), 'g'), r[0]);
        }, r)),
      );
    return {
      formId: n ? r.replace(/-/g, '') : r,
      portalId: parseInt(t.substring(22 + o), 36),
      version:
        ((o = t.charAt(o - 1)), parseInt(o, 36) + (isNaN(+o) && o === o.toUpperCase() ? 26 : 0)),
    };
  }
  function m() {
    return windowLocation.hostname.split('.')[0].split('-')[1] || '';
  }
  d = (function () {
    for (var t = [], r = 0; r < 256; r++) t.push((r + 256).toString(16).slice(1));
    return t;
  })();
  e = windowLocation.pathname.split('/').filter(function (t) {
    return '' !== t && -1 === t.indexOf('test-');
  });
  i = e[0];
  o =
    2 === e.length
      ? {
          region: m(),
          portalId: e[0],
          formId: e[1],
        }
      : (((i = f(i)).region = m()), i);

  return o;
  /* eslint-enable */
}

export default function (url) {
  return new Promise((resolve, reject) => {
    const formDetails = getFormDetails(url);
    if (formDetails && formDetails.formId && formDetails.portalId) {
      const data = {
        type: 'rich',
        version: '1.0',
        iframe_src: url,
        provider_name: 'HubspotForm',
        povider_url: 'https://www.hubspot.com/',
        portal_id: formDetails.portalId,
        form_id: formDetails.formId,
        region: formDetails.region,
        width: 300,
        height: 100,
        html: `<span class="js-hubspot-form" data-portal-id="${formDetails.portalId}" data-form-id="${formDetails.formId}" data-region="${formDetails.region}">
                  <!--[if lte IE 8]>
                  <script charset="utf-8" type="text/javascript" src="//js.hsforms.net/forms/v2-legacy.js"></script>
                  <![endif]-->
                  <script charset="utf-8" type="text/javascript" src="//js.hsforms.net/forms/v2.js"></script>
                  <script>
                    hbspt.forms.create({region:"${formDetails.region}",portalId:"${formDetails.portalId}",formId:"${formDetails.formId}"});
                  </script>
               </span>`,
      };
      data.html_responsive = data.html;
      resolve(data);
    } else {
      reject(new Error('form not found'));
    }
  });
}
