import { useLayoutEffect, useEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import BlockCaption from '../../components/blocks/BlockCaption';
import updateDataOfBlock from '../../utils/updateDataOfBlock';
import deleteDataBlock from '../../utils/deleteDataBlock';
import selectBlock from '../../utils/selectBlock';
import MediaItem from '@/media/components/MediaItem';
import useModal from '@/modals/hooks/useModal';

const propTypes = {
  block: PropTypes.shape({
    getData: PropTypes.func.isRequired,
  }).isRequired,
  blockProps: PropTypes.shape({
    getEditorState: PropTypes.func.isRequired,
    setEditorState: PropTypes.func.isRequired,
    setReadOnly: PropTypes.func.isRequired,
    isSelected: PropTypes.bool,
    editorEnabled: PropTypes.bool,
  }).isRequired,
};
const defaultProps = {};

function GalleryBlock(props) {
  // Variables
  const blockData = props.block.getData();
  let images = blockData.get('images');
  // fix: data firstly returns a List after that it returns an array
  if (Immutable.List.isList(images)) {
    images = images.toArray();
  }
  images = images.map((img) => {
    if (Immutable.Map.isMap(img)) {
      return img.toObject();
    }
    return img;
  });

  const hasImages = images?.length > 1;
  const size = blockData.get('size');
  const isSelected = props.blockProps.isSelected;
  const classNameWrapper = `block-container block-gallery-container ${!hasImages && ' is-empty'} ${
    isSelected ? 'selected' : ''
  } ${size}`;

  // Hooks
  const modal = useModal('ImageGallery');
  useLayoutEffect(() => {
    if (typeof window.strchf !== 'undefined') {
      window.strchf.Embeds.process();
    }
  }, []);

  useLayoutEffect(() => {
    if (typeof window.strchf !== 'undefined') {
      window.strchf.Embeds.destroy();
      window.strchf.Embeds.process();
    }
  }, [blockData]);

  useEffect(() => {
    loadEmbedsScript();
  }, []);

  // Functions
  function loadEmbedsScript() {
    if (typeof window.strchf === 'undefined' && !document.getElementById('storychief-jssdk')) {
      const scriptNode = document.createElement('script');
      document.body.appendChild(scriptNode);
      scriptNode.onload = () => {
        window.strchf.Embeds.destroy();
        window.strchf.Embeds.process();
      };
      scriptNode.id = 'storychief-jssdk';
      scriptNode.src = `${window.StoryChief.services.cloudfront.url}/scripts/v0/strchf.js`;
    }
  }

  function updateData(data) {
    const { getEditorState, setEditorState } = props.blockProps;
    setEditorState(updateDataOfBlock(getEditorState(), props.block, data));
  }

  function onWrapperClick() {
    const { getEditorState, setEditorState } = props.blockProps;
    setEditorState(selectBlock(props.block, getEditorState()));
  }

  function onOrganizeClick() {
    const { getEditorState, setEditorState } = props.blockProps;
    setEditorState(selectBlock(props.block, getEditorState()));

    modal.toggle({
      props: {
        images: images.map((_image) => ({ ..._image, url: _image.src })),
        isPrivate: blockData.get('isPrivate'),
        onSave: (_images) => {
          updateData({
            images: _images.map((_image) => ({
              id: _image.id,
              src: _image.url,
              width: _image.width,
              height: _image.height,
              caption: _image.caption,
              alt: _image.alt,
            })),
          });
        },
      },
    });
  }

  function onRemove() {
    props.blockProps.setReadOnly(true);
    const newEditorState = deleteDataBlock(props.blockProps.getEditorState(), props.block);
    props.blockProps.setEditorState(newEditorState);
    props.blockProps.setReadOnly(false);
  }

  return (
    <div
      className={classNameWrapper}
      onClick={props.blockProps.editorEnabled && !isSelected ? onWrapperClick : null}
      onKeyPress={props.blockProps.editorEnabled && !isSelected ? onWrapperClick : null}
    >
      {!hasImages ? (
        <div contentEditable={false}>
          <button
            type="button"
            onClick={onRemove}
            className="btn-chromeless btn-delete icon-cancel"
            aria-label="Remove"
          />
          <h2>
            Add an <span className="highlight-marker">image gallery</span>
          </h2>
          <p className="text-muted">
            <small>Add some images to your gallery</small>
          </p>
          <button
            className="btn btn-primary d-inline-block"
            type="button"
            onClick={onOrganizeClick}
          >
            Organize
          </button>
          <BlockCaption {...props} />
        </div>
      ) : (
        <div className="media-wrapper gallery-wrapper">
          <button
            type="button"
            onClick={onRemove}
            className="btn-chromeless btn-delete icon-cancel"
            aria-label="Remove"
          />
          <div contentEditable={false} className="gallery-container strchf-gallery">
            <div className="strchf-gallery-images">
              {images.map((image, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <div key={index}>
                  <MediaItem
                    object={{
                      mime_type: 'image/*',
                      ...image,
                      url: image.src,
                    }}
                    size="full"
                  />
                  {image.caption && <figcaption>{image.caption}</figcaption>}
                </div>
              ))}
            </div>
            <div className="strchf-gallery-controls">
              <div className="strchf-gallery-control-prev" role="button">
                &#8249;
              </div>
              <div className="strchf-gallery-control-next" role="button">
                &#8250;
              </div>
            </div>
            <div className="strchf-gallery-nav">
              {images.map((image, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <div role="button" key={index} aria-label={`View image ${index + 1}`} />
              ))}
            </div>
          </div>
          <BlockCaption {...props} />
        </div>
      )}
    </div>
  );
}

GalleryBlock.propTypes = propTypes;
GalleryBlock.defaultProps = defaultProps;

export default GalleryBlock;
