import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { CAPTURE_INTEGRATIONS } from '@/storychief/constants/Constants';
import BlockCaption from '../../components/blocks/BlockCaption';
import updateDataOfBlock from '../../utils/updateDataOfBlock';
import deleteDataBlock from '../../utils/deleteDataBlock';
import selectBlock from '../../utils/selectBlock';
import StoryChief from '@/storychief';
import addStoryIntegration from '@/storyIntegrations/actions/addStoryIntegration';
import deleteStoryIntegration from '@/storyIntegrations/actions/deleteStoryIntegration';

const propTypes = {
  block: PropTypes.shape({
    getData: PropTypes.func.isRequired,
    getKey: PropTypes.func.isRequired,
  }).isRequired,
  blockProps: PropTypes.shape({
    editorEnabled: PropTypes.bool,
    isSelected: PropTypes.bool,
    getModelId: PropTypes.func.isRequired,
    getEditorState: PropTypes.func.isRequired,
    setEditorState: PropTypes.func.isRequired,
    setReadOnly: PropTypes.func.isRequired,
  }).isRequired,
};
const defaultProps = {};

function CaptureBlock(props) {
  const [processing, setProcessing] = useState(false);
  const statusPolling = useRef(null);
  const dispatch = useDispatch();
  // The storyIntegrations are used inside a interval and the value changes after the interval is run.
  // To avoid the interval having a stale value, we need to store it in a ref.
  const storyIntegrationsRef = useRef(null);
  const storyIntegrations = useSelector((state) => state.story.integrations);
  const integrations = useSelector((state) => state.integrations);

  // Variables
  const { block, blockProps } = props;
  const isSelected = blockProps.isSelected;
  const data = block.getData();
  const storyIntegrationId = data.get('storyIntegrationId');
  const captureSrc = getCaptureSrc();

  // Effects
  useEffect(() => {
    if (blockProps.editorEnabled) {
      const integrationId = data.get('integrationId');
      let storyIntegration = getStoryIntegration();
      if (!storyIntegration && storyIntegrationId) {
        // restore storyIntegration
        storyIntegration = {
          story_id: blockProps.getModelId(),
          integration_id: integrationId,
          story_integration_id: storyIntegrationId,
        };
        const blockKey = block.getKey();
        dispatch(addStoryIntegration(storyIntegration, blockKey));
        startStatusPolling();
      }
    }

    return () => {
      if (blockProps.editorEnabled) {
        const hasFocus = blockProps.getEditorState().getSelection().getHasFocus();
        if (hasFocus) {
          const storyIntegration = getStoryIntegration();
          if (storyIntegration) {
            dispatch(deleteStoryIntegration(storyIntegration));
          }
          stopStatusPolling();
        }
      }
    };
  }, []);

  useEffect(() => {
    storyIntegrationsRef.current = storyIntegrations;
  }, [JSON.stringify(storyIntegrations)]);

  // Functions
  function getCaptureSrc() {
    const storyIntegration = getStoryIntegration();

    if (storyIntegration !== null || !blockProps.editorEnabled) {
      return `${window.StoryChief.app_url}/capture/${storyIntegrationId}`;
    }

    return null;
  }

  function getCaptureIntegrations() {
    return integrations?.filter((i) => CAPTURE_INTEGRATIONS.includes(i.integration_type)) || null;
  }

  function getStoryIntegration() {
    let storyIntegration = null;
    if (storyIntegrationId) {
      storyIntegration = storyIntegrations
        ? storyIntegrations.find((i) => i.id === storyIntegrationId)
        : null;
      if (storyIntegration === undefined) {
        storyIntegration = null;
      }
    }
    return storyIntegration;
  }

  function startStatusPolling() {
    setProcessing(true);
    statusPolling.current = setInterval(() => getStoryIntegrationByBlockKey(), 1000);
  }

  function stopStatusPolling() {
    setProcessing(false);
    clearInterval(statusPolling.current);
  }

  function getStoryIntegrationByBlockKey() {
    const blockKey = block.getKey();
    // check if a new storyIntegration was added by searching it by blockKey
    const storyIntegration = storyIntegrationsRef.current
      ? storyIntegrationsRef.current.find((i) => i.blockKey === blockKey)
      : null;

    if (storyIntegration) {
      updateData({
        integrationId: storyIntegration.integration_id,
        storyIntegrationId: storyIntegration.id,
      });
      stopStatusPolling();
    }
  }

  function onRemove() {
    blockProps.setReadOnly(true);
    const newEditorState = deleteDataBlock(blockProps.getEditorState(), block);
    blockProps.setEditorState(newEditorState);
    blockProps.setReadOnly(false);
  }

  function onWrapperClick() {
    const { getEditorState, setEditorState } = blockProps;
    setEditorState(selectBlock(block, getEditorState()));
  }

  function handleAddStoryIntegration(integrationId) {
    const blockKey = block.getKey();
    dispatch(
      addStoryIntegration(
        {
          story_id: blockProps.getModelId(),
          integration_id: integrationId,
        },
        blockKey,
      ),
    );
    startStatusPolling();
  }

  function updateData(newData) {
    const { getEditorState, setEditorState } = blockProps;
    setEditorState(updateDataOfBlock(getEditorState(), block, newData));
  }

  function handleSelectChange(event) {
    if (event.target.value === 'create') {
      window.location = `${StoryChief.dashboardBasePath}/integrations`;
    } else {
      const integrationId = parseInt(event.target.value, 10);

      handleAddStoryIntegration(integrationId);
    }
  }

  function handleCaptureResize(e) {
    const calcHeight = e.target.contentWindow.document.getElementById('form').clientHeight;
    if (block.getData().get('height') !== calcHeight) {
      updateData({
        height: calcHeight,
      });
    }
    e.target.setAttribute('height', calcHeight);
  }

  // Render
  return (
    <div
      className={classNames(
        'block-container block-capture-container',
        { 'is-empty': storyIntegrationId === null && !processing },
        { selected: isSelected },
        { processing },
      )}
    >
      {processing ? (
        <div contentEditable={false} className="placeholder-animated capture-block">
          <div className="control control-1">
            <div className="background-masker label-content" />
            <div className="background-masker label-bottom" />
            <div className="background-masker field-content" />
            <div className="background-masker field-bottom" />
          </div>
          <div className="control control-2">
            <div className="background-masker label-content" />
            <div className="background-masker label-bottom" />
            <div className="background-masker field-content" />
            <div className="background-masker field-bottom" />
          </div>
          <div className="control control-3">
            <div className="background-masker label-content" />
            <div className="background-masker label-bottom" />
            <div className="background-masker field-content" />
            <div className="background-masker field-bottom" />
          </div>
          <div className="control control-4">
            <div className="background-masker button-content" />
            <div className="background-masker button-bottom" />
          </div>
        </div>
      ) : (
        <div>
          {storyIntegrationId === null ? (
            <div contentEditable={false}>
              <button
                type="button"
                onClick={onRemove}
                className="btn-chromeless btn-delete icon-cancel"
                aria-label="Remove"
              />
              <div>
                <h2>
                  Add a <span className="highlight-marker">lead</span> form
                </h2>
                <p className="text-muted">
                  <small>Capture contact info inside your article</small>
                </p>
                <div className="form-group">
                  <select className="form-control" defaultValue={0} onChange={handleSelectChange}>
                    <option value={0} disabled>
                      {' '}
                      -- select a lead form --
                    </option>
                    {getCaptureIntegrations().map((captureIntegration) => (
                      <option key={captureIntegration.id.toString()} value={captureIntegration.id}>
                        {captureIntegration.display_name}
                      </option>
                    ))}
                    <option value="create">+ Create new lead form</option>
                  </select>
                </div>
              </div>
              <BlockCaption {...props} />
            </div>
          ) : (
            <div>
              <div
                contentEditable={false}
                className="media-wrapper embed-wrapper"
                onClick={blockProps.editorEnabled && !isSelected ? onWrapperClick : null}
                onKeyPress={blockProps.editorEnabled && !isSelected ? onWrapperClick : null}
              >
                <button
                  type="button"
                  onClick={onRemove}
                  className="btn-chromeless btn-delete icon-cancel"
                  aria-label="Remove"
                />
                <div className="capture-container">
                  {captureSrc !== null && (
                    <iframe
                      src={captureSrc}
                      frameBorder="0"
                      scrolling="no"
                      sandbox="allow-forms allow-scripts allow-same-origin"
                      width="400"
                      height="340"
                      onLoad={handleCaptureResize}
                      title={`Capture block for ${captureSrc}`}
                    />
                  )}
                </div>
              </div>
              <BlockCaption {...props} />
            </div>
          )}
        </div>
      )}
    </div>
  );
}

CaptureBlock.propTypes = propTypes;
CaptureBlock.defaultProps = defaultProps;

export default CaptureBlock;
