import request from 'superagent';
import convertIframeToResponsive from '../utils/convertIframeToResponsive';
import getIframeSrc from '../../storychief/utils/getIframeSrc';

export default function (url) {
  return new Promise((resolve, reject) => {
    request
      .get('https://oembed.radiopublic.com/oembed')
      .query({ url, format: 'json' })
      .end((err, res) => {
        if (err) {
          reject(err);
        } else {
          const data = res.body;
          data.provider_name = 'RadioPublic';
          data.provider_url = 'https://radiopublic.com';
          data.iframe_src = getIframeSrc(data.html);
          data.html_responsive = convertIframeToResponsive(data.html);
          resolve(data);
        }
      });
  });
}
