import { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { EditorState } from 'draft-js';
import classNames from 'classnames';
import { Icon } from '@iconify-icon/react';
import ToolbarBlock from './ToolbarBlock';
import ToolbarInline from './ToolbarInline';
import ToolbarHyperlink from './ToolbarHyperlink';
import ToolbarHyperlinkPreview from './ToolbarHyperlinkPreview';
import getSelection from '../../utils/getSelection';
import getSelectionRect from '../../utils/getSelectionRect';
import getCurrentBlock from '../../utils/getCurrentBlock';
import {
  BLOCK_BUTTONS,
  INLINE_BUTTONS_RIGHT,
  INLINE_BUTTONS_LEFT,
  HYPERLINK,
  TOOLBAR_ARROW_SIZE,
} from '../../constants/Constants';
import getEntitySelectionState from '../../utils/getEntitySelectionState';
import useAssistant from '@/assistant/hooks/useAssistant';
import usePrevious from '@/storychief/hooks/usePrevious';
import AssistantDropdown from '../../../assistant/components/AssistantDropdown';
import getEditorStateCurrentBlockType from '@/editor/utils/getEditorStateCurrentBlockType';

const propTypes = {
  editorEnabled: PropTypes.bool,
  allowAnchorLinks: PropTypes.bool,
  editorState: PropTypes.shape({
    getSelection: PropTypes.func,
    getCurrentContent: PropTypes.func,
  }).isRequired,
  setEditorState: PropTypes.func.isRequired,
  toggleBlockType: PropTypes.func,
  toggleInlineStyle: PropTypes.func.isRequired,
  inlineButtonsRight: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      style: PropTypes.string,
      icon: PropTypes.string,
      description: PropTypes.string,
      shortcut: PropTypes.string,
    }),
  ),
  inlineButtonsLeft: PropTypes.arrayOf(PropTypes.shape({})),
  blockButtons: PropTypes.arrayOf(PropTypes.shape({})),
  setLink: PropTypes.func,
  focus: PropTypes.func.isRequired,
  showToolbarHyperlink: PropTypes.bool.isRequired,
  handleCommandShowToolbarHyperlink: PropTypes.func,
  preview: PropTypes.bool,
  language: PropTypes.string,
  toolbarsContainer: PropTypes.shape({
    getBoundingClientRect: PropTypes.func,
  }),
};

const defaultProps = {
  editorEnabled: true,
  allowAnchorLinks: true,
  blockButtons: BLOCK_BUTTONS,
  inlineButtonsRight: INLINE_BUTTONS_RIGHT,
  inlineButtonsLeft: INLINE_BUTTONS_LEFT,
  preview: false,
  setLink: () => null,
  toggleBlockType: () => null,
  handleCommandShowToolbarHyperlink: () => null,
  language: undefined,
  toolbarsContainer: null,
};

function Toolbar({
  editorEnabled,
  allowAnchorLinks,
  editorState,
  setEditorState,
  toggleBlockType,
  toggleInlineStyle,
  inlineButtonsRight,
  inlineButtonsLeft,
  blockButtons,
  setLink,
  focus,
  showToolbarHyperlink,
  handleCommandShowToolbarHyperlink,
  preview,
  language,
  toolbarsContainer,
}) {
  const { command } = useAssistant();
  const toolbarNode = useRef();
  const _selectionState = editorState.getSelection();
  const [showURLInput, setShowURLInput] = useState(false);
  const [urlTargetBlank, setUrlTargetBlank] = useState(false);
  const [urlRelNofollow, setUrlRelNofollow] = useState(false);
  const [showEntitlementPopover, setShowEntitlementPopover] = useState(false);
  const [urlInputValue, setUrlInputValue] = useState('');
  const [isPositioned, setIsPositioned] = useState(false);
  const [linkEntity, setLinkEntity] = useState(getLinkEntity());
  const prevShowURLInput = usePrevious(showURLInput);

  const _showToolbarHyperlink = showURLInput || showToolbarHyperlink;

  const _showToolbarHyperlinkPreview = useMemo(
    () => linkEntity && _selectionState.isCollapsed() && !preview,
    [linkEntity, _selectionState.isCollapsed(), preview],
  );

  const className = classNames(
    'editor-toolbar',
    { 'editor-toolbar--linkinput': showURLInput },
    { 'editor-toolbar--isopen': isPositioned },
  );

  const hyperlinkDescription = useMemo(getHyperlinkDescription, [inlineButtonsRight]);

  const currentBlockType = useMemo(
    () => getEditorStateCurrentBlockType(editorState),
    [editorState],
  );

  const isCurrentBlockHeading = useMemo(
    () => currentBlockType.indexOf('header') === 0,
    [currentBlockType],
  );

  useEffect(() => {
    if (!editorEnabled) {
      return;
    }
    if (_selectionState.isCollapsed()) {
      if (showURLInput) {
        setShowURLInput(false);
      }
    }
    setLinkEntity(getLinkEntity());
  }, [editorState, editorEnabled]);

  useEffect(() => {
    if (showToolbarHyperlink) {
      if (_selectionState.isCollapsed()) {
        // hides ToolbarHyperlink when clicking somewhere else
        handleCommandShowToolbarHyperlink(false);
        return;
      }
      if (!_selectionState.getHasFocus()) {
        return;
      }
    }

    if (!editorEnabled || (showURLInput && prevShowURLInput)) {
      return;
    }
    // fixing position of ToolbarHyperlinkPreview
    if (linkEntity) {
      setPositionToolbar();
      return;
    }

    if (_selectionState.isCollapsed()) {
      return;
    }
    // eslint-disable-next-line no-undef
    const nativeSelection = getSelection(window);
    if (!nativeSelection.rangeCount) {
      return;
    }
    const selectionBoundary = getSelectionRect(nativeSelection);
    // Fix intercom seems to take over focus
    if (!selectionBoundary || selectionBoundary.isEmptyline) {
      return;
    }

    setPositionToolbar();
  }, [
    editorState,
    editorEnabled,
    linkEntity,
    showToolbarHyperlink,
    showURLInput,
    prevShowURLInput,
  ]);

  useEffect(() => {
    if (!isShowToolbar() && isPositioned) {
      setIsPositioned(false);
    }
  }, [isShowToolbar()]);

  function getHyperlinkDescription() {
    const button = inlineButtonsRight.find((b) => b.style === HYPERLINK && b.description);

    if (button) {
      return `${button.description}${button.shortcut ? ` (${button.shortcut})` : ''}`;
    }

    return 'Add a link';
  }

  function hasHyperlink() {
    return inlineButtonsRight.some((b) => b.style === HYPERLINK);
  }

  function isShowToolbar() {
    if (command) {
      return false;
    }

    if (
      editorEnabled &&
      (showURLInput || (!_selectionState.isCollapsed() && _selectionState.getHasFocus()))
    ) {
      return true;
    }

    if (!preview && linkEntity && _selectionState.isCollapsed()) {
      return true;
    }

    if (showToolbarHyperlink) {
      return true;
    }

    if (showEntitlementPopover) {
      return true;
    }

    return false;
  }

  function getPositionLeft() {
    const selectionRect = getSelectionRect(getSelection(window));
    const editorContainerWidth = document.querySelector('[data-editor-container]').scrollWidth;
    const toolbarsContainerRect = toolbarsContainer.getBoundingClientRect();
    const toolbarRect = toolbarNode.current.getBoundingClientRect();
    const SPACING = 15;

    const containerWidthOnSide = (editorContainerWidth - toolbarsContainerRect.width) / 2;
    const maxLeft =
      toolbarsContainerRect.width + containerWidthOnSide - toolbarRect.width - SPACING;
    const minLeft = -containerWidthOnSide + SPACING;

    const leftWithoutOffset =
      selectionRect.left +
      selectionRect.width * 0.5 -
      toolbarsContainerRect.x -
      toolbarRect.width * 0.5;
    const leftWithOffset = Math.max(minLeft, Math.min(maxLeft, leftWithoutOffset));

    return {
      left: leftWithOffset,
      offset: leftWithoutOffset - leftWithOffset,
    };
  }

  function getPositionTop() {
    const selectionBoundary = getSelectionRect(getSelection(window));
    const toolbarsContainerRect = toolbarsContainer.getBoundingClientRect();
    const toolbarRect = toolbarNode.current.getBoundingClientRect();

    return (
      selectionBoundary.top - toolbarsContainerRect.y - toolbarRect.height - TOOLBAR_ARROW_SIZE
    );
  }

  function setPositionToolbar() {
    if (!toolbarNode.current || !toolbarsContainer) {
      return;
    }

    // prevent from moving forward if there is no selection
    if (window.getSelection().rangeCount < 1) return;

    const leftData = getPositionLeft(toolbarsContainer);

    const arrowClipDomNode = toolbarNode.current.querySelector('.arrow-clip');
    arrowClipDomNode.style.marginLeft = `${leftData.offset}px`;

    toolbarNode.current.style.top = `${getPositionTop()}px`;
    toolbarNode.current.style.left = `${leftData.left}px`;
    setIsPositioned(true);
  }

  function getLinkEntity() {
    const currentBlock = getCurrentBlock(editorState);
    if (_selectionState && currentBlock) {
      const entityKey = currentBlock.getEntityAt(_selectionState.getStartOffset());

      if (entityKey) {
        const contentState = editorState.getCurrentContent();
        const entity = contentState.getEntity(entityKey);

        if (entity.getType() === 'LINK') {
          return { entity, entityKey };
        }
      }
    }
    return null;
  }

  async function handleSaveLinkInput(url, target, follow) {
    // Focus on editor before all other operations (issue since React 17).
    await focus();
    setLink(url, target, follow);
    setShowURLInput(false);
    setUrlInputValue('');
    handleCommandShowToolbarHyperlink(false);
    setPositionToolbar();
  }

  function handleLinkInput(e, direct = false) {
    if (!direct) {
      e.preventDefault();
    }
    const contentState = editorState.getCurrentContent();
    if (_selectionState.isCollapsed()) {
      focus();
      return;
    }
    // select linkEntity and update state Toolbar
    if (linkEntity) {
      const newSelectionState = getEntitySelectionState(
        contentState,
        _selectionState,
        linkEntity.entityKey,
      );
      const newEditorState = EditorState.forceSelection(editorState, newSelectionState);
      setEditorState(newEditorState);
      const { url, targetBlank, relNofollow } = linkEntity.entity.getData();
      setShowURLInput(true);
      setUrlInputValue(url);
      setUrlTargetBlank(targetBlank);
      setUrlRelNofollow(relNofollow);
      return;
    }
    setShowURLInput(true);
  }

  function handleTargetCheckboxChange() {
    setUrlTargetBlank(!urlTargetBlank);
  }

  function handleRelCheckboxChange() {
    setUrlRelNofollow(!urlRelNofollow);
  }

  function hideLinkInput(e) {
    e.preventDefault();
    setShowURLInput(false);
    setUrlInputValue('');
    focus();
  }

  function handleRemoveLink() {
    setUrlInputValue('');
    setUrlTargetBlank(false);
    setUrlRelNofollow(false);
  }

  function onKeyDown(e, url, target, follow) {
    if (e.which === 13) {
      e.preventDefault();
      handleSaveLinkInput(url, target, follow);
    } else if (e.which === 27) {
      hideLinkInput(e);
    }
  }

  function onChange(e) {
    setUrlInputValue(e.target.value);
  }

  function showUrlInput(_urlInputValue) {
    setShowURLInput(true);
    setUrlInputValue(_urlInputValue);
  }

  function initializeLinkAttributes() {
    if (linkEntity) {
      setUrlTargetBlank(linkEntity.entity.getData().targetBlank);
      setUrlRelNofollow(linkEntity.entity.getData().relNofollow);
    }
  }

  function handleOnEntitlementPopoverShow() {
    setShowEntitlementPopover(true);
  }

  function handleOnEntitlementPopoverHide() {
    setShowEntitlementPopover(false);
  }

  // Render
  if (!isShowToolbar()) {
    return null;
  }

  if (_showToolbarHyperlink) {
    return (
      <div ref={toolbarNode} className={className}>
        <div>
          <ToolbarHyperlink
            onKeyDown={onKeyDown}
            onChangeUrl={onChange}
            urlInputValue={urlInputValue}
            urlTargetBlank={urlTargetBlank}
            urlRelNofollow={urlRelNofollow}
            handleTargetCheckboxChange={handleTargetCheckboxChange}
            handleRelCheckboxChange={handleRelCheckboxChange}
            handleSaveLinkInput={handleSaveLinkInput}
            getEditorState={() => editorState}
            setEditorState={setEditorState}
            handleRemoveLink={handleRemoveLink}
            allowAnchorLinks={allowAnchorLinks}
            initializeLinkAttributes={initializeLinkAttributes}
          />
        </div>
        <div className="arrow-clip">
          <div className="arrow" />
        </div>
      </div>
    );
  }

  if (_showToolbarHyperlinkPreview) {
    return (
      <div ref={toolbarNode} className={className}>
        <div>
          <ToolbarHyperlinkPreview
            setEditorState={setEditorState}
            getEditorState={() => editorState}
            linkEntity={linkEntity}
            showUrlInput={showUrlInput}
            handleRemoveLink={handleRemoveLink}
          />
        </div>
        <div className="arrow-clip">
          <div className="arrow" />
        </div>
      </div>
    );
  }

  return (
    <div ref={toolbarNode} className={className}>
      <div>
        {!preview && (
          <div className="RichEditor-controls">
            <AssistantDropdown
              getEditorState={() => editorState}
              setEditorState={setEditorState}
              disabled={!editorEnabled}
              includeMenu={false}
              language={language}
              className="RichEditor-styleButton"
              hasSelectedText
              onEntitlementPopoverShow={handleOnEntitlementPopoverShow}
              onEntitlementPopoverHide={handleOnEntitlementPopoverHide}
            />
            <div className="RichEditor-controls-separator" />
          </div>
        )}

        {/* FIRST SECTION: B, I, U, s, link */}
        <div className="RichEditor-controls">
          {inlineButtonsRight.length > 0 ? (
            <ToolbarInline
              disabled={!editorEnabled || isCurrentBlockHeading}
              getEditorState={() => editorState}
              onToggle={toggleInlineStyle}
              buttons={inlineButtonsRight}
            />
          ) : null}
          {hasHyperlink() && (
            <button
              className={`RichEditor-styleButton btn btn-chromeless RichEditor-linkButton-link hint--top ${
                linkEntity ? 'RichEditor-activeButton' : ''
              }`}
              type="button"
              onMouseDown={handleLinkInput}
              aria-label={hyperlinkDescription}
              title={hyperlinkDescription}
              disabled={!editorEnabled}
            >
              <Icon icon="fa:link" height="13" inline className="mx-[3px]" />
            </button>
          )}
        </div>

        {/* SECOND SECTION: H1, H2, H3, quotes, ul, ol */}
        {blockButtons.length > 0 ? (
          <span>
            <div className="RichEditor-controls-separator" />
            <ToolbarBlock
              disabled={!editorEnabled}
              getEditorState={() => editorState}
              onToggle={toggleBlockType}
              buttons={blockButtons}
            />
          </span>
        ) : null}

        {/* THIRD SECTION: add comment */}
        {inlineButtonsLeft.length > 0 ? (
          <span>
            {blockButtons.length > 0 && <div className="RichEditor-controls-separator" />}
            <ToolbarInline
              disabled={!editorEnabled}
              getEditorState={() => editorState}
              onToggle={toggleInlineStyle}
              buttons={inlineButtonsLeft}
            />
          </span>
        ) : null}
      </div>
      <div className="arrow-clip">
        <div className="arrow" />
      </div>
    </div>
  );
}

Toolbar.propTypes = propTypes;
Toolbar.defaultProps = defaultProps;

export default Toolbar;
