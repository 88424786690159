import PropTypes from 'prop-types';
import { OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';
import { Picker } from 'emoji-mart';
import { EditorState, Modifier } from 'draft-js';
import { Icon } from '@iconify-icon/react';
import ToolbarInline from '@/editor/components/toolbar/ToolbarInline';
import ToolbarBlock from '@/editor/components/toolbar/ToolbarBlock';
import AssistantDropdown from '@/assistant/components/AssistantDropdown';

const propTypes = {
  disabled: PropTypes.bool.isRequired,
  isPrivate: PropTypes.bool,
  setEditorState: PropTypes.func.isRequired,
  getEditorState: PropTypes.func.isRequired,
  onToggleBlockStyle: PropTypes.func.isRequired,
  onToggleInlineStyle: PropTypes.func.isRequired,
  plugins: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  blockButtons: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  inlineButtons: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setReadOnly: PropTypes.func.isRequired,
  focus: PropTypes.func.isRequired,
  emojiPicker: PropTypes.bool.isRequired,
  onEmojiPickerOpen: PropTypes.func,
  onEmojiPickerClose: PropTypes.func,
};

const defaultProps = {
  isPrivate: false,
  onEmojiPickerOpen: () => {},
  onEmojiPickerClose: () => {},
};

function RichEditorToolbar({
  disabled,
  isPrivate,
  setEditorState,
  getEditorState,
  onToggleBlockStyle,
  onToggleInlineStyle,
  plugins,
  blockButtons,
  inlineButtons,
  setReadOnly,
  focus,
  emojiPicker,
  onEmojiPickerOpen,
  onEmojiPickerClose,
}) {
  function addEmoji(emoji) {
    const currentContent = getEditorState().getCurrentContent();
    const selection = getEditorState().getSelection();
    let textWithEntity = null;
    if (selection.isCollapsed()) {
      textWithEntity = Modifier.insertText(currentContent, selection, emoji.native);
    } else {
      textWithEntity = Modifier.replaceText(currentContent, selection, emoji.native);
    }
    const newEditorState = EditorState.push(getEditorState(), textWithEntity, 'insert-characters');
    setEditorState(newEditorState, focus);
  }

  const popover = (
    <Popover className="popover--emoji" id="popover-emoji" title="">
      <Picker showPreview={false} onClick={addEmoji} title="" />
    </Popover>
  );

  return (
    <div className="rich-editor-toolbar">
      <div className="flex-grow">
        {inlineButtons.length > 0 ? (
          <ToolbarInline
            disabled={disabled}
            getEditorState={getEditorState}
            onToggle={onToggleInlineStyle}
            buttons={inlineButtons}
          />
        ) : null}

        {blockButtons.length > 0 ? (
          <ToolbarBlock
            disabled={disabled}
            getEditorState={getEditorState}
            onToggle={onToggleBlockStyle}
            buttons={blockButtons}
            showTooltips
          />
        ) : null}
        {plugins.map((plugin) => {
          const Button = plugin.buttonComponent;
          return (
            <Button
              key={plugin.type}
              disabled={disabled}
              isPrivate={isPrivate}
              getEditorState={getEditorState}
              setEditorState={setEditorState}
              setReadOnly={setReadOnly}
              focus={focus}
            />
          );
        })}
        {emojiPicker && !disabled && (
          <OverlayTrigger
            trigger="click"
            rootClose
            placement="bottom"
            overlay={popover}
            onClick={onEmojiPickerOpen}
            onExit={onEmojiPickerClose}
          >
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="tooltip-emoji">Insert an emoji</Tooltip>}
            >
              <button type="button" className="btn btn-chromeless">
                <Icon icon="fa:smile-o" height="13" inline className="mx-[3px]" />
              </button>
            </OverlayTrigger>
          </OverlayTrigger>
        )}
      </div>
      {!disabled && (
        <AssistantDropdown
          pullRight
          getEditorState={getEditorState}
          setEditorState={setEditorState}
          hasSelectedText={!getEditorState().getSelection().isCollapsed()}
        />
      )}
    </div>
  );
}

RichEditorToolbar.propTypes = propTypes;
RichEditorToolbar.defaultProps = defaultProps;

export default RichEditorToolbar;
