import PropTypes from 'prop-types';
import { Fragment, useMemo } from 'react';
import { EditorState } from 'draft-js';
import * as Popover from '@radix-ui/react-popover';
import { MenuItem } from 'react-bootstrap';
import classNames from 'classnames';
import useAssistant from '@/assistant/hooks/useAssistant';
import {
  COMMANDS_OF_TYPE_WRITE,
  COMMANDS_OF_TYPE_EDIT,
  COMMAND_IDENTIFIERS,
} from '@/assistant/constants/Constants';
import getRecommendedDraftCommandsByType from '@/assistant/utils/getRecommendedDraftCommandsByType';
import Truncate from '@/storychief/components/Truncate';
import useBrandVoices from '@/assistant/hooks/useBrandVoices';

const propTypes = {
  getEditorState: PropTypes.func,
  setEditorState: PropTypes.func,
  pullRight: PropTypes.bool,
};

const defaultProps = {
  getEditorState: undefined,
  setEditorState: undefined,
  pullRight: false,
};

function AssistantDropdownMenuItems({ getEditorState, setEditorState, pullRight }) {
  const { subject, initCommand } = useAssistant();
  const { data: { brandVoices } = { data: { brandVoices: [] } } } = useBrandVoices();

  const commandsSections = useMemo(() => {
    const _draft_commands = {
      title: 'Draft with AI',
      commands: getRecommendedDraftCommandsByType(subject),
    };
    const _edit_commands = {
      title: subject.hasSelectedText ? 'Edit selection' : 'Edit content',
      commands: COMMANDS_OF_TYPE_EDIT,
    };
    const _write_commands = {
      title: 'Write with AI',
      commands: COMMANDS_OF_TYPE_WRITE,
    };

    if (subject?.isEmpty === true) {
      // When there is no content, hide commands such as "expand", "change tone"
      return [_draft_commands];
    }

    if (subject.hasSelectedText) {
      // Give priority to commands such as "expand", "change tone"
      return [_write_commands, _edit_commands, _draft_commands];
    }

    return [_draft_commands, _edit_commands, _write_commands];
  }, [subject]);

  function handleOnFocus(e) {
    if (e) {
      e.preventDefault();
    }
    if (getEditorState && setEditorState) {
      // Fix to prevent editor from loosing selection
      const editorState = getEditorState();
      const newEditorState = EditorState.forceSelection(
        getEditorState(),
        editorState.getSelection(),
      );
      setEditorState(newEditorState);
    }
  }

  function renderDropdown(_command) {
    return (
      <>
        <li role="presentation" className="assistant-dropdown-menu-item">
          <Popover.Root>
            <Popover.Trigger className="btn btn-chromeless">
              <img src={_command.icon} alt={_command.title} />
              {_command.title} <i className="icon-angle-right assistant-dropdown-item-icon" />
            </Popover.Trigger>

            <Popover.Content
              side="right"
              align="start"
              sideOffset={5}
              alignOffset={-8}
              collisionPadding={15}
              onOpenAutoFocus={(e) => e.preventDefault()}
            >
              <ul
                className={classNames(`assistant-dropdown-sub-menu-item dropdown-menu`, {
                  'dropdown-menu-right': pullRight,
                })}
              >
                {_command.items.map((_menuItem) => (
                  <li key={`${_command.identifier}-${_menuItem.title}`}>
                    <button
                      type="button"
                      className="btn btn-chromeless"
                      onClick={() => initCommand(_command, _menuItem.value)}
                    >
                      {_menuItem.title}
                    </button>
                  </li>
                ))}
                {_command.identifier === COMMAND_IDENTIFIERS.CHANGE_TONE &&
                  brandVoices &&
                  brandVoices.length > 0 && (
                    <>
                      <MenuItem divider />
                      {brandVoices.map((brandVoice) => (
                        <li key={`assistant-${_command.identifier}-brand-voice-${brandVoice.id}`}>
                          <button
                            type="button"
                            role="menuitem"
                            className="btn btn-chromeless"
                            style={{ maxWidth: '100%' }}
                            onClick={() =>
                              initCommand(_command, {
                                context: {
                                  tone: brandVoice.characteristics,
                                },
                              })
                            }
                            key={`${_command.identifier}-brand-voice-${brandVoice.id}`}
                          >
                            <span className="d-block">
                              <Truncate lines={1}>{brandVoice.name}</Truncate>
                            </span>
                            <small className="text-muted small d-block">
                              <Truncate lines={2}>{brandVoice.characteristics}</Truncate>
                            </small>
                          </button>
                        </li>
                      ))}
                    </>
                  )}
              </ul>
            </Popover.Content>
          </Popover.Root>
        </li>
      </>
    );
  }

  function renderMenuItem(_command) {
    return (
      <MenuItem
        key={_command.identifier}
        data-testid={`assistant-dropdown-menu-item-${_command.identifier}`}
        onMouseDown={handleOnFocus}
        className="assistant-dropdown-menu-item"
        onClick={() => (!_command.dropdown ? initCommand(_command) : null)}
      >
        <img src={_command.icon} alt={_command.title} />
        {_command.title}
      </MenuItem>
    );
  }

  return (
    <>
      {commandsSections
        .filter((_commandsSection) => _commandsSection.commands.length > 0)
        .map((_commandsSection) => (
          <Fragment key={_commandsSection.title}>
            <MenuItem header className="assistant-dropdown-header">
              {_commandsSection.title}
            </MenuItem>
            {_commandsSection.commands.map((_command) => (
              <Fragment key={_command.identifier}>
                {_command.items && renderDropdown(_command)}
                {!_command.items && renderMenuItem(_command)}
              </Fragment>
            ))}
          </Fragment>
        ))}
    </>
  );
}

AssistantDropdownMenuItems.propTypes = propTypes;
AssistantDropdownMenuItems.defaultProps = defaultProps;

export default AssistantDropdownMenuItems;
