import request from 'superagent';
import { ADD_STORY_INTEGRATION } from './types';
import processingIntegration from '../../integrations/actions/processingIntegration';
import updateStoryIntegrationStatusError from './updateStoryIntegrationStatusError';
import StoryChief from '../../storychief/index';

function addStoryIntegrationAsync(storyIntegration) {
  return {
    type: ADD_STORY_INTEGRATION,
    payload: storyIntegration,
  };
}

export default function addStoryIntegration(storyIntegration, blockKey = null) {
  return (dispatch) => {
    dispatch(processingIntegration(storyIntegration.integration_id));
    request
      .put(`${StoryChief.apiBasePath}/stories/${storyIntegration.story_id}/integrations`)
      .set('Accept', 'application/json')
      .set('X-Requested-With', 'XMLHttpRequest')
      .set('X-CSRF-TOKEN', StoryChief.csrfToken)
      .set('X-Socket-ID', window.Echo.socketId())
      .send({
        ...storyIntegration,
      })
      .end((err, res) => {
        if (res && res.ok) {
          const newStoryIntegration = res.body;
          dispatch(addStoryIntegrationAsync({ ...newStoryIntegration, blockKey }));
        } else {
          dispatch(updateStoryIntegrationStatusError(res, err));
        }
      });
  };
}
