/* eslint-disable no-useless-escape, max-len */
export const EMBED_REGEX = {
  // rawIframes are checked first to avoid accidental matching oEmbed versions below
  rawIframe: /^<iframe[^>]*src=["|'](https:\/\/[^"|']*)["|'][^>]*><\/iframe>$/i,
  soundcloud: /(soundcloud.com)\/[a-zA-Z0-9-_]+\/[a-zA-Z0-9-_]+/gi,
  mixcloud: /(www.mixcloud.com)\/[a-zA-Z0-9-_]+\/[a-zA-Z0-9-_]+/gi,
  codepen: /codepen.io\/([A-Za-z0-9_]+)\/pen\/([A-Za-z0-9_]+)/gi,
  giphy: /https?:\/\/(.+)?(giphy.com|gph.is)\/.*/,
  ideone: /ideone.com\/[a-zA-Z0-9]{6}/gi,
  jsbin: /jsbin.com\/[a-zA-Z0-9_]+\/[0-9_]+/gi,
  jsfiddle: /jsfiddle.net\/[a-zA-Z0-9_]+\/[a-zA-Z0-9_\/]+/gi,
  plunker: /plnkr.co\/edit\/[a-zA-Z0-9\?=]+/gi,
  flickr: /flickr.com\/[a-z]+\/[a-zA-Z@_$!\d\-\]+\/[\d]+/gi,
  applePodcasts: /podcasts.apple.com\/[a-z]+\/[a-zA-Z@_$!\d\-\]+\/[\d]+/gi,
  instagram: /instagram.com\/(?:p|tv|reel)\/[a-zA-Z0-9_\/\?\-\=]+/gi,
  slideshare: /slideshare.net\/[a-zA-Z0-9_-]*\/[a-zA-Z0-9_-]*/gi,
  github: /[^\.]github.com\/([\w\.\-]+)\/([\w\.\-]+)/gi,
  cloudinary: /res\.cloudinary\.com\/.*\/video\/upload\/.*\.mp4$/gi,
  dailymotion: /dailymotion.com\/video\/[a-zA-Z0-9-_]+/gi,
  liveleak: /liveleak.com\/view\?i=[a-zA-Z0-9_]+/gi,
  ted: /ted.com\/talks\/[a-zA-Z0-9_]+/gi,
  ustream: /ustream.tv\/[a-z\/0-9]*/gi,
  vimeo:
    /https?:\/\/(?:www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|)(\d+)(?:$|\/|\?)*/gi,
  vine: /vine.co\/v\/[a-zA-Z0-9]+/gi,
  youtube:
    /https?:\/\/(?:[0-9A-Z-]+\.)?(?:youtu\.be\/|youtube\.com(?:\/embed\/|\/v\/|\/watch\?v=|\/ytscreeningroom\?v=|\/feeds\/api\/videos\/|\/user\S*[^\w\-\s]|\S*[^\w\-\s]))([\w\-]{11})[?=&+%\w-]*/gi,
  twitter: /https:\/\/(twitter|x)\.com\/\w+\/\w+\/\d+/gi,
  wistia: /https?:\/\/(.+)?(wistia.com|wi.st)\/.*/,
  gmaps: /^https?\:\/\/(www\.|maps\.)?google(\.[a-z]+){1,2}\/maps\/?/,
  hubspotCta:
    /(cta-redirect.hubspot.com\/cta\/redirect\/[a-zA-Z0-9_]+|cta-service-cms2.hubspot.com\/web-interactives\/public\/v1\/track\/[a-zA-Z0-9_]+)/gi,
  typeform: /[.]typeform.com\/to\/[a-zA-Z0-9]+/gi,
  vooplayer: /[.]cdn.vooplayer.com|[.]vplay.media\/publish\/[a-zA-Z0-9]+/gi, // Renamed to spotlightr, deprecated?
  spotlightr: /[.]cdn.spotlightr.com|[.]vplay.media\/watch\/[a-zA-Z0-9]+/gi,
  airtable: /https:\/\/airtable.com\/[a-zA-Z0-9]+/gi,
  adways: /play.adways.com\/[a-zA-Z0-9_-]+\/([a-zA-Z0-9]+)/gi,
  pippa: /player.pippa.io/gi,
  anchorfm: /anchor.fm\/.*\/\episodes\/.*\/a-.*/gi,
  libsyn: /[.]libsyn.com/gi,
  chartblocks: /public.chartblocks.com\/c\/.*/,
  codesandbox: /codesandbox.io\/s\/.*/,
  radiopublic: /radiopublic.com\/.*/,
  upscribe: /upscri.be\/.*/,
  simplecast: /[.]simplecast.com\/episodes\/.*/,
  issuu: /issuu.com\/.*\/docs\/.*/,
  spotify: /[.]spotify.com/gi,
  infogram: /infogram.com\/.*/,
  whooshkaa: /(?:player|webplayer).whooshkaa.com\/(?:episode|show)\/.*/,
  thinglink: /https?:\/\/(.+)?thinglink.com\/.*/,
  buzzsprout: /https?:\/\/(.+)?buzzsprout.com\/[a-zA-Z0-9_-]*\/[a-zA-Z0-9_-]*/gi,
  podcastco: /https?:\/\/pod.co\/[a-zA-Z0-9_-]+\/?[a-zA-Z0-9_-]*/gi,
  githubgist: /gist.github.com\/[a-zA-Z0-9_-]+\/([a-zA-Z0-9]+)/gi,
  imgur: /imgur.com\/[a-zA-Z0-9_-]+\/([a-zA-Z0-9]+)/gi,
  facebookPost: /https?:\/\/www.facebook.com\/.*\/(?:posts|photos)\/[a-zA-Z0-9]+/gi,
  facebookVideo: /https?:\/\/www.facebook.com\/.*\/(?:videos)\/[a-zA-Z0-9]+/gi,
  pastebin: /https?:\/\/pastebin.com\/embed\/[a-zA-Z0-9]+/gi,
  gforms: /docs.google.com\/forms\/d\/e\/([a-zA-Z0-9]+)/gi,
  podigee: /[.]podigee.io\/[a-zA-Z0-9]+/gi,
  transistor: /share.transistor.fm\/s\/[a-zA-Z0-9]+/gi,
  captivate: /player.captivate.fm/gi,
  datawrapper: /https?:\/\/(?:www.)?datawrapper.(?:de|dwcdn.net)\/.*/gi,
  kbsMedia: /https?:\/\/(?:accept-)?media.kbs-frb.be\/[a-zA-Z]*\/media\/.*/gi,
  tiktok: /https?:\/\/(www.)?tiktok.com\/@[a-zA-Z_\-.]*\/video\/.*/gi,
  loom: /https?:\/\/(www.)?loom.com\/share\/.*/gi,
  hubspotForm: /[.]hsforms.com/gi,
  activeCampaignForm: /[.](?:activehosted.com|12all.com)\/[a-zA-Z0-9]\/[a-zA-Z0-9]+/gi,
  embedsocialForm: /forms.mk\/([a-zA-Z0-9]+)/gi,
  canva: /https:\/\/www.canva.com\/design\/*/gi,
  arcadeDemo: /https:\/\/app.arcade.software\/share\/*/,
  springCast: /https:\/\/app.springcast.fm\/player\/*/,
};
/* eslint-enable */
export default {
  EMBED_REGEX,
};
