import request from 'superagent';
import StoryChief from '@/storychief';

export default function (url) {
  return new Promise((resolve, reject) => {
    request
      .put(`${StoryChief.dashboardBasePath}/integrations/embed/oembed`)
      .set('Accept', 'application/json')
      .set('X-Requested-With', 'XMLHttpRequest')
      .set('X-CSRF-TOKEN', StoryChief.csrfToken)
      .send({
        url,
        provider: 'kbsMedia',
      })
      .end((err, res) => {
        if (err) {
          reject(err);
        } else {
          const data = res.body;
          data.html = `<img src="${data.url}" width="100%" style="max-width:${data.width}px" alt="${data.title}">`;
          data.thumbnail_url = data.url;

          resolve(data);
        }
      });
  });
}
