import { EditorState, SelectionState, Modifier } from 'draft-js';

function deleteDataBlock(editorState, block) {
  const content = editorState.getCurrentContent();

  const targetRange = new SelectionState({
    anchorKey: block.key,
    anchorOffset: 0,
    focusKey: block.key,
    focusOffset: block.getLength(),
  });

  const withoutTeX = Modifier.removeRange(content, targetRange, 'backward');
  const resetBlock = Modifier.setBlockType(withoutTeX, withoutTeX.getSelectionAfter(), 'unstyled');

  const newState = EditorState.push(editorState, resetBlock, 'remove-range');
  return EditorState.forceSelection(newState, resetBlock.getSelectionAfter());
}

export default deleteDataBlock;
