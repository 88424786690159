import request from 'superagent';
import getIframeSrc from '../../storychief/utils/getIframeSrc';

export default function (url) {
  return new Promise((resolve, reject) => {
    request
      .get(`https://embed.podigee.com/oembed?url=${url}&type=iframe`)
      .set('Accept', 'application/json')
      .end((err, res) => {
        if (err) {
          reject(err);
        } else {
          const data = res.body;
          const html = data.html.replace('?context=external', '');
          data.width = 700;
          data.html_responsive = html;
          data.iframe_src = getIframeSrc(html);
          resolve(data);
        }
      });
  });
}
