import { ChangeEvent, JSX, MouseEvent, useMemo } from 'react';
import { gql, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { ControlLabel, FormControl, FormGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Icon } from '@iconify-icon/react';
import useAssistant from '@/assistant/hooks/useAssistant';
// The dependency cycle is caused by <TextEditor> importing assistant components and those components also importing <TextEditor>.
// The issue is mitigated by disabling the assistant on <TextEditor> inside the assistant components.
// eslint-disable-next-line import/no-cycle
import TextEditor from '@/storychief/components/editors/TextEditor';
import Tabs from '@/storychief/components/Tabs';
import CommandAddContentPiece from '@/assistant/components/Command/CommandAddContentPiece';
import appendUrlProtocol from '@/storychief/utils/appendUrlProtocol';
import Label from '@/storychief/components/Label';
import getTitlePlainText from '@/editor/utils/getTitlePlainText';
import Button, { ButtonSize } from '@/storychief/components/Button';

const GET_URL_TEXT_MUTATION = gql`
  mutation GetUrlText($url: String!) {
    getUrlText(url: $url)
  }
`;

type ContentPiece = {
  __typename: string;
  id: string;
  title: string;
  content: string;
  edit_url: string;
};

type CommandFormContextAssetsType = {
  size?: ButtonSize;
};

function CommandFormContextAssets({ size = 'sm' }: CommandFormContextAssetsType): JSX.Element {
  const {
    command,
    loading,
    assetSource,
    setAssetSource,
    assetTextValue,
    setAssetTextValue,
    assetUrl,
    setAssetUrl,
    assetUrlValue,
    setAssetUrlValue,
    assetContentPieces,
    setAssetContentPieces,
    subject,
  } = useAssistant();

  const includeContentPieces = useMemo(
    () => command.contextAssets?.includeContentPieces,
    [command],
  );

  // Mutations
  const [getUrlText, { loading: isLoadingUrl }] = useMutation(GET_URL_TEXT_MUTATION);

  // Functions
  function handleOnUrlChange(event: ChangeEvent<HTMLInputElement>) {
    setAssetUrl(event.target.value);
  }

  function handleOnTextValueChange(value: string) {
    setAssetTextValue(value);
  }

  function handleOnLoadUrl() {
    if (!assetUrl) return;

    getUrlText({
      variables: {
        url: appendUrlProtocol(assetUrl),
      },
    })
      .then(({ data } = {}) => setAssetUrlValue(data.getUrlText || ''))
      .catch(() => {
        toast.error(
          'Something went wrong while fetching your URL. Please try again or copy and paste the text manually.',
          {
            toastId: 'generateTopicsPipelineIdError',
            autoClose: 5000,
          },
        );
      });
  }

  function getAnnexes(): string[] {
    const items = [];

    if (subject?.model?.brief) {
      items.push('Editorial Brief');
    }

    if (subject?.model?.keywords?.length) {
      items.push('SEO Keywords');
    }

    return items;
  }

  function handleOnAddContentPieces(newContentPieces: ContentPiece[]): void {
    setAssetContentPieces((prev) => [...prev, ...newContentPieces]);
  }

  function handOnRemoveContentPiece(contentPiece: ContentPiece): void {
    return (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();

      setAssetContentPieces((prev) => prev.filter((cp) => cp.id !== contentPiece.id));
    };
  }

  const annexes = getAnnexes();

  return (
    <div className="command-form-context-assets" data-testid="command-form-context-assets">
      <FormGroup controlId="enrichment" bsSize={size}>
        <ControlLabel bsClass="control-label" className="m-0">
          Context {!command.contextAssets.required && '(optional)'}
          <OverlayTrigger
            placement="right"
            overlay={
              <Tooltip id="help-description">
                Enhance the assistant&apos;s output by adding drafts, ideas, meeting notes, ...
              </Tooltip>
            }
          >
            <Icon icon="fa:question-circle" inline className="text-muted ml-1" width="11" />
          </OverlayTrigger>
        </ControlLabel>
        <div className="sc-well sc-well--outline well--tablist m-0 bg-white p-2">
          <Tabs
            id="source-type-tab"
            value={assetSource}
            onValueChange={(_source) => setAssetSource(_source)}
          >
            <Tabs.List classNameNav="nav nav-tabs nav-tabs--minimal small mb-2">
              <Tabs.ListItem value="text">Text</Tabs.ListItem>
              <Tabs.ListItem value="url">URL</Tabs.ListItem>
              {includeContentPieces && <Tabs.ListItem value="contentPieces">Article</Tabs.ListItem>}
            </Tabs.List>

            <Tabs.Content value="text" id="tab-content-text">
              <TextEditor
                className="text-editor--chromeless"
                allowNewLines
                placeholder="Add content like ideas, meeting notes, ..."
                readOnly={loading}
                content={assetTextValue}
                formControl
                formControlAutoHeight
                editorMaxSize="sm"
                onContentChange={handleOnTextValueChange}
                assistant={false}
                debounceContentChange={false}
              />
            </Tabs.Content>
            <Tabs.Content value="url" id="tab-content-url">
              <>
                {assetUrl.length && assetUrlValue.length ? (
                  <ul className="mb-0 mt-2 list-none border p-0 text-sm">
                    <li className="flex justify-between gap-3 border-0 border-b border-solid border-wild-sand px-0 py-1 last:border-b-0">
                      <a
                        data-testid="assistant-context-assets-url-href"
                        href={assetUrl}
                        target="_blank"
                        className="text-word-break"
                        rel="noopener noreferrer"
                      >
                        {assetUrl}
                      </a>
                      <Button
                        type="button"
                        variant="link"
                        size="xs"
                        aria-label="Remove"
                        onClick={() => {
                          setAssetSource('url');
                          setAssetUrl('');
                          setAssetUrlValue('');
                        }}
                      >
                        <em className="icon-cancel text-xs" />
                      </Button>
                    </li>
                  </ul>
                ) : (
                  <FormGroup className="form-group--no-margin" controlId="url" bsSize={size}>
                    <div className="flex flex-row gap-2">
                      <FormControl
                        disabled={isLoadingUrl}
                        id="url"
                        data-testid="assistant-context-assets-url-input"
                        type="url"
                        autoFocus
                        placeholder="Add a webpage as context"
                        componentClass="input"
                        value={assetUrl}
                        onChange={handleOnUrlChange}
                        onKeyDown={({ keyCode }) => {
                          if (keyCode === 13) handleOnLoadUrl();
                        }}
                      />
                      <Button
                        size={size}
                        disabled={isLoadingUrl}
                        className="btn-default-light"
                        onClick={handleOnLoadUrl}
                      >
                        Add
                        {isLoadingUrl && (
                          <span className="ml-2">
                            <span className="animated-spin icon-spin1 inline-block text-xs" />
                          </span>
                        )}
                      </Button>
                    </div>
                  </FormGroup>
                )}
              </>
            </Tabs.Content>
            {includeContentPieces && (
              <Tabs.Content value="contentPieces" id="tab-content-story">
                {assetContentPieces.length > 0 && (
                  <ul className="my-2 list-none border p-0 text-sm">
                    {assetContentPieces.map((contentPiece) => (
                      <li
                        key={contentPiece.id}
                        className="flex justify-between gap-3 border-0 border-b border-solid border-wild-sand px-0 py-1 last:border-b-0"
                      >
                        <a
                          href={contentPiece.edit_url}
                          target="_blank"
                          className="text-word-break"
                          rel="noopener noreferrer"
                        >
                          {getTitlePlainText(contentPiece.title)}
                        </a>
                        <Button
                          type="button"
                          variant="link"
                          size="xs"
                          aria-label="Remove"
                          data-testid="command-context-remove-content-piece"
                          onClick={handOnRemoveContentPiece(contentPiece)}
                        >
                          <em className="icon-cancel" />
                        </Button>
                      </li>
                    ))}
                  </ul>
                )}

                <CommandAddContentPiece
                  size={size}
                  contentPieces={assetContentPieces}
                  handleOnAdd={handleOnAddContentPieces}
                />
              </Tabs.Content>
            )}
          </Tabs>
          {!!annexes.length && (
            <div className="align-items flex gap-2">
              <div className="text-muted">Annexes</div>
              {annexes.map((title) => (
                <Label key={title}>{title}</Label>
              ))}
            </div>
          )}
        </div>
      </FormGroup>
    </div>
  );
}

export default CommandFormContextAssets;
