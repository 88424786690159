import { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { throttle } from 'lodash-es';
import classNames from 'classnames';
import {
  Editor,
  EditorState,
  Modifier,
  RichUtils,
  convertFromRaw,
  convertToRaw,
  KeyBindingUtil,
  getDefaultKeyBinding,
  SelectionState,
} from 'draft-js';
import urlRegex from 'url-regex';
import { convertFromHTML } from 'draft-convert';
import embed from '@/editor/plugins/embed/plugin';
import image from '@/editor/plugins/image/plugin';
import video from '@/editor/plugins/video/plugin';
import divider from '@/editor/plugins/divider';
import columnDivider from '@/editor/plugins/columnDivider';
import {
  Block,
  BLOCK_BUTTONS as ALL_BLOCK_BUTTONS,
  CUSTOM_BLOCK_STYLES,
  ENTITY_TYPES,
  INLINE_BUTTONS as ALL_INLINE_BUTTONS,
} from '@/editor/constants/Constants';
import blockRendererFn from '@/editor/components/blocks/blockRendererFn';
import Toolbar from '@/editor/components/toolbar/Toolbar';
import getDefaultDecorator from '@/editor/decorators/getDefaultDecorator';
import getCurrentBlock from '@/editor/utils/getCurrentBlock';
import resetBlockWithType from '@/editor/utils/resetBlockWithType';
import addNewBlockAt from '@/editor/utils/addNewBlockAt';
import blockStyleFn from '@/editor/components/blocks/blockStyleFn';
import RichEditorToolbar from '@/storychief/components/editors/RichEditorToolbar';
import Truncate from '@/storychief/components/Truncate';
import AssistantProvider from '@/assistant/components/AssistantProvider';
import BlockToolbarAssistant from '@/editor/components/blocks/BlockToolbarAssistant';
import AssistantPlaceholder from '@/assistant/components/AssistantPlaceholder';
import {
  addPastedURLtoEditorState,
  getEditorStateWithFixedAtomicBlockAfterPaste,
} from '@/storychief/components/editors/Modifiers';
import useAccount from '@/account/hooks/useAccount';

export const INLINE_BUTTONS = [
  ALL_INLINE_BUTTONS.BOLD,
  ALL_INLINE_BUTTONS.ITALIC,
  ALL_INLINE_BUTTONS.UNDERLINE,
  ALL_INLINE_BUTTONS.HYPERLINK,
];

export const BLOCK_BUTTONS = ALL_BLOCK_BUTTONS;

export const PLUGINS = [image, video, embed, divider, columnDivider];

const propTypes = {
  updateContent: PropTypes.func,
  formControl: PropTypes.bool,
  allowAnchorLinks: PropTypes.bool,
  formControlAutoHeight: PropTypes.bool,
  isEditorResizable: PropTypes.bool,
  editorSize: PropTypes.string,
  formControlId: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.shape([])]),
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  emojiPicker: PropTypes.bool,
  onEmojiPickerOpen: PropTypes.func,
  onEmojiPickerClose: PropTypes.func,
  isPreview: PropTypes.bool,
  isPrivate: PropTypes.bool,
  previewTruncateLines: PropTypes.number,
  disabled_inline_styles: PropTypes.arrayOf(
    PropTypes.oneOf([...INLINE_BUTTONS.map((_type) => _type.style)]),
  ),
  disabled_block_styles: PropTypes.arrayOf(
    PropTypes.oneOf([...BLOCK_BUTTONS.map((_type) => _type.style)]),
  ),
  disabled_plugins: PropTypes.arrayOf(PropTypes.oneOf([...PLUGINS.map((_plugin) => _plugin.type)])),
  assistantSubject: PropTypes.shape({
    type: PropTypes.oneOf(['Brief']),
    language: PropTypes.string,
  }),
  assistantShowPlaceholder: PropTypes.bool,
  hasFooter: PropTypes.bool,
};

const defaultProps = {
  updateContent: () => {},
  formControl: false,
  formControlId: undefined,
  formControlAutoHeight: false,
  isEditorResizable: false,
  editorSize: 'md',
  disabled: false,
  emojiPicker: false,
  placeholder: '',
  allowAnchorLinks: false,
  content: undefined,
  onEmojiPickerOpen: undefined,
  onEmojiPickerClose: undefined,
  isPreview: false,
  isPrivate: false,
  previewTruncateLines: null,
  disabled_inline_styles: [],
  disabled_block_styles: [],
  disabled_plugins: [],
  assistantSubject: {},
  assistantShowPlaceholder: false,
  hasFooter: false,
};
const continuousBlocks = [Block.UNSTYLED, Block.BLOCKQUOTE, Block.OL, Block.UL, Block.CODE];

function removeDisabledStyles(buttons, disabledStyles) {
  return buttons.filter(
    (button) => !disabledStyles.find((disabledStyle) => disabledStyle === button.style),
  );
}

function removeDisabledPlugins(plugins, disabledPlugins) {
  return plugins.filter(
    (plugin) => !disabledPlugins.find((disabledPlugin) => disabledPlugin === plugin.type),
  );
}

class RichEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: null,
      showToolbarHyperlink: false,
      readOnly: false,
      INLINE_BUTTONS: removeDisabledStyles(INLINE_BUTTONS, props.disabled_inline_styles),
      BLOCK_BUTTONS: removeDisabledStyles(BLOCK_BUTTONS, props.disabled_block_styles),
      PLUGINS: removeDisabledPlugins(PLUGINS, props.disabled_plugins),
    };
    this.toolbarsContainerNode = createRef();
    this.initEditorState = this.initEditorState.bind(this);
    this.focus = () => this.richEditorNode.focus();
    this.focusAtEnd = this.focusAtEnd.bind(this);
    this.onChange = this.onChange.bind(this);
    this.getEditorState = () => this.state.editorState;
    this.getEditorNode = () => this.richEditorNode;
    this.getEditorWrapperNode = () => this.editorWrapperNode;
    this.getEditorEnabled = () => !props.disabled && !props.isPreview;
    this.handleReturn = this.handleReturn.bind(this);
    this.saveContentState = throttle(this.saveContentState, 1000);
    this.addEmoji = this.addEmoji.bind(this);
    this.setLink = this.setLink.bind(this);
    this.toggleBlockType = this.toggleBlockType.bind(this);
    this.toggleInlineStyle = this.toggleInlineStyle.bind(this);
    this.setMinHeightEditorContainer = this.setMinHeightEditorContainer.bind(this);
    this.setReadOnly = (readOnly) => this.setState({ readOnly });
    this.handlePastedText = this.handlePastedText.bind(this);
    this.blockRendererFn = blockRendererFn(
      this.onChange,
      this.getEditorState,
      () => {},
      this.setReadOnly,
      this.setLink,
      this.getEditorEnabled,
      this.state.PLUGINS,
    );
  }

  UNSAFE_componentWillMount() {
    this.initEditorState(this.props);
  }

  componentDidMount() {
    this.setMinHeightEditorContainer();
    this.focusAtEnd();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.disabled || this.props.disabled !== nextProps.disabled) {
      this.initEditorState(nextProps);
    }
    if (nextProps.isPreview && this.props.content !== nextProps.content) {
      this.initEditorState(nextProps);
    }
    if (nextProps.disabled_inline_styles !== this.props.disabled_inline_styles) {
      this.setState({
        INLINE_BUTTONS: removeDisabledStyles(INLINE_BUTTONS, nextProps.disabled_inline_styles),
      });
    }
    if (nextProps.disabled_block_styles !== this.props.disabled_block_styles) {
      this.setState({
        BLOCK_BUTTONS: removeDisabledStyles(BLOCK_BUTTONS, nextProps.disabled_block_styles),
      });
    }
    if (nextProps.disabled_plugins !== this.props.disabled_plugins) {
      this.setState({
        PLUGINS: removeDisabledPlugins(PLUGINS, nextProps.disabled_plugins),
      });
    }
  }

  componentDidUpdate() {
    this.setMinHeightEditorContainer();
  }

  initEditorState(props) {
    if (props.content) {
      let newContentState = null;
      if (typeof props.content === 'string') {
        newContentState = convertFromHTML({
          htmlToEntity: (nodeName, node, createEntity) => {
            if (nodeName === 'a') {
              return createEntity('LINK', 'MUTABLE', {
                url: node.href,
                targetBlank: node.target === '_blank',
                relNofollow: node.rel === 'nofollow',
              });
            }

            return undefined;
          },
        })(props.content);
      } else {
        newContentState = convertFromRaw(props.content);
      }
      this.setState({
        editorState: EditorState.createWithContent(
          newContentState,
          getDefaultDecorator({ editorEnabled: !props.disabled }),
        ),
      });
    } else {
      this.setState({
        editorState: EditorState.createEmpty(
          getDefaultDecorator({ editorEnabled: !props.disabled }),
        ),
      });
    }
  }

  focusAtEnd() {
    const { editorState } = this.state;
    const content = editorState.getCurrentContent();
    const lastBlock = content.getLastBlock();
    const lastKey = lastBlock.getKey();
    const lastLength = lastBlock.getLength();

    const selection = SelectionState.createEmpty(lastKey).merge({
      anchorKey: lastKey,
      anchorOffset: lastLength,
      focusKey: lastKey,
      focusOffset: lastLength,
      hasFocus: true,
    });

    const newEditorState = EditorState.forceSelection(editorState, selection);
    this.setState({ editorState: newEditorState }, () => {
      this.focus();
    });
  }

  onChange(editorState) {
    const editor = this.editorWrapperNode.querySelector('.DraftEditor-root');
    const isAtBottom = editor.scrollTop + editor.clientHeight >= editor.scrollHeight - 100;

    if (isAtBottom) {
      editor.scrollTop = editor.scrollHeight;
    }

    const contentState = editorState.getCurrentContent();
    if (contentState !== this.state.editorState.getCurrentContent()) {
      // content has changed, save it
      this.saveContentState(contentState);
    }
    this.setState({ editorState });
  }

  toggleBlockType(blockType) {
    const type = RichUtils.getCurrentBlockType(this.state.editorState);
    if (type.indexOf('atomic') === 0) {
      return;
    }
    this.onChange(RichUtils.toggleBlockType(this.state.editorState, blockType));
  }

  toggleInlineStyle(inlineStyle) {
    const newEditorState = RichUtils.toggleInlineStyle(this.state.editorState, inlineStyle);
    this.onChange(newEditorState);
  }

  addEmoji(emoji) {
    const { editorState } = this.state;
    const currentContent = editorState.getCurrentContent();
    const selection = editorState.getSelection();
    let textWithEntity = null;
    if (selection.isCollapsed()) {
      textWithEntity = Modifier.insertText(currentContent, selection, emoji.native);
    } else {
      textWithEntity = Modifier.replaceText(currentContent, selection, emoji.native);
    }
    const newEditorState = EditorState.push(editorState, textWithEntity, 'insert-characters');
    this.setState(
      {
        editorState: newEditorState,
      },
      () => {
        this.focus();
      },
    );
    const contentState = newEditorState.getCurrentContent();
    this.saveContentState(contentState);
  }

  setLink(url, urlTargetBlank, urlRelNofollow) {
    const { editorState } = this.state;
    const selection = editorState.getSelection();
    const contentState = editorState.getCurrentContent();
    let newEditorState = editorState;
    let entityKey = null;
    let newUrl = url;
    if (url !== '') {
      if (url.indexOf('@') >= 0) {
        newUrl = `mailto:${newUrl}`;
      } else if (url.indexOf('http') === -1) {
        newUrl = `http://${newUrl}`;
      }
      const contentStateWithEntity = contentState.createEntity(ENTITY_TYPES.LINK, 'MUTABLE', {
        url: newUrl,
        targetBlank: urlTargetBlank,
        relNofollow: urlRelNofollow,
      });
      entityKey = contentStateWithEntity.getLastCreatedEntityKey();
      const contentStateWithLink = Modifier.applyEntity(
        contentStateWithEntity,
        selection,
        entityKey,
      );
      newEditorState = EditorState.set(editorState, { currentContent: contentStateWithLink });
    }
    this.onChange(
      RichUtils.toggleLink(newEditorState, newEditorState.getSelection(), entityKey),
      this.focus,
    );
  }

  handleReturn(e) {
    const { editorState } = this.state;
    if (e.shiftKey) {
      this.onChange(RichUtils.insertSoftNewline(editorState));
      return true;
    }
    if (!e.altKey && !e.metaKey && !e.ctrlKey) {
      const currentBlock = getCurrentBlock(editorState);
      const blockType = currentBlock.getType();

      if (blockType.indexOf('atomic') === 0) {
        this.onChange(addNewBlockAt(editorState, currentBlock.getKey()));
        return true;
      }

      if (currentBlock.getLength() === 0) {
        switch (blockType) {
          case Block.UL:
          case Block.OL:
          case Block.BLOCKQUOTE:
          case Block.BLOCKQUOTE_CAPTION:
          case Block.CAPTION:
          case Block.H2:
          case Block.H3:
          case Block.H1:
            this.onChange(resetBlockWithType(editorState, Block.UNSTYLED));
            return true;
          default:
            return false;
        }
      }

      const selection = editorState.getSelection();

      if (selection.isCollapsed() && currentBlock.getLength() === selection.getStartOffset()) {
        if (continuousBlocks.indexOf(blockType) < 0) {
          this.onChange(addNewBlockAt(editorState, currentBlock.getKey()));
          return true;
        }
        return false;
      }
      return false;
    }
    return false;
  }

  handleKeyCommand = (command) => {
    const allowedDefaultCommands = ['bold', 'italic', 'underline', 'delete', 'backspace'];
    let newState = null;

    if (command === 'addHyperlink') {
      this.handleAddHyperlinkKeyCommand();
    } else if (command === 'selectAll') {
      // This is needed to avoid event bubbling if RichEditor is a child of another editor
      this.selectAll();
    } else if (allowedDefaultCommands.includes(command)) {
      newState = RichUtils.handleKeyCommand(this.state.editorState, command);
    }

    if (newState) {
      this.onChange(newState);
      return true;
    }
    return false;
  };

  externalKeyBindings = (e) => {
    if (KeyBindingUtil.hasCommandModifier(e) && e.which === 75) {
      return 'addHyperlink';
    }

    if (KeyBindingUtil.hasCommandModifier(e) && e.key === 'a') {
      return 'selectAll';
    }

    return getDefaultKeyBinding(e);
  };

  handleAddHyperlinkKeyCommand = () => {
    const selection = this.getEditorState().getSelection();
    const currentBlock = getCurrentBlock(this.getEditorState());
    if (selection && currentBlock) {
      const entityKey = currentBlock.getEntityAt(selection.getStartOffset());
      if (!selection.isCollapsed() && !entityKey) {
        this.handleCommandShowToolbarHyperlink(true);
      }
    }
  };

  handleCommandShowToolbarHyperlink = (show) => {
    this.setState({ showToolbarHyperlink: show });
  };

  handlePastedText(text) {
    const { editorState } = this.state;

    const currentBlock = getCurrentBlock(editorState);
    const blockType = currentBlock.getType();

    if (urlRegex({ exact: true }).test(text)) {
      addPastedURLtoEditorState(editorState, text, this.setLink, this.onChange);
      return true;
    }
    if (blockType.indexOf('atomic') === 0) {
      this.onChange(getEditorStateWithFixedAtomicBlockAfterPaste(editorState, text));
      return true;
    }
    return false;
  }

  selectAll() {
    const { editorState } = this.state;
    const content = editorState.getCurrentContent();
    const firstBlock = content.getFirstBlock();
    const lastBlock = content.getLastBlock();

    const selection = SelectionState.createEmpty(firstBlock.getKey()).merge({
      anchorKey: firstBlock.getKey(),
      anchorOffset: 0,
      focusKey: lastBlock.getKey(),
      focusOffset: lastBlock.getLength(),
      hasFocus: true,
    });

    const newEditorState = EditorState.forceSelection(editorState, selection);
    this.onChange(newEditorState);
  }

  saveContentState(contentState) {
    this.props.updateContent(convertToRaw(contentState));
  }

  setMinHeightEditorContainer() {
    if (this.props.formControlAutoHeight && !this.props.disabled) {
      const placeholder = this.editorWrapperNode.getElementsByClassName(
        'public-DraftEditorPlaceholder-inner',
      );
      if (placeholder.length) {
        const minHeight = `${placeholder[0].offsetHeight}px`;
        const editorContentNode = this.richEditorNode.editorContainer.getElementsByClassName(
          'public-DraftEditor-content',
        );
        if (editorContentNode.length) {
          editorContentNode[0].style.minHeight = minHeight;
        }
        this.richEditorNode.editorContainer.style.minHeight = minHeight;
      }
    }
  }

  render() {
    const {
      formControl,
      formControlAutoHeight,
      isEditorResizable,
      editorSize,
      formControlId,
      disabled,
      emojiPicker,
      allowAnchorLinks,
      onEmojiPickerOpen,
      onEmojiPickerClose,
      isPreview,
      previewTruncateLines,
      assistantSubject,
      hasFooter,
      account,
    } = this.props;
    const className = classNames('editor--rich-editor', {
      'editor--resizable': isEditorResizable,
      [`editor--${editorSize}`]: !isPreview,
      'editor--rich-editor--footer': hasFooter,
    });

    const formControlClassName = classNames('', {
      'form-control form-control-html': formControl,
      'form-control-auto-height': formControlAutoHeight,
      'form-control-with-emoji-picker': emojiPicker,
      focus: document.activeElement === this.editorWrapperNode,
    });
    const currentBlock = getCurrentBlock(this.state.editorState);

    return (
      <div className={className} data-editor-container>
        <div
          ref={(node) => {
            if (node !== null) {
              this.editorWrapperNode = node;
            }
          }}
          id={formControlId}
          disabled={disabled}
          className={formControlClassName}
        >
          <AssistantProvider
            getEditorState={this.getEditorState}
            setEditorState={this.onChange}
            setReadOnly={this.setReadOnly}
            subject={{
              ...assistantSubject,
              component: 'RichEditor',
              hasSelectedText: !this.state.editorState.getSelection().isCollapsed(),
              isEmpty: !this.state.editorState.getCurrentContent().hasText(),
            }}
          >
            <div className="editor-toolbars" ref={this.toolbarsContainerNode}>
              {!isPreview && (
                <RichEditorToolbar
                  focus={this.focus}
                  disabled={disabled}
                  isPrivate={this.props.isPrivate}
                  plugins={this.state.PLUGINS}
                  inlineButtons={this.state.INLINE_BUTTONS}
                  blockButtons={this.state.BLOCK_BUTTONS}
                  setReadOnly={this.setReadOnly}
                  onToggleBlockStyle={this.toggleBlockType}
                  getEditorState={this.getEditorState}
                  onToggleInlineStyle={this.toggleInlineStyle}
                  setEditorState={this.onChange}
                  emojiPicker={emojiPicker}
                  onEmojiPickerOpen={onEmojiPickerOpen}
                  onEmojiPickerClose={onEmojiPickerClose}
                />
              )}

              {!isPreview && (
                <>
                  <Toolbar
                    toolbarsContainer={this.toolbarsContainerNode.current}
                    getEditorWrapperNode={this.getEditorWrapperNode}
                    setEditorState={this.onChange}
                    editorState={this.state.editorState}
                    toggleBlockType={this.toggleBlockType}
                    toggleInlineStyle={this.toggleInlineStyle}
                    editorEnabled={!disabled}
                    setLink={this.setLink}
                    allowAnchorLinks={allowAnchorLinks}
                    focus={this.focus}
                    blockButtons={[]}
                    inlineButtonsRight={this.state.INLINE_BUTTONS}
                    inlineButtonsLeft={[]}
                    showToolbarHyperlink={this.state.showToolbarHyperlink}
                    handleCommandShowToolbarHyperlink={this.handleCommandShowToolbarHyperlink}
                  />
                  {currentBlock && (
                    <BlockToolbarAssistant
                      getEditorNode={this.getEditorNode}
                      currentBlock={currentBlock}
                      size="sm"
                    />
                  )}
                </>
              )}
            </div>

            <Truncate lines={previewTruncateLines}>
              <Editor
                ref={(node) => {
                  this.richEditorNode = node;
                }}
                readOnly={disabled || this.state.readOnly || isPreview}
                placeholder={isPreview ? '' : this.props.placeholder}
                editorState={this.state.editorState}
                getEditorState={this.getEditorState}
                blockStyleFn={blockStyleFn}
                onChange={this.onChange}
                handleReturn={this.handleReturn}
                handleKeyCommand={this.handleKeyCommand}
                blockRendererFn={this.blockRendererFn}
                customStyleMap={CUSTOM_BLOCK_STYLES}
                stripPastedStyles
                keyBindingFn={this.externalKeyBindings}
                handlePastedText={this.handlePastedText}
                webDriverTestID="rich-editor"
              />
            </Truncate>
            {this.props.assistantShowPlaceholder && account.can.power_mode && (
              <AssistantPlaceholder getEditorNode={this.getEditorNode} focusEditor={this.focus} />
            )}
          </AssistantProvider>
        </div>
      </div>
    );
  }
}

function RichEditorWrapped(props) {
  const account = useAccount();

  return <RichEditor {...props} account={account} />;
}

RichEditor.propTypes = propTypes;
RichEditor.defaultProps = defaultProps;

export default RichEditorWrapped;
