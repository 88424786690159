import {
  adways,
  airtable,
  anchorfm,
  buzzsprout,
  chartblocks,
  codepen,
  codesandbox,
  facebookVideo,
  facebookPost,
  gforms,
  giphy,
  githubgist,
  gmaps,
  hubspotCta,
  imgur,
  infogram,
  instagram,
  issuu,
  libsyn,
  mixcloud,
  pastebin,
  pippa,
  podcastco,
  podigee,
  radiopublic,
  simplecast,
  slideshare,
  soundcloud,
  spotify,
  spotlightr,
  thinglink,
  transistor,
  twitter,
  typeform,
  upscribe,
  vimeo,
  vooplayer,
  whooshkaa,
  wistia,
  youtube,
  captivate,
  datawrapper,
  kbsMedia,
  tiktok,
  loom,
  bookmark,
  hubspotForm,
  activeCampaignForm,
  applePodcasts,
  embedsocialForm,
  canva,
  cloudinary,
  arcadeDemo,
  springCast,
  rawIframe,
} from './providers';

import getProvider from './utils/getProvider';

export default class Oembed {
  constructor() {
    this.provider = null;
    this.data = null;
    this.src = null;
  }

  getData(url) {
    const provider = getProvider(url);
    if (provider) {
      this.src = url;
      let providerPromise = null;
      this.provider = provider;
      if (provider === 'instagram') {
        providerPromise = instagram(url);
      }
      if (provider === 'twitter') {
        providerPromise = twitter(url);
      }
      if (provider === 'youtube') {
        providerPromise = youtube(url);
      }
      if (provider === 'vimeo') {
        providerPromise = vimeo(url);
      }
      if (provider === 'wistia') {
        providerPromise = wistia(url);
      }
      if (provider === 'soundcloud') {
        providerPromise = soundcloud(url);
      }
      if (provider === 'mixcloud') {
        providerPromise = mixcloud(url);
      }
      if (provider === 'slideshare') {
        providerPromise = slideshare(url);
      }
      if (provider === 'gmaps') {
        providerPromise = gmaps(url);
      }
      if (provider === 'hubspotCta') {
        providerPromise = hubspotCta(url);
      }
      if (provider === 'typeform') {
        providerPromise = typeform(url);
      }
      if (provider === 'giphy') {
        providerPromise = giphy(url);
      }
      if (provider === 'vooplayer') {
        providerPromise = vooplayer(url);
      }
      if (provider === 'spotlightr') {
        providerPromise = spotlightr(url);
      }
      if (provider === 'airtable') {
        providerPromise = airtable(url);
      }
      if (provider === 'codepen') {
        providerPromise = codepen(url);
      }
      if (provider === 'adways') {
        providerPromise = adways(url);
      }
      if (provider === 'pippa') {
        providerPromise = pippa(url);
      }
      if (provider === 'anchorfm') {
        providerPromise = anchorfm(url);
      }
      if (provider === 'libsyn') {
        providerPromise = libsyn(url);
      }
      if (provider === 'chartblocks') {
        providerPromise = chartblocks(url);
      }
      if (provider === 'codesandbox') {
        providerPromise = codesandbox(url);
      }
      if (provider === 'radiopublic') {
        providerPromise = radiopublic(url);
      }
      if (provider === 'upscribe') {
        providerPromise = upscribe(url);
      }
      if (provider === 'simplecast') {
        providerPromise = simplecast(url);
      }
      if (provider === 'issuu') {
        providerPromise = issuu(url);
      }
      if (provider === 'spotify') {
        providerPromise = spotify(url);
      }
      if (provider === 'whooshkaa') {
        providerPromise = whooshkaa(url);
      }
      if (provider === 'infogram') {
        providerPromise = infogram(url);
      }
      if (provider === 'thinglink') {
        providerPromise = thinglink(url);
      }
      if (provider === 'buzzsprout') {
        providerPromise = buzzsprout(url);
      }
      if (provider === 'podcastco') {
        providerPromise = podcastco(url);
      }
      if (provider === 'githubgist') {
        providerPromise = githubgist(url);
      }
      if (provider === 'imgur') {
        providerPromise = imgur(url);
      }
      if (provider === 'facebookVideo') {
        providerPromise = facebookVideo(url);
      }
      if (provider === 'facebookPost') {
        providerPromise = facebookPost(url);
      }
      if (provider === 'pastebin') {
        providerPromise = pastebin(url);
      }
      if (provider === 'gforms') {
        providerPromise = gforms(url);
      }
      if (provider === 'podigee') {
        providerPromise = podigee(url);
      }
      if (provider === 'transistor') {
        providerPromise = transistor(url);
      }
      if (provider === 'applePodcasts') {
        providerPromise = applePodcasts(url);
      }
      if (provider === 'captivate') {
        providerPromise = captivate(url);
      }
      if (provider === 'datawrapper') {
        providerPromise = datawrapper(url);
      }
      if (provider === 'kbsMedia') {
        providerPromise = kbsMedia(url);
      }
      if (provider === 'tiktok') {
        providerPromise = tiktok(url);
      }
      if (provider === 'loom') {
        providerPromise = loom(url);
      }
      if (provider === 'bookmark') {
        providerPromise = bookmark(url);
      }
      if (provider === 'hubspotForm') {
        providerPromise = hubspotForm(url);
      }
      if (provider === 'activeCampaignForm') {
        providerPromise = activeCampaignForm(url);
      }
      if (provider === 'embedsocialForm') {
        providerPromise = embedsocialForm(url);
      }
      if (provider === 'canva') {
        providerPromise = canva(url);
      }
      if (provider === 'cloudinary') {
        providerPromise = cloudinary(url);
      }
      if (provider === 'arcadeDemo') {
        providerPromise = arcadeDemo(url);
      }
      if (provider === 'springCast') {
        providerPromise = springCast(url);
      }
      if (provider === 'rawIframe') {
        providerPromise = rawIframe(url);
      }
      providerPromise
        .then(
          (data) => {
            this.data = data;
          },
          (err) => Promise.reject(err),
        )
        .catch(() => {});
      return providerPromise;
    }
    const error = { message: 'provider not found' };
    return Promise.reject(error);
  }
}
