import type { JSX } from 'react';
import { Icon } from '@iconify-icon/react';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { convertToRaw } from 'draft-js';
import useEditorCollaboration from '@/editor/hooks/useEditorCollaboration';
import Button from '@/storychief/components/Button';
import StoryChief from '@/storychief';
import { useEmitEvent, useEmitEventEffect } from '@/storychief/hooks';
import { EDITOR_COLLABORATION_EVENT } from '@/editor/constants/Constants';

const getBlockRaw = (contentState, blockKey) => {
  const contentStateWithSingleBlock = contentState.merge({
    blockMap: contentState.getBlockMap().filter((_, key) => key === blockKey),
  });

  return convertToRaw(contentStateWithSingleBlock);
};

function DefaultBlockComponent(props): JSX.Element {
  // Variables
  const editorDomEl = document.querySelector('.editor-container');

  // Hooks
  const {
    blockedBlocks,
    portalTarget,
    setPortalTarget,
    setContentJSON,
    setBlockTarget,
    blockTarget,
    loading,
  } = useEditorCollaboration();
  const emitStartedEditingBlock = useEmitEvent(EDITOR_COLLABORATION_EVENT.emitStartedEditingBlock);

  // Refs
  const containerRef = useRef(null);
  const mainContainerRef = useRef(null);

  // States
  const [leftPos, setLeftPos] = useState(getLeftPositionForIcons());
  const [isEditEnabled, setIsEditEnabled] = useState(!!blockTarget);
  const [editorIsOpenedInOtherTab, setEditorIsOpenedInOtherTab] = useState(false);

  // Effects
  useEffect(() => {
    setLeftPos(getLeftPositionForIcons());
  }, [getLeftPositionForIcons()]);

  useEmitEventEffect((data) => {
    setEditorIsOpenedInOtherTab(data);
  }, EDITOR_COLLABORATION_EVENT.setEditorOpenInOtherTab);

  useEffect(() => {
    const isThisTheBlockTargeted = blockTarget === props.block.getKey();
    setIsEditEnabled(isThisTheBlockTargeted);
  }, [blockTarget]);

  // Functions
  function handleOnClick(): void {
    if (!portalTarget) {
      emitStartedEditingBlock({
        user: StoryChief.user,
        block: props.block.getKey(),
      });

      setContentJSON(getBlockRaw(props.contentState, props.block.getKey()));
      setIsEditEnabled(true);
      setPortalTarget(containerRef);
      setBlockTarget(props.block.getKey());
    }
  }

  function getIsBlockBlocked() {
    return blockedBlocks.some((blockedBlock) => blockedBlock.block === props.block.getKey());
  }

  function getBlockAuthor() {
    return blockedBlocks.find((blockedBlock) => blockedBlock.block === props.block.getKey())?.user;
  }

  function getCanShowEditButton(): boolean {
    // Hide the block when there is no text inside to avoid the secondary editor
    // be able to edit the first or others blocks when are empty
    return !loading && !getIsBlockBlocked() && props.block.getText() && !editorIsOpenedInOtherTab;
  }

  function getLeftPositionForIcons(): number {
    const editorLeftPos = (editorDomEl?.getBoundingClientRect().left ?? 0) + window.scrollX;

    const mainContainerLeftPos =
      (mainContainerRef?.current?.getBoundingClientRect().left ?? 0) + window.scrollX;

    const offset = 10;

    return editorLeftPos - mainContainerLeftPos - offset;
  }

  // Render
  return (
    <div
      ref={mainContainerRef}
      className={classNames('group', {
        'text-gray-chateau': getIsBlockBlocked(),
      })}
    >
      <div className="default-block-component relative">
        {getIsBlockBlocked() && (
          <div className="z-9999 absolute -top-1 h-[23px] w-[23px]" style={{ left: leftPos }}>
            <img
              className="img-circle z-9999 mr-1 !inline-block h-[23px] w-[23px]"
              src={getBlockAuthor().profile_picture}
              alt={getBlockAuthor().fullname}
              data-testid="default-block-component-profile-image"
            />
          </div>
        )}

        {!isEditEnabled && !portalTarget && (
          <div
            style={{ left: leftPos }}
            className={classNames('z-9999 absolute top-[3px] pr-[100px] opacity-0', {
              'group-hover:opacity-100': getCanShowEditButton(),
              'pointer-events-none': !getCanShowEditButton(),
            })}
          >
            <div>
              <Button
                variant="link"
                onClick={handleOnClick}
                className="inline-block opacity-80 hover:opacity-100"
              >
                <Icon
                  width={9}
                  icon="fa:pencil"
                  className="rounded-full border border-solid border-alto bg-white p-1.5"
                  data-testid="default-block-component-pencil-icon"
                />
              </Button>
            </div>
          </div>
        )}

        <div className={classNames({ hidden: !isEditEnabled, 'caret-visible': isEditEnabled })}>
          <div ref={containerRef} />
        </div>

        {!isEditEnabled && props.blockProps.childrenRenderer(props)}
      </div>
    </div>
  );
}

export default DefaultBlockComponent;
