import request from 'superagent';
import { DELETE_STORY_INTEGRATION } from './types';
import processingIntegration from '../../integrations/actions/processingIntegration';
import updateStoryIntegrationStatusError from './updateStoryIntegrationStatusError';
import StoryChief from '../../storychief/index';

function deleteStoryIntegrationAsync(storyIntegration) {
  return {
    type: DELETE_STORY_INTEGRATION,
    payload: storyIntegration,
  };
}

export default function deleteStoryIntegration(storyIntegration) {
  return (dispatch) => {
    dispatch(processingIntegration(storyIntegration.integration_id));
    request
      .del(
        `${StoryChief.apiBasePath}/stories/${storyIntegration.story_id}/integrations/${storyIntegration.id}`,
      )
      .set('Accept', 'application/json')
      .set('X-Requested-With', 'XMLHttpRequest')
      .set('X-CSRF-TOKEN', StoryChief.csrfToken)
      .set('X-Socket-ID', window.Echo.socketId())
      .end((err, res) => {
        if (res && res.ok) {
          dispatch(deleteStoryIntegrationAsync(storyIntegration));
        } else {
          dispatch(updateStoryIntegrationStatusError(res, err));
        }
      });
  };
}
