import { Modifier, EditorState } from 'draft-js';
import getInsertRangeMention from '../../../editor/utils/getInsertRangeMention';
import { getCurrentMention } from '@/editor/utils/getCurrentMention';
import getCurrentTextData from '@/editor/utils/getCurrentTextData';
import { ENTITY_TYPES } from '@/editor/constants/Constants';

function getNewEditorState(editorState, contentState, addSpace) {
  let newEditorState = EditorState.push(editorState, contentState, 'apply-entity');
  let newContentState = newEditorState.getCurrentContent();
  const newSelectionState = newEditorState.getSelection();
  newContentState = Modifier.insertText(newContentState, newSelectionState, addSpace ? ' ' : '');
  newEditorState = EditorState.push(newEditorState, newContentState, 'insert-characters');

  return newEditorState;
}

/**
 *
 * @param {object} editorState
 * @param {object} mention
 * @param {string} mention.name
 * @returns {object}
 */
export function addMentionSelectToEditorState(editorState, mention) {
  const { start, end } = getInsertRangeMention(editorState);
  const name = mention.name;
  const selectionState = editorState.getSelection();

  const selection = selectionState.merge({
    anchorOffset: start,
    focusOffset: end,
  });

  const contentState = editorState.getCurrentContent();
  const contentStateWithEntity = contentState.createEntity('mention', 'IMMUTABLE', { ...mention });
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

  const isCharAfterCursor = !!contentState.getPlainText()[end];

  return getNewEditorState(
    editorState,
    Modifier.replaceText(contentStateWithEntity, selection, name, null, entityKey),
    !isCharAfterCursor,
  );
}

/**
 *
 * @param {object} editorState
 * @param {string} name
 * @param {number} channelType
 * @returns {object}
 */
export function addMentionToEditorState(editorState, { name } = {}, channelType) {
  const selectionState = editorState.getSelection();
  const contentState = editorState.getCurrentContent();
  const [index, text] = getCurrentTextData(editorState);
  const currentMention = getCurrentMention({ index, text, channelType });
  const selection = selectionState.merge({
    anchorOffset: currentMention.start,
    focusOffset: currentMention.end,
  });

  return getNewEditorState(editorState, Modifier.replaceText(contentState, selection, name, null));
}

/**
 *
 * @param {object} editorState
 * @param {string} url
 * @param {Function} setLink
 * @param {Function} onChange
 * @returns {void}
 */
export function addPastedURLtoEditorState(editorState, url, setLink, onChange) {
  const currentContent = editorState.getCurrentContent();
  let selection = editorState.getSelection();

  if (selection.isCollapsed()) {
    // Add URL as text.
    const blockText = Modifier.insertText(currentContent, selection, url);
    let newEditorState = EditorState.push(editorState, blockText, 'insert-characters');

    // Change newly inserted URL text to a link.
    selection = selection.merge({
      anchorOffset: selection.anchorOffset,
      focusOffset: selection.focusOffset + url.length,
    });
    newEditorState = EditorState.forceSelection(newEditorState, selection);
    let { newEditorState: newEditorStateWithLink } = addLinkToEditorState({
      url,
      editorState: newEditorState,
    });
    selection = selection.merge({
      anchorOffset: selection.focusOffset,
      focusOffset: selection.focusOffset,
    });
    newEditorStateWithLink = EditorState.forceSelection(newEditorStateWithLink, selection);

    onChange(newEditorStateWithLink);
  } else {
    // Change selected text to a link.
    setLink(url);
  }
}

/**
 *
 * @param {object} options
 * @param {string} options.url
 * @param {boolean} options.urlTargetBlank
 * @param {boolean} options.urlRelNofollow
 * @param {object} options.editorState
 * @returns {object}
 */
export function addLinkToEditorState({ url, urlTargetBlank, urlRelNofollow, editorState }) {
  const selection = editorState.getSelection();
  const contentState = editorState.getCurrentContent();
  let newEditorState = editorState;
  let entityKey = null;
  let newUrl = url;
  if (url !== '' && url !== '-Edit-') {
    const hasPrefix = /^(mailto:|tel:|#|http:|https:)/.test(url);

    if (!hasPrefix) {
      if (url.includes('@')) {
        // URL is an email
        newUrl = `mailto:${newUrl}`;
      } else {
        // URL is an external link
        newUrl = `http://${newUrl}`;
      }
    }

    const contentStateWithEntity = contentState.createEntity(ENTITY_TYPES.LINK, 'MUTABLE', {
      url: newUrl,
      targetBlank: urlTargetBlank,
      relNofollow: urlRelNofollow,
    });
    entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const contentStateWithLink = Modifier.applyEntity(contentStateWithEntity, selection, entityKey);
    newEditorState = EditorState.set(editorState, { currentContent: contentStateWithLink });
  }

  return { entityKey, newEditorState };
}

/**
 * The functions returns a fixed version of the editor state to avoid block data loss when pasting text inside atomic blocks captions.
 *
 * @param {object} editorState
 * @param {string} text
 * @returns {object}
 */
export function getEditorStateWithFixedAtomicBlockAfterPaste(editorState, text) {
  const currentContent = editorState.getCurrentContent();
  const selection = editorState.getSelection();
  let blockText;
  if (selection.isCollapsed()) {
    blockText = Modifier.insertText(currentContent, selection, text);
  } else {
    blockText = Modifier.replaceText(currentContent, selection, text);
  }
  return EditorState.push(editorState, blockText, 'insert-characters');
}
