import { TWITTER_MENTIONS_PATTERN } from '@/editor/constants/Constants';

export default function findTwitterWithRegex(contentBlock, callback) {
  const text = contentBlock.getText();
  let matchArr;
  // eslint-disable-next-line no-cond-assign
  while ((matchArr = TWITTER_MENTIONS_PATTERN.exec(text)) !== null) {
    const cleanedText = matchArr[0].match(/@(.*)/g)[0];
    const start = matchArr.index + matchArr[0].length - cleanedText.length;
    let end = start + cleanedText.length;
    if (matchArr[0][matchArr[0].length] === ' ') {
      end -= 1;
    }
    callback(start, end, cleanedText);
  }
}
