import { TransformStream } from 'web-streams-polyfill';

// Source: https://developer.mozilla.org/en-US/docs/Web/API/TransformStream#polyfilling_textencoderstream_and_textdecoderstream
const tds = {
  start() {
    this.decoder = new TextDecoder(this.encoding, this.options);
  },
  transform(chunk, controller) {
    controller.enqueue(this.decoder.decode(chunk, { stream: true }));
  },
};

const _jstds_wm = new WeakMap(); /* info holder */

class TextDecoderStreamPolyfill extends TransformStream {
  constructor(encoding = 'utf-8', { ...options } = {}) {
    const t = { ...tds, encoding, options };
    super(t);

    _jstds_wm.set(this, t);
  }

  get encoding() {
    return _jstds_wm.get(this).decoder.encoding;
  }

  get fatal() {
    return _jstds_wm.get(this).decoder.fatal;
  }

  get ignoreBOM() {
    return _jstds_wm.get(this).decoder.ignoreBOM;
  }
}

const TextDecoderStreamJS =
  typeof TextDecoderStream !== 'undefined' ? window.TextDecoderStream : TextDecoderStreamPolyfill;

export default TextDecoderStreamJS;
