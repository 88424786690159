import { EditorState, Modifier, SelectionState } from 'draft-js';

export default function splitEditorState(editorState) {
  const contentState = editorState.getCurrentContent();
  let aboveSelectionContentState;
  let belowSelectionContentState;
  let selectionContentState;
  const blockMap = contentState.getBlockMap();
  const selectionState = editorState.getSelection();
  const isBackward = selectionState.getIsBackward();

  const firstBlock = blockMap.first();
  const lastBlock = blockMap.last();

  const aboveSelectionState = new SelectionState({
    anchorKey: firstBlock.key,
    anchorOffset: 0,
    focusKey: selectionState.getStartKey(),
    focusOffset: selectionState.getStartOffset(),
  });
  const belowSelectionState = new SelectionState({
    anchorKey: selectionState.getEndKey(),
    anchorOffset: selectionState.getEndOffset(),
    focusKey: lastBlock.key,
    focusOffset: lastBlock.getText().length,
  });
  selectionContentState = Modifier.removeRange(contentState, belowSelectionState, 'forward');
  selectionContentState = Modifier.removeRange(
    selectionContentState,
    aboveSelectionState,
    'backward',
  );

  aboveSelectionContentState = Modifier.removeRange(contentState, belowSelectionState, 'backward');
  aboveSelectionContentState = Modifier.removeRange(
    aboveSelectionContentState,
    selectionState,
    'backward',
  );

  belowSelectionContentState = Modifier.removeRange(contentState, selectionState, 'backward');
  belowSelectionContentState = Modifier.removeRange(
    belowSelectionContentState,
    aboveSelectionState,
    'backward',
  );

  const firstSelectedBlock = contentState.getBlockForKey(
    isBackward ? selectionState.focusKey : selectionState.anchorKey,
  );

  const firstSelectedBlockSelection = new SelectionState({
    anchorKey: selectionContentState.getBlockMap().first().key,
    anchorOffset: 0,
    focusKey: selectionContentState.getBlockMap().first().key,
    focusOffset: selectionContentState.getBlockMap().first().text.length,
  });

  selectionContentState = Modifier.setBlockType(
    selectionContentState,
    firstSelectedBlockSelection,
    firstSelectedBlock.type,
  );

  const selectionEditorState = EditorState.push(editorState, selectionContentState, 'remove-range');
  const aboveSelectionEditorState = EditorState.push(
    editorState,
    aboveSelectionContentState,
    'remove-range',
  );
  const belowSelectionEditorState = EditorState.push(
    editorState,
    belowSelectionContentState,
    'remove-range',
  );

  return { selectionEditorState, aboveSelectionEditorState, belowSelectionEditorState };
}
