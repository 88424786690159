export default function (url) {
  let data = {};
  let scroll = 'no';
  let height = 241;
  if (url.includes('podcast')) {
    scroll = 'yes';
    height = 390;
  }
  data = {
    type: 'rich',
    version: '1.0',
    iframe_src: url,
    provider_name: 'SpringCast',
    povider_url: 'https://www.springcast.fm/',
    html: `<iframe src='${url}' width='100%'
        height='${height}' frameBorder='0' scrolling='${scroll}' seamless='true'
        ></iframe>`,
  };
  return new Promise((resolve) => {
    data.html_responsive = data.html;
    resolve(data);
  });
}
