import PropTypes from 'prop-types';
import classNames from 'classnames';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { uniqueId } from 'lodash-es';
import { Icon } from '@iconify-icon/react';
import { HYPERLINK } from '@/editor/constants/Constants';
import ConditionalWrapper from '@/storychief/components/ConditionalWrapper';

const propTypes = {
  onToggle: PropTypes.func,
  styleName: PropTypes.string,
  active: PropTypes.bool,
  icon: PropTypes.string.isRequired,
  label: PropTypes.string,
  description: PropTypes.string,
  shortcut: PropTypes.string,
  disabled: PropTypes.bool,
  showIconAndLabel: PropTypes.bool,
  showTooltip: PropTypes.bool,
};

const defaultProps = {
  onToggle: () => {},
  styleName: null,
  label: null,
  active: false,
  description: null,
  shortcut: null,
  disabled: false,
  showIconAndLabel: false,
  showTooltip: false,
};

export default function ToolbarButton(props) {
  const {
    styleName,
    active,
    description,
    shortcut,
    showIconAndLabel,
    icon,
    label,
    showTooltip,
    onToggle,
    disabled,
  } = props;

  if (styleName === HYPERLINK) {
    return null;
  }

  // Variables
  const className = classNames(
    'RichEditor-styleButton btn btn-chromeless hint--top',
    { 'RichEditor-activeButton': active },
    `RichEditor-styleButton-${styleName.toLowerCase()}`,
  );
  const fullDescription = `${description}${shortcut ? ` (${shortcut})` : ''}`;

  // Functions
  function handleToggle(e) {
    e.preventDefault();
    if (!disabled) {
      onToggle(styleName);
    }
  }

  return (
    <ConditionalWrapper
      condition={showTooltip}
      wrapper={(children) => (
        <OverlayTrigger
          placement="top"
          key={uniqueId('toolbar-button-overlay-')}
          delayShow={100}
          overlay={<Tooltip id={uniqueId('toolbar-button-tooltip-')}>{fullDescription}</Tooltip>}
        >
          {children}
        </OverlayTrigger>
      )}
    >
      <button
        className={className}
        type="button"
        onMouseDown={handleToggle}
        aria-label={description}
        title={!showTooltip ? fullDescription : null}
        disabled={disabled}
      >
        {showIconAndLabel ? (
          <>
            <Icon
              icon={icon}
              width="20"
              height="13"
              className="mr-[3px]"
              inline
              // This makes sure the width of the icon is known immediately to properly calculate the toolbar position
              style={{ width: '20px' }}
            />
            <span> {label}</span>
          </>
        ) : (
          <>
            {icon ? (
              <Icon
                icon={icon}
                width="20"
                height="13"
                inline
                // This makes sure the width of the icon is known immediately to properly calculate the toolbar position
                style={{ width: '20px' }}
              />
            ) : (
              label
            )}
          </>
        )}
      </button>
    </ConditionalWrapper>
  );
}

ToolbarButton.propTypes = propTypes;
ToolbarButton.defaultProps = defaultProps;
