export default function (url) {
  let data = {};
  data = {
    type: 'rich',
    version: '1.0',
    iframe_src: url,
    provider_name: 'Typeform',
    povider_url: 'https://www.typeform.com/',
    width: 598,
    height: 450,
    html: `<div class="typeform-widget" data-url="${url}" style="width: 100%; height: 500px;"></div> 
            <script> 
            (function() { var qs,js,q,s,d=document, gi=d.getElementById, ce=d.createElement, gt=d.getElementsByTagName, id="typef_orm", b="https://embed.typeform.com/"; if(!gi.call(d,id)) { js=ce.call(d,"script"); js.id=id; js.src=b+"embed.js"; q=gt.call(d,"script")[0]; q.parentNode.insertBefore(js,q) } })() 
            </script>`,
  };
  return new Promise((resolve) => {
    data.html_responsive = data.html;
    resolve(data);
  });
}
