import request from 'superagent';
import jsonp from 'superagent-jsonp';
import convertIframeToResponsive from '../utils/convertIframeToResponsive';

export default function (url) {
  return new Promise((resolve, reject) => {
    request
      .get(`https://noembed.com/embed/?url=${url}`)
      .query({ url })
      .use(
        jsonp({
          timeout: 3000,
          callbackName: 'callback',
        }),
      )
      .end((err, res) => {
        if (err) {
          reject(err);
        } else {
          const data = res.body;
          const urlParams = data.url.replace(/^.*\/\/[^\/]+/, '').split('/'); // eslint-disable-line no-useless-escape
          urlParams.shift();
          const id = urlParams[1];
          const iframeSrc = `https://giphy.com/embed/${id}/twitter/iframe`;
          const html = `<iframe scrolling="no" frameborder="0" width="${data.width}" height="${data.height}" style="border:0" src="${iframeSrc}" allowfullscreen="allowfullscreen"></iframe>`;
          data.html = html;
          data.html_responsive = convertIframeToResponsive(html);
          data.iframe_src = iframeSrc;
          data.thumbnail_url = `https://i.giphy.com/media/${id}/giphy.gif`;
          resolve(data);
        }
      });
  });
}
