import request from 'superagent';
import StoryChief from '../../storychief';

export default function (url) {
  return new Promise((resolve, reject) => {
    request
      .put(`${StoryChief.dashboardBasePath}/integrations/embed/oembed`)
      .set('Accept', 'application/json')
      .set('X-Requested-With', 'XMLHttpRequest')
      .set('X-CSRF-TOKEN', StoryChief.csrfToken)
      .send({
        url,
        provider: 'facebook-post',
      })
      .end((err, res) => {
        if (err) {
          reject(err);
        } else {
          const data = res.body;
          data.url = url;
          if (!data.height) {
            data.height = 700;
          }
          resolve(data);
        }
      });
  });
}
