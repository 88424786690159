import { useRef, useState } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import { Overlay, Popover } from 'react-bootstrap';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useDebounce } from '@/storychief/hooks';
import PopoverMenu from '@/storychief/components/PopoverMenu';
import usePopoverHeight from '@/storychief/hooks/usePopoverHeight';
import getTitlePlainText from '@/editor/utils/getTitlePlainText';
import Button, { ButtonSize } from '@/storychief/components/Button.tsx';
import useAssistant from '@/assistant/hooks/useAssistant';

const SEARCH_QUERY = gql`
  query PowerModeContextAddContentPiecesSearchQuery(
    $page: Int
    $filters: SearchFilter!
    $orderBy: OrderByClause
    $first: Int
  ) {
    search(page: $page, filters: $filters, orderBy: $orderBy, first: $first) {
      data {
        ... on Story {
          __typename
          id
          title
          language
          content
          edit_url
        }
      }
      paginatorInfo {
        hasMorePages
        currentPage
        lastPage
        perPage
        total
      }
    }
  }
`;

type ContentPiece = {
  __typename: string;
  id: string;
  title: string;
  content: string;
  edit_url: string;
};

type ModelAddButtonType = {
  size?: ButtonSize; // Size differs between the assistant page and the article editor
  contentPieces: ContentPiece[];
  handleOnAdd: (contentPieces: ContentPiece[]) => void;
};

function CommandAddContentPiece({ size = 'sm', contentPieces, handleOnAdd }: ModelAddButtonType) {
  // State
  const { subject } = useAssistant();
  const [isShow, setIsShow] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [searchResults, setSearchResults] = useState<ContentPiece[]>([]);
  const [selectedContentPieces, setSelectedContentPieces] = useState<ContentPiece[]>([]);
  const idsToNotRender = selectedContentPieces.map(({ id }) => id);

  // Queries
  const [
    loadSearchResultsQuery,
    {
      data: { search: { paginatorInfo: searchResultsMeta } } = {
        search: { paginatorInfo: { currentPage: 1, hasMorePages: false } },
      },
      loading: loadingSearchResults,
    },
  ] = useLazyQuery(SEARCH_QUERY, {
    onCompleted: (data) => {
      if (data.search.paginatorInfo.currentPage === 1) {
        // Reset everything after a new search query
        setSearchResults(data.search.data);
      } else {
        setSearchResults((prev) => [...prev, ...data.search.data]);
      }
    },
  });

  // Refs
  const buttonRef = useRef(null);

  // Hooks
  useDebounce(searchQuery, 500, (currentSearchValue: string) => {
    setSearchResults([]);

    loadSearchResults(currentSearchValue || '');
  });

  const [popoverHeight, adjustPopoverHeight] = usePopoverHeight(isShow ? buttonRef : null, {
    maxHeight: 350,
  });
  const [infiniteSentryRef, { rootRef: infiniteRootRef }] = useInfiniteScroll({
    loading: loadingSearchResults,
    onLoadMore: () => {
      if (searchResultsMeta.hasMorePages) {
        loadSearchResults(searchQuery, searchResultsMeta.currentPage + 1);
      }
    },
    hasNextPage: true,
  });

  // Functions
  function loadSearchResults(query: string = '', page: number = 1) {
    loadSearchResultsQuery({
      variables: {
        page,
        first: 100,
        filters: {
          category: 'stories',
          search: query,
          predicates: [],
        },
        orderBy: {
          column: 'created_at',
          order: 'DESC',
        },
      },
    });
  }

  function renderSearchItem(contentPiece: ContentPiece) {
    if (
      subject &&
      subject.model &&
      contentPiece.__typename === subject.model.__typename &&
      contentPiece.id === subject.model.id
    ) {
      // Sometimes the command form is called inside the article form
      return null;
    }

    if (contentPieces.some((cp) => cp.id === contentPiece.id)) {
      // Don't render items that have already been selected
      return null;
    }

    const title = getTitlePlainText(contentPiece.title);

    return (
      <PopoverMenu.MultiselectItem
        key={contentPiece.id}
        data-testid="command-context-button-checkbox-content-piece"
        onChange={handleOnChangeContentPiece(contentPiece)}
        tooltip={title}
        isChecked={idsToNotRender.includes(contentPiece.id)}
      >
        {title}
      </PopoverMenu.MultiselectItem>
    );
  }

  function handleOnSearch(query: string) {
    setSearchQuery(query);
  }

  function handleOnChangeContentPiece(contentPiece: ContentPiece) {
    return () => {
      setSelectedContentPieces((prev: ContentPiece[]) => {
        const exists = prev.some(
          (c) => c.__typename === contentPiece.__typename && c.id === contentPiece.id,
        );

        if (exists) {
          return prev.filter(
            (c) => c.__typename === contentPiece.__typename && c.id !== contentPiece.id,
          );
        }

        return [...prev, contentPiece];
      });
    };
  }

  function handleOnSubmit() {
    handleOnAdd(selectedContentPieces);

    setIsShow(false);
    setSelectedContentPieces([]);
  }

  function handleOnToggle(value: boolean) {
    return () => {
      setIsShow(value);
    };
  }

  // Render
  return (
    <div className="relative" ref={buttonRef}>
      <Button
        size={size}
        variant="secondary"
        className="w-full"
        data-testid="command-context-button-add-content-piece"
        onClick={handleOnToggle(true)}
      >
        Add
      </Button>

      <Overlay
        show={isShow}
        placement="bottom"
        rootClose
        shouldUpdatePosition
        container={buttonRef.current}
        onHide={handleOnToggle(false)}
        onEntered={adjustPopoverHeight}
      >
        <Popover
          id="popover-powermode-add-content-piece"
          className="popover--filter popover--with-search popover--with-menu"
          style={{ maxHeight: popoverHeight }}
        >
          <PopoverMenu
            type="multiselect"
            selectedItems={selectedContentPieces.map((contentPiece) => ({
              id: contentPiece.id,
              label: getTitlePlainText(contentPiece.title),
            }))}
            isLoading={loadingSearchResults}
            onSearch={handleOnSearch}
            onSubmit={handleOnSubmit}
            onCancel={handleOnToggle(false)}
            loadingRef={infiniteSentryRef}
            scrollRef={infiniteRootRef}
            hasMorePages={searchResultsMeta?.hasMorePages || false}
          >
            {searchResults ? searchResults.map(renderSearchItem) : null}
          </PopoverMenu>
        </Popover>
      </Overlay>
    </div>
  );
}

export default CommandAddContentPiece;
