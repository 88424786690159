import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import prepareImagesForMasonryGallery from '@/storychief/utils/prepareImagesForMasonryGallery';

const propTypes = {
  data: PropTypes.shape({
    results: PropTypes.arrayOf(PropTypes.shape({})),
    total: PropTypes.number,
    total_pages: PropTypes.number,
  }).isRequired,
  page: PropTypes.number.isRequired,
  handlePagination: PropTypes.func.isRequired,
  handleSelection: PropTypes.func.isRequired,
};
const defaultProps = {};

function UnsplashGallery(props) {
  const { data, page, handlePagination, handleSelection } = props;
  const images = data.results;
  const [containerWidth, setContainerWidth] = useState(696);

  // Hooks
  const container = useRef(null);
  useEffect(() => {
    if (container.current) setContainerWidth(container.current.offsetWidth);
  }, [container]);

  const renderImages = () =>
    prepareImagesForMasonryGallery(images, page, false, containerWidth).map((row, idx) => (
      // eslint-disable-next-line react/no-array-index-key
      <div key={idx}>
        {row.map((image) => (
          <button
            type="button"
            key={image.id}
            className="image-unsplash"
            onClick={(e) => handleSelection(e, image)}
          >
            <img
              width={image.scaledWidth}
              height={image.scaledHeight}
              src={image.urls.thumb}
              alt=""
            />
            <div className="overlay-unsplash">
              <small className="photographer-unsplash">{image.title}</small>
            </div>
          </button>
        ))}
      </div>
    ));

  const renderPaginationInfo = () => (
    <span className="text-muted">
      {data.total === 0 ? (
        <small>No results</small>
      ) : (
        <small>
          {data.total} results | page {page} of {data.total_pages}
        </small>
      )}
    </span>
  );

  const renderArrows = (dataImages, direction) =>
    page === dataImages || dataImages === 0 ? null : (
      <button
        type="button"
        onClick={() => handlePagination(direction)}
        className={`arrows-unsplash link-reset ${direction}`}
      >
        {direction === 'Next' ? (
          <small>
            {direction} <span className="icon-angle-right" />
          </small>
        ) : (
          <small>
            <span className="icon-angle-left" /> {direction}
          </small>
        )}
      </button>
    );

  return (
    <div className="unsplash-gallery" ref={container}>
      <div className="pagination-unsplash">
        {renderArrows(1, 'Previous')}
        {renderPaginationInfo()}
        {renderArrows(data.total_pages, 'Next')}
      </div>
      {!!data.total && <div className="images-unsplash">{renderImages()}</div>}
    </div>
  );
}

UnsplashGallery.propTypes = propTypes;
UnsplashGallery.defaultProps = defaultProps;

export default UnsplashGallery;
