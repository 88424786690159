export default function (url) {
  let data = {};
  data = {
    type: 'rich',
    version: '1.0',
    iframe_src: url,
    provider_name: 'Github',
    povider_url: 'https://gist.github.com/',
    width: 598,
    height: 450,
    html: `<script src="${url}.js"></script>`,
  };
  return new Promise((resolve) => {
    data.html_responsive = data.html;
    const regExp = /([^/]*)$/;
    const match = url.match(regExp);
    data.gist_id = match[0];
    resolve(data);
  });
}
