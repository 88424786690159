import { EditorState, SelectionState } from 'draft-js';

export default function selectAllBeforeSelection(editorState) {
  const currentContent = editorState.getCurrentContent();
  const selectionState = editorState.getSelection();
  const isBackward = selectionState.getIsBackward();

  const selection = new SelectionState({
    anchorKey: currentContent.getFirstBlock().getKey(),
    anchorOffset: 0,
    focusKey: isBackward ? selectionState.focusKey : selectionState.anchorKey,
    focusOffset: isBackward ? selectionState.focusOffset : selectionState.anchorOffset,
  });
  return EditorState.forceSelection(editorState, selection);
}
