import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import PropTypes from 'prop-types';
import useAssistant from '@/assistant/hooks/useAssistant';
import ConditionalWrapper from '@/storychief/components/ConditionalWrapper';
import PointerEventsWrapper from '@/storychief/components/PointerEventsWrapper';
import RestrictedButton from '@/storychief/components/RestrictedButton';

const propTypes = {
  onSubmit: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  insidePopover: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
};
const defaultProps = {
  insidePopover: false,
  size: 'sm',
};
function CommandFormSubmit({ onSubmit, disabled, insidePopover, size }) {
  const { loading, isOverMonthlyLimit, maxMonthlyUsage, context, available } = useAssistant();

  return (
    <ConditionalWrapper
      condition={available && isOverMonthlyLimit}
      wrapper={(children) => (
        <OverlayTrigger
          key="command-submit-overlay"
          placement="top"
          overlay={
            <Tooltip id="command-submit-tooltip">
              The monthly limit of {maxMonthlyUsage} has been reached. You can upgrade to AI Power
              Mode Pro anytime in your billing center.
            </Tooltip>
          }
        >
          <PointerEventsWrapper>{children}</PointerEventsWrapper>
        </OverlayTrigger>
      )}
    >
      <RestrictedButton
        onClick={onSubmit}
        disabled={disabled || loading || isOverMonthlyLimit || context?.assets?.loading}
        className={`sc-btn sc-btn--primary sc-btn--${size}`}
        entitlement="ai-assistant"
        tooltipPlacement={insidePopover ? 'top' : 'bottom'}
        entitlementDescription="Purchase the AI Power Mode add-on to activate its features."
      >
        Submit
      </RestrictedButton>
    </ConditionalWrapper>
  );
}

CommandFormSubmit.propTypes = propTypes;
CommandFormSubmit.defaultProps = defaultProps;

export default CommandFormSubmit;
