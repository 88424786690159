import request from 'superagent';

function getBlockquoteId(htmlString) {
  let id = null;
  const wrap = document.createElement('div');
  wrap.innerHTML = htmlString;
  const blockquotes = wrap.getElementsByTagName('BLOCKQUOTE');
  if (blockquotes.length > 0) {
    const blockquote = blockquotes[0];
    id = blockquote.getAttribute('data-id');
  }
  return id;
}

export default function (url) {
  return new Promise((resolve, reject) => {
    request
      .get(`https://api.imgur.com/oembed?url=${url}`)
      .set('Accept', 'application/json')
      .end((err, res) => {
        if (err) {
          reject(err);
        } else {
          const data = res.body;
          data.html_responsive = data.html;
          data.imgur_id = getBlockquoteId(data.html);
          resolve(data);
        }
      });
  });
}
