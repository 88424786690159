import { gql, useQuery, QueryOptions, QueryResult } from '@apollo/client';
import StoryChief from '@/storychief';

const BRAND_VOICES_QUERY = gql`
  query BrandVoices {
    brandVoices {
      __typename
      id
      name
      characteristics
      dimensions {
        funny_vs_serious
        formal_vs_casual
        respectful_vs_irreverent
        enthusiastic_vs_matter_of_fact
      }
      is_default
      created_at
      updated_at
      deleted_at
      can {
        update
        delete
      }
    }
  }
`;

function useBrandVoices(options: QueryOptions = null): QueryResult {
  return useQuery(BRAND_VOICES_QUERY, {
    fetchPolicy: StoryChief.user?.role ? 'cache-first' : 'cache-only',
    ...options,
  });
}

export default useBrandVoices;
