import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { EditorState, Modifier } from 'draft-js';
import classNames from 'classnames';
import BlockCaption from '../../components/blocks/BlockCaption';
import updateDataOfBlock from '../../utils/updateDataOfBlock';
import deleteDataBlock from '../../utils/deleteDataBlock';
import selectBlock from '../../utils/selectBlock';
import addLinkEntityToEditorState from '../../utils/addLinkEntityToEditorState';
import UnsplashContainer from '@/editor/plugins/image/unsplash/UnsplashContainer';
import { useUploadFiles } from '@/storychief/hooks';
import MediaPicker from '@/media/components/MediaPicker';
import MediaItem from '@/media/components/MediaItem';
import { fileTypesImages } from '@/media/constants/Constants';

const propTypes = {
  block: PropTypes.shape({
    key: PropTypes.string,
    getData: PropTypes.func,
  }),
  blockProps: PropTypes.shape({
    isSelected: PropTypes.bool,
    editorEnabled: PropTypes.bool,
    getEditorState: PropTypes.func,
    setEditorState: PropTypes.func,
    setReadOnly: PropTypes.func,
  }),
};
const defaultProps = {
  block: null,
  blockProps: null,
};

function ImageBlock(props) {
  // Variables & states
  const blockData = props.block.getData();
  const { blockProps } = props;
  const size = blockData.get('size');
  const isSelected = blockProps.isSelected;
  const [image, setImage] = useState({
    url: blockData.get('src'),
    id: blockData.get('id'),
    width: blockData.get('width'),
    height: blockData.get('height'),
    alt: blockData.get('alt'),
    uploadProgress: blockData.get('uploadProgress'),
    uploading: !!blockData.get('uploading'),
  });
  const isEmpty = !image.url || image.url === '';
  const isUploading = image.uploading === true;
  const classNameWrapper = classNames('block-container block-image-container', {
    'is-empty': isEmpty,
    selected: isSelected,
    size,
  });

  // Hooks
  const [upload] = useUploadFiles({
    saveToDatabase: true,
    isPrivate: blockData.get('isPrivate'),
    onUploading: (_images) => setImage(_images[0]),
    onCompleted: (_images) => insertImage(_images[0]),
  });

  useEffect(() => {
    setImage({
      url: blockData.get('src'),
      id: blockData.get('id'),
      width: blockData.get('width'),
      height: blockData.get('height'),
      alt: blockData.get('alt'),
      uploadProgress: blockData.get('uploadProgress'),
      uploading: !!blockData.get('uploading'),
    });
  }, [blockData]);

  useEffect(() => {
    const data = props.block.getData();
    const initialUpload = data.get('initialUpload');

    if (initialUpload && initialUpload.name && !data.get('src')) {
      upload([initialUpload]);
      props.blockProps.setReadOnly(false);
    }
  }, []);

  function insertImage(_image) {
    updateData(
      {
        id: _image.id,
        src: _image.url,
        width: _image.width,
        height: _image.height,
        alt: _image.description || _image.alt,
      },
      props.blockProps.getEditorState(),
    );
  }

  // Functions
  function onWrapperClick() {
    props.blockProps.setEditorState(selectBlock(props.block, props.blockProps.getEditorState()));
  }

  function onRemove() {
    props.blockProps.setReadOnly(true);
    const newEditorState = deleteDataBlock(props.blockProps.getEditorState(), props.block);
    props.blockProps.setEditorState(newEditorState);
    props.blockProps.setReadOnly(false);
  }

  function updateData(data, editorState = null) {
    const { getEditorState, setEditorState } = blockProps;
    const currentEditorState = editorState || getEditorState();
    setEditorState(updateDataOfBlock(currentEditorState, props.block, data));
  }

  function handleOnFileLoaded(images) {
    const _image = images[0];
    if (_image.id) {
      insertImage(_image);
    } else {
      setImage(images[0]);
      upload(images);
    }
    props.blockProps.setEditorState(fillImageCaption(_image, props.blockProps.getEditorState()));
  }

  function addCaptionLinks(state, links) {
    links.forEach((captionLink) => {
      const captionSelection = state.getSelection().merge({
        anchorOffset: captionLink.selectionData.anchorOffset,
        focusOffset: captionLink.selectionData.focusOffset,
      });
      // eslint-disable-next-line no-param-reassign
      state = EditorState.forceSelection(state, captionSelection);
      // eslint-disable-next-line no-param-reassign
      state = addLinkEntityToEditorState(captionLink.linkData, state);
    });

    return state;
  }

  function getCaptionTextItem({ label, text }) {
    return label ? `${label}: ${text}` : text;
  }

  function getCaptionText(caption) {
    return caption.map(getCaptionTextItem).join(' | ');
  }

  function getCaptionLinks(caption) {
    const links = [];
    let offset = 0;

    caption.forEach(({ label, text, url, targetBlank }) => {
      if (url) {
        const labelOffset = label ? `${label}: `.length : 0;

        links.push({
          selectionData: {
            anchorOffset: offset + labelOffset,
            focusOffset: offset + labelOffset + text.length,
          },
          linkData: {
            url,
            targetBlank: targetBlank || false,
          },
        });
      }

      offset += getCaptionTextItem({ label, text }).length + ' | '.length;
    });

    return links;
  }

  function fillImageCaption(_image, editorState) {
    let newEditorState = editorState;
    const currentContent = editorState.getCurrentContent();
    const selection = editorState.getSelection();
    let newSelection = selection.merge({
      anchorKey: props.block.key,
      anchorOffset: 0,
      focusKey: props.block.key,
      focusOffset: 0,
    });

    switch (_image.source) {
      case 'unsplash': {
        const caption = [];

        if (_image.user.name) {
          caption.push({
            label: 'Photographer',
            text: _image.user.name,
            url: _image.user?.links?.html || null,
            targetBlank: true,
          });
        }

        caption.push({
          label: 'Source',
          text: 'Unsplash',
          url: 'https://unsplash.com/',
          targetBlank: true,
        });

        // Add caption text
        const blockText = Modifier.replaceText(
          currentContent,
          newSelection,
          getCaptionText(caption),
        );
        newEditorState = EditorState.push(editorState, blockText, 'insert-characters');

        // Add caption links
        const captionLinks = getCaptionLinks(caption);

        if (captionLinks.length > 0) {
          newEditorState = addCaptionLinks(newEditorState, captionLinks);
        }
        break;
      }
      default: {
        newSelection = selection.merge({
          anchorKey: props.block.key,
          anchorOffset: 0,
          focusKey: props.block.key,
          focusOffset: 0,
        });

        const caption = [];

        if (_image.caption) {
          caption.push({
            label: null,
            text: _image.caption,
          });
        }

        if (_image.description || _image.alt) {
          caption.push({
            label: null,
            text: _image.description || _image.alt,
          });
        }

        if (_image?.user?.name) {
          caption.push({
            label: 'Photographer',
            text: _image.user.name,
            url: _image.user.url || null,
            targetBlank: true,
          });
        }

        if (_image?.copyright?.name) {
          caption.push({
            label: 'Copyright',
            text: _image.copyright.name,
            url: _image.copyright.url || null,
            targetBlank: true,
          });
        }

        if (caption.length > 0) {
          // Add caption text
          const blockText = Modifier.replaceText(
            currentContent,
            newSelection,
            getCaptionText(caption),
          );
          newEditorState = EditorState.push(newEditorState, blockText, 'insert-characters');

          // Add caption links
          const captionLinks = getCaptionLinks(caption);

          if (captionLinks.length > 0) {
            newEditorState = addCaptionLinks(newEditorState, captionLinks);
          }
        }
        break;
      }
    }
    // Collapse selection and move to start
    newSelection = newEditorState.getSelection();
    const emptySelection = newSelection.merge({
      anchorOffset: 0,
      focusOffset: 0,
    });
    newEditorState = EditorState.forceSelection(newEditorState, emptySelection);

    return newEditorState;
  }

  // Rendering
  return (
    <div className={classNameWrapper}>
      <div contentEditable={false}>
        {isEmpty ? (
          <div>
            <button
              type="button"
              onClick={onRemove}
              className="btn-chromeless btn-delete icon-cancel"
              aria-label="Remove"
            />
            <h2>
              Add an <span className="highlight-marker">image</span>
            </h2>
            <br />
            <MediaPicker
              multiSelect={false}
              onLoadCompleted={handleOnFileLoaded}
              allowedFileTypes={fileTypesImages}
              hideUnsplash
            />
            <small className="text-muted d-block space-5 space-top-5">
              <span>Or search free stock images</span>
            </small>
            <UnsplashContainer
              blockProps={blockProps}
              onFileLoaded={handleOnFileLoaded}
              focusInput={blockProps.isSelected}
              onInputBlur={() => blockProps.setReadOnly(false)}
              onInputFocus={() => blockProps.setReadOnly(true)}
            />
          </div>
        ) : (
          <div
            className="media-wrapper image-wrapper"
            contentEditable={false}
            onClick={blockProps.editorEnabled && !isSelected ? onWrapperClick : null}
            onKeyPress={blockProps.editorEnabled && !isSelected ? onWrapperClick : null}
          >
            <button
              type="button"
              onClick={onRemove}
              className="btn-chromeless btn-delete icon-cancel"
              aria-label="Remove"
            />
            <MediaItem
              object={{
                mime_type: 'image/*',
                ...image,
              }}
              size="full"
            />
          </div>
        )}
      </div>
      {isEmpty || isUploading ? (
        <div contentEditable={false}>
          <BlockCaption {...props} style={{ display: 'none' }} />
        </div>
      ) : (
        <BlockCaption {...props} />
      )}
    </div>
  );
}

ImageBlock.propTypes = propTypes;
ImageBlock.defaultProps = defaultProps;

export default ImageBlock;
