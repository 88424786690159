import { isInteger } from 'lodash-es';

function getRatio(height, width) {
  return `${(parseInt(height, 10) / parseInt(width, 10)) * 100}%`;
}

export default function (iframeString) {
  let output = '';
  const wrap = document.createElement('div');
  wrap.innerHTML = iframeString;
  const iframes = wrap.getElementsByTagName('iframe');
  if (iframes.length > 0) {
    const iframe = iframes[0];
    // Get dimensions from width/height attributes or style attribute
    const height = iframe.height ? iframe.height : iframe.style.height.replace('px', '');
    const width = iframe.width ? iframe.width : iframe.style.width.replace('px', '');

    if (isInteger(parseInt(height, 10)) && isInteger(parseInt(width, 10))) {
      iframe.style.position = 'absolute';
      iframe.style.top = '0px';
      iframe.style.left = '0px';
      iframe.style.width = '100%';
      iframe.style.height = '100%';
      iframe.style.border = 'none';
      wrap.innerHTML = iframe.outerHTML;
      wrap.style.maxWidth = '100%';
      wrap.style.position = 'relative';
      wrap.style.paddingTop = getRatio(height, width);
      output = wrap.outerHTML;
    }
  }
  return output;
}
