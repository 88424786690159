import findWithRegex from '@/editor/utils/findWithRegex';
import findTwitterWithRegex from '@/editor/utils/findTwitterWithRegex';
import { MENTIONS_PATTERN } from '@/editor/constants/Constants';

export const mentionStrategy = (contentBlock, callback) => {
  findWithRegex(MENTIONS_PATTERN, contentBlock, callback);
};

export const mentionInactiveStrategy = (contentBlock, callback) => {
  findWithRegex(/(?:(?:@|＠)(?!\/))([a-zA-Z0-9/_-]*)/g, contentBlock, callback);
};

export const mentionInactiveWithSpacesStrategy = (contentBlock, callback) => {
  findWithRegex(/(?:(?:@|＠)(?!\/))([a-zA-Z0-9/'_\-\b ]*)/g, contentBlock, callback);
};

export const mentionTwitterStrategy = (contentBlock, callback) => {
  findTwitterWithRegex(contentBlock, callback);
};

export const mentionSelectStrategy = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return entityKey != null && contentState.getEntity(entityKey).getType() === 'mention';
  }, callback);
};
