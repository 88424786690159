import { useState, useRef, useEffect } from 'react';
import { toast } from 'react-toastify';
import uuid from 'uuid/v4';
import { captureException } from '@sentry/react';
import StoryChief from '@/storychief';
import JSTextDecoderStream from '@/storychief/utils/JSTextDecoderStream';
import cleanupAssistantCommandTextResult from '../utils/cleanupAssistantCommandTextResult';
import tryParseJson from '@/storychief/utils/tryParseJson';

function useAssistantCommand(initialResult = null) {
  const [result, setResult] = useState(initialResult);
  const [loading, setLoading] = useState(false);
  const isInProgress = useRef(false);
  const fullText = useRef('');

  function getStreamResponseText(streamResponse) {
    const lines = streamResponse
      .replace('data: [DONE]\n\n', '')
      .replace(/^data: /, '')
      .split('\ndata:')
      .filter((line) => line);
    let leftoverPartialChunk = null;

    const content = lines
      .map((obj) => {
        const newValueData = tryParseJson(obj);

        if (!newValueData.valid) {
          // The streamResponse may be larger than the ReadableStream's chunk size, so it may include a partial chunk
          // which is not valid JSON.
          leftoverPartialChunk = obj;
          return '';
        }

        if (newValueData.parsed?.error?.message) {
          if (StoryChief.shouldCaptureErrorsInSentry) {
            captureException(new Error(newValueData.parsed.error.message), {
              extra: { response: JSON.stringify(newValueData.parsed) },
            });
          }

          toast.error(
            'Due to high demand, our assistant is currently busy. Please retry again in a few seconds.',
          );
          cancelCommand();
          return '';
        }

        return typeof newValueData.parsed?.choices[0]?.delta?.content !== 'undefined'
          ? newValueData.parsed.choices[0].delta.content
          : '';
      })
      .join('');

    return { content, leftoverPartialChunk };
  }

  async function streamCommand(command, variables) {
    fullText.current = '';
    setLoading(true);
    isInProgress.current = true;
    return new Promise((resolve) => {
      (async () => {
        const response = await fetch(`${StoryChief.apiBasePath}/assistant/command/${command}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-Csrf-Token': StoryChief.csrfToken,
            Accept: 'application/json',
          },
          body: JSON.stringify(variables),
        });

        if (response.status === 200) {
          const reader = response.body.pipeThrough(new JSTextDecoderStream()).getReader();

          let streamResponse = '';
          let leftoverPartialChunk = '';
          let done = false;

          while (!streamResponse.includes('data: [DONE]\n\n') && !done && isInProgress.current) {
            // eslint-disable-next-line no-await-in-loop
            ({ value: streamResponse, done } = await reader.read());
            const streamResponseText = getStreamResponseText(
              leftoverPartialChunk ? `${leftoverPartialChunk}${streamResponse}` : streamResponse,
            );
            const newContent = streamResponseText.content;
            leftoverPartialChunk = streamResponseText.leftoverPartialChunk;

            if (newContent) {
              setResult((prevResult) => {
                const _result = { ...prevResult };

                let lastTranscript = _result?.transcript?.slice(-1)[0];

                if (
                  lastTranscript &&
                  lastTranscript.role === 'user' &&
                  lastTranscript.content === 'continue'
                ) {
                  // We will continue in the same transcript
                  const secondLastTranscript = _result?.transcript?.slice(-2);
                  if (secondLastTranscript) {
                    lastTranscript = secondLastTranscript[0];
                    fullText.current = lastTranscript.content;

                    if (!/[a-zA-Z0-9]$/.test(fullText.current)) {
                      // We add a space if the last character is not a letter or a number
                      fullText.current = `${fullText.current} `;
                    }

                    const currentLines = fullText.current.split('\n');
                    const lastLine = currentLines[currentLines.length - 1];

                    if (
                      newContent.startsWith('-') ||
                      (lastLine.startsWith('-') && !/[a-zA-Z0-9]$/.test(lastLine))
                    ) {
                      // we add a newline if the incomming content starts with - (list item)
                      // or if the last line starts with - and does not end with a letter or a number
                      fullText.current = `${fullText.current}\n\n`;
                    }
                    // We remove the last transcript
                    _result.transcript.pop();
                  }
                }

                if (!lastTranscript || lastTranscript.role !== 'assistant') {
                  _result.transcript = _result.transcript || [];
                  _result.transcript.push({
                    role: 'assistant',
                    content: '',
                    id: uuid(),
                  });
                }

                fullText.current = cleanupAssistantCommandTextResult(
                  variables.prompt.command.identifier,
                  `${fullText.current || ''}${newContent}`,
                );

                _result.transcript[_result.transcript.length - 1].content = fullText.current;
                return _result;
              });
            }
          }
          reader.cancel();
        } else {
          toast.error('An unexpected error occurred while processing your request.', {
            toastId: 'assistant-command-error',
          });

          const responseData = await response.json();

          if (StoryChief.shouldCaptureErrorsInSentry && responseData.message) {
            captureException(new Error(responseData.message));
          }
        }

        isInProgress.current = false;
        setLoading(false);
        resolve();
      })();
    });
  }

  function resetResult() {
    setResult(null);
    setLoading(false);
  }

  function cancelCommand() {
    isInProgress.current = false;
  }

  useEffect(
    () => () => {
      cancelCommand();
    },
    [],
  );

  return { streamCommand, resetResult, setResult, cancelCommand, result, loading };
}

export default useAssistantCommand;
