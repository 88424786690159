import { EditorState, SelectionState } from 'draft-js';
/*
 selects a given block
 */
export default function selectBlock(block, editorState) {
  const offset = block.text ? block.text.length : 0;
  const endRange = new SelectionState({
    anchorKey: block.key,
    anchorOffset: offset,
    focusKey: block.key,
    focusOffset: offset,
  });
  return EditorState.forceSelection(editorState, endRange);
}
